<div mat-dialog-content class="dialog-container">
  <h1 mat-dialog-title>{{'CHAT_WIDGET.SAVE_ANSWER' | translate}}</h1>

  <div class="inputs-container">
  <mat-form-field appearance="fill">
    <mat-label>{{'CHAT_WIDGET.TITLE' | translate}}</mat-label>
    <input class="title-input" matInput [(ngModel)]="title"/>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'CHAT_WIDGET.MESSAGE' | translate}}</mat-label>
    <textarea matInput [(ngModel)]="data.message" readonly></textarea>
  </mat-form-field>
  </div>
  <div class="buttons-container" mat-dialog-actions>
    <button [disabled]="!title" mat-raised-button color="primary" (click)="onConfirm()">{{'CHAT_WIDGET.SAVE' | translate}}</button>
    <button mat-raised-button color="warn" (click)="onCancel()">{{'CHAT_WIDGET.CANCEL' | translate}}</button>
  </div>
</div>
