<div class="impedance-container" *ngIf="isImpedanceData">
  <div class="lighter">
    <p>{{'GENIUS_SETTINGS.CHANNEL_TEXT' | translate}} {{impedanceFirstChannelKOhms}} kOhms</p>
    <div class="circle" [ngStyle]="getFirstLighterColor()">
        <div class="negative-impedance" *ngIf="impedanceFirstChannelKOhms < 0">
          <p>{{'GENIUS_SETTINGS.NEGATIVE_IMPEDANCE' | translate}}</p>
        </div>
    </div>
  </div>
<!--  <button (click)="sim()">los</button>-->
<!--  <div class="lighter">-->
<!--    <p>Ch2: {{impedanceSecondChannelKOhms}} kOhms</p>-->
<!--    <div class="circle" [ngStyle]="getSecondLighterColor()"></div>-->
<!--  </div>-->
</div>
<div class="simulator-container" *ngIf="currentUser?.role === 'supervisor'">
  <button mat-raised-button
          id="simulator-button"
          tourAnchor="simulator-tour"
          (click)="toggleSimulatorMode()"
          [ngClass]="isSimulatorMode? 'simulator-on' : 'toggle-button'"
          [disabled]="device"
          *ngIf="!device">
    {{isSimulatorMode ? ('SIMULATOR_MODE.SIMULATOR_OFF' | translate) : ('SIMULATOR_MODE.SIMULATOR_ON' | translate)}}
  </button>
</div>
<section class="setting-section" *ngIf="!isSimulatorMode">
  <div class="how-to-use-container">
    <button mat-raised-button (click)="openYoutubeLink()" class="youtube-link" [ngbTooltip]="bluetoothHint()">
      <img src="./assets/images/youtube.png" alt="youtube">
    </button>
    <button mat-raised-button (click)="openInfoDialog($event)" class="info-button" tourAnchor="info-tour" [ngbTooltip]="bluetoothHint()">
      <span class="material-icons">
        info
      </span>
    </button>
  </div>

  <div class="settings-container">
    <div class="timer-container" *ngIf="showTimer">
      <p>{{timerView}}</p>
    </div>
    <button [ngbTooltip]="bluetoothHint()" mat-raised-button tourAnchor="connect-device-tour" (click)="toggleConnectDevice()"
            class="toggle-button">{{isSensorConnected ? ('GENIUS_SETTINGS.DISCONNECT_TEXT' | translate) : ('GENIUS_SETTINGS.CONNECT_TEXT' | translate)}}</button>
    <mat-spinner [diameter]="25" class="connecting-spinner" *ngIf="isLoading"></mat-spinner>
    <div class="device-info" *ngIf="device || isSensorConnected && percentage">
      <img class="energy-icon" src="../../../assets/images/energy-icon.png">
      <div class="percentage-container">
        <p class="percentage-number">{{percentage}}%</p>
        <div class="percentage-line" [ngStyle]="lineWidth()"></div>
      </div>
    </div>
    <div class="status-dot">
       <span class="material-symbols-outlined" *ngIf="device" [ngClass]="{'status-dot-errors' : isSensorErrors(), 'status-dot-connected' : !isSensorErrors()}">
            bluetooth
          </span>
      <div [ngClass]="getCurrentLanguage() === 'de' ? 'sensor-stats-de' : null" class="sensor-stats" *ngIf="device">
          <div class="device-stats">
            <p>{{'SENSOR_STATS.TOTAL_PACKETS' | translate}} {{getSensorStats().totalPackets}}</p>
            <p>{{'SENSOR_STATS.PACKETS_DROPPED' | translate}} {{getSensorStats().packetsDropped}}</p>
            <p>{{'SENSOR_STATS.SYNC_ERROR' | translate}} {{getSensorStats().syncError}}</p>
            <p>{{'SENSOR_STATS.TOTAL_ERROR' | translate}} {{getSensorStats().totalErrorCount}}</p>
          </div>
        <ng-container *ngIf="currentUser && currentUser.devices">
          <div class="device-name" *ngFor="let device of currentUser.devices;let isLast = last;">
            <span class="device-name-heading">{{'GENIUS_SETTINGS.PERSONAL_DEVICE' | translate}}</span>
            <p>{{device}} <span *ngIf="!isLast">,</span></p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
