import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'kt-add-text-answer-dialog',
  templateUrl: './add-text-answer-dialog.component.html',
  styleUrls: ['./add-text-answer-dialog.component.scss']
})
export class AddTextAnswerDialogComponent {

  title = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
    private dialogRef: MatDialogRef<AddTextAnswerDialogComponent>
  ) {}

  onConfirm(): void {
    this.dialogRef.close({ title: this.title, answer: this.data.message });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
