<div
  class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10 language-select-container"
>
  <!-- <span class="font-weight-bold text-dark-50">
    Create free account
  </span> -->
  <kt-language-selector></kt-language-selector>
  <a
    href="javascript:;"
    routerLink="/auth/register"
    class="font-weight-bold ml-2"
    id="kt_login_signup"
    >{{ "AUTH.GENERAL.CREATE_ACCOUNT" | translate }}</a
  >
</div>
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
  <!--begin::Signin-->
  <div class="login-form login-signin">
    <div clbbass="text-center mb-10 mb-lg-20">
      <h3 class="font-size-h1">{{ "AUTH.LOGIN.TITLE" | translate }}</h3>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <!--begin::Form-->
    <form
      class="form"
      [formGroup]="loginForm"
      autocomplete="off"
      novalidate="novalidate"
      id="kt_login_form"
    >
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.EMAIL" | translate }}</mat-label>
          <input
            matInput
            type="email"
            placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>{{ "AUTH.VALIDATION.INVALID_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minlength')">
            <strong
              >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxlength')">
            <strong
              >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 320</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.PASSWORD" | translate }}</mat-label>
          <input
            matInput
            type="password"
            placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
            formControlName="password"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minlength')">
            <strong
              >{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxlength')">
            <strong
              >{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</strong
            >
          </mat-error>
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div
        class="form-group d-flex flex-wrap justify-content-between align-items-center"
      >
        <button
          style="width: 100%"
          id="kt_login_signin_submit"
          (click)="submit()"
          [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }"
          class="btn btn-primary font-weight-bold px-9 py-4 my-3"
        >
          {{ "AUTH.LOGIN.LOGIN_BUTTON" | translate }}
        </button>

<!--        <button-->
<!--          style="width: 100%"-->
<!--          id="kt_login_signin_submit"-->
<!--          (click)="loginWithGoogle()"-->
<!--          [ngClass]="{-->
<!--            'spinner spinner-right spinner-md spinner-light': loadingGoogle-->
<!--          }"-->
<!--          class="btn btn-primary font-weight-bold px-9 py-4 my-3"-->
<!--        >-->
<!--          <img-->
<!--            style="width: 20px; margin-right: 10px"-->
<!--            src="assets/google-icon.png"-->
<!--            alt=""-->
<!--          />-->
<!--          {{ "AUTH.LOGIN.LOGIN_GOOGLE" | translate }}-->
<!--        </button>-->
<!--        <button-->
<!--          style="width: 100%;text-transform: uppercase"-->
<!--          id="kt_login_signin_submit"-->
<!--          (click)="loginWithApple()"-->
<!--          [ngClass]="{-->
<!--            'spinner spinner-right spinner-md spinner-light': loadingGoogle-->
<!--          }"-->
<!--          class="btn btn-primary font-weight-bold px-9 py-4 my-3"-->
<!--        >-->
<!--          <img-->
<!--            style="width: 20px; margin-right: 10px"-->
<!--            src="assets/images/apple-logo.svg"-->
<!--            alt=""-->
<!--          />-->
<!--          {{ "AUTH.LOGIN.LOGIN_APPLE" | translate }}-->
<!--        </button>-->
        <div>
          <a
            href="javascript:;"
            routerLink="/auth/forgot-password"
            class="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
            >{{ "AUTH.GENERAL.FORGOT_BUTTON" | translate }}</a
          >
        </div>
        <br />
        <br />
        <br />
        <div class="login-links">
          <div class="link-item" *ngIf="selectedLanguage === 'de'">
            <a href="https://mindfield-shop.com/en/product/esense-eegenius-starter-set/" target="_blank">
              eSense EEGenius Starter Set kaufen
              <mat-icon class="material-symbols-outlined">
                shopping_cart
              </mat-icon>
            </a>
          </div>
          <div class="link-item" *ngIf="selectedLanguage === 'de'">
            <a href="https://mindfield-shop.com/en/product-category/home-devices/esense-eegenius-neurofeedback-at-home/" target="_blank">
              eSense EEGenius Verbrauchsmaterial kaufen
              <mat-icon class="material-symbols-outlined">
                shopping_cart
              </mat-icon>
            </a>
          </div>
<!--          // en-->
          <div class="link-item" *ngIf="selectedLanguage === 'en'">
            <a href="https://mindfield-shop.com/en/product/esense-eegenius-starter-set/" target="_blank">
              eSense EEGenius Starter Set
              <mat-icon class="material-symbols-outlined">
                shopping_cart
              </mat-icon>
            </a>
          </div>
          <div class="link-item" *ngIf="selectedLanguage === 'en'">
            <a href="https://mindfield-shop.com/en/product-category/home-devices/esense-eegenius-neurofeedback-at-home/" target="_blank">
              Accessories
              <mat-icon class="material-symbols-outlined">
                shopping_cart
              </mat-icon>
            </a>
          </div>
        </div>
        <div class="login-youtube-videos-wrap">
          <div class="youtube-item" *ngIf="selectedLanguage === 'de'">
            <a href="https://foxly.link/eSense-EEGenius-Hardware" target="_blank">
              Einführung zur Inbetriebnahme und Elektrodenplatzierung
            </a>
            <img src="./../../../../../assets/images/youtube.png" alt=""/>
          </div>
          <div class="youtube-item" *ngIf="selectedLanguage === 'de'">
            <a href="https://foxly.link/eSense-EEGenius-Software" target="_blank">
              Erklärung der Software für Endanwender
            </a>
            <img src="./../../../../../assets/images/youtube.png" alt=""/>
          </div>
<!--          en-->
          <div class="youtube-item" *ngIf="selectedLanguage === 'en'">
            <a href="https://foxly.link/eSense-EEGenius-Hardware" target="_blank">
              Introduction to commissioning and electrode placement
            </a>
            <img src="./../../../../../assets/images/youtube.png" alt=""/>
          </div>
          <div class="youtube-item" *ngIf="selectedLanguage === 'en'">
            <a href="https://foxly.link/eSense-EEGenius-Software" target="_blank">
              Explanation of the software for end users
            </a>
            <img src="./../../../../../assets/images/youtube.png" alt=""/>
          </div>
          <div class="apple-link">
            <a href="https://apps.apple.com/us/app/esense-eegenius/id1563767134">
              <img [src]="selectedLanguage === 'en' ? 'assets/app-store-icons/app-store-en.svg' : 'assets/app-store-icons/app-store-de.svg'">
            </a>
            <p>{{'AUTH.LOGIN.APPLE_LINK_TEXT' | translate}}</p>
          </div>
        </div>
<!--        <div style="display: flex; justify-content: space-between; width: 100%">-->
<!--          <a style="font-size: 12px;cursor: pointer" (click)="onNavigate(details?.features)">-->
<!--            <img-->
<!--              width="20px"-->
<!--              src="./../../../../../assets/images/youtube.png"-->
<!--              alt=""-->
<!--            />-->
<!--            {{ "AUTH.GENERAL.ACCOUNT_FEATURES" | translate }}-->
<!--          </a>-->
<!--          <a style="font-size: 12px;cursor: pointer" (click)="onNavigate(details?.dashboard)">-->
<!--            <img-->
<!--              width="20px"-->
<!--              src="./../../../../../assets/images/youtube.png"-->
<!--              alt=""-->
<!--            />-->
<!--            {{ "AUTH.GENERAL.NEW_DASHBOARD" | translate }}-->
<!--          </a>-->
<!--        </div>-->
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>
