// Angular
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Store, select } from '@ngrx/store';
import {Observable, Subject, Subscription} from 'rxjs';
// Layout
import { OffcanvasOptions } from '../../../../core/_base/layout';
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../core/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import {filter, take, takeUntil} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserStatusService} from '../../../../services/user-status/user-status.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {FirebaseService} from '../../../../services/firebase/firebase.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IUser} from '../../../../core/interfaces/user';
import {currentUserDetails} from '../../../../core/auth/_selectors/auth.selectors';
import {CurrentUserService} from '../../../../services/current-user/current-user.service';

@Component({
  selector: 'kt-quick-user-panel',
  templateUrl: './quick-user-panel.component.html',
  styleUrls: ['./quick-user-panel.component.scss']
})
export class QuickUserPanelComponent implements OnInit {
  details: any;
  today: number = Date.now();
  currentRouteUrl = '';
  currentUser: IUser;
  preferableLanguage = 'en';
  // Public properties
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'offcanvas',
    placement: 'right',
    closeBy: 'kt_quick_user_close',
    toggleBy: 'kt_quick_user_toggle'
  };

  logoutSubscription: Subscription;
  currentUserSubscription: Subscription

  constructor(
    private store: Store<AppState>,
    private afa: AngularFireAuth,
    private translate: TranslateService,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
    private userStatus: UserStatusService,
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private afstorage: AngularFireStorage,
    private firebase: FirebaseService,
    private firebaseUser: CurrentUserService,
  ) {

  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.currentUserSubscription = this.firebaseUser.getCurrentUser().subscribe((user: IUser) => {
      if (user) {
        this.currentUser = user;
        this.cdr.markForCheck();
        if (user.photoURL) {
          this.currentUserSubscription.unsubscribe();
        }
      }
    });
  }

  logout() {
    this.logoutSubscription =  this.userStatus.setUserOffline().subscribe((user) => {
      if (user) {
          this.afa.signOut().then(() => {
            this.store.dispatch(new Logout());
            if (this.logoutSubscription) {
              this.logoutSubscription.unsubscribe();
            }
          }).catch(error => {
            console.log(error)
          })
      }
    });
    setTimeout(() => {
      this.afa.signOut().then(() => {
        this.store.dispatch(new Logout());
      }).catch(error => {
        console.log(error)
      })
    }, 1000)
  }

  changeProfileImg(event, element) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setImageDownloadUrl(reader.result).then((url) => {
        const user = {
          photoURL: url
        }
        this.firebase.updateUser(this.currentUser.uid, user);
        element.value = '';
        this.cdr.markForCheck();
      });
      this.cdr.markForCheck()
    };
  }

  setImageDownloadUrl(baseImage) {
    const ref = this.afstorage.storage.ref();
    const imgRef = ref.child(`usersPhoto/${this.currentUser.uid}/${Date.now()}`);
    return new Promise((resolve, reject) => {
      imgRef.putString(baseImage, 'data_url').then(() => {
        imgRef.getDownloadURL().then((imageUrl) => {
          resolve(imageUrl);
        })
      })
    })
  }
}
