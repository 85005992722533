import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecordService {

  isNeedRecord: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  columnData : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  stopRecording: Subject<boolean> = new Subject();

  constructor() { }
}
