// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
// RxJS
// Translate
import {TranslateService} from '@ngx-translate/core';
// NGRX
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, AuthService,} from '../../../../core/auth/';
import {Subject, Subscription} from 'rxjs';
import {SupervisorsService} from '../../../../services/supervisors.service';
import {FirebaseService} from '../../../../services/firebase/firebase.service';
import {ReCaptcha2Component} from 'ngx-captcha';
import {take, takeUntil} from 'rxjs/operators';
import {superUser} from '../../../../core/consts/super-user';
import {ChatService} from '../../../../services/chat/chat.service';
import {IMessages} from '../../../../core/interfaces/imessage';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  registerForm: FormGroup;
  loading = false;
  errors: any = [];
  hide = true;
  supervisorsArray = [];
  supervisorsArrayOriginal = [];
  supervisorsSubscription: Subscription;
  isSupervisor = false;
  supervisorCode = '';
  queryParamSubscription: Subscription;
  supervisorFromList = false;

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  emailData = null;
  searchValue: FormControl = new FormControl();
  approvalMessageEn;
  approvalMessageDE;
  superUser = superUser;
  hideSupervisorsList = true;
  chatRoom: IMessages = {
    messages: [{
      date: '',
      text: '',
      senderId: '',
      seen: [],
      file: {
        fileName: '',
        fileRef: ''
      }
    }],
    users: []
  };
  mySupervisor = null;

  /**
   * Component constructor
   *
   * @param authNoticeService: AuthNoticeService
   * @param translate: TranslateService
   * @param router: Router
   * @param auth: AuthService
   * @param store: Store<AppState>
   * @param fb: FormBuilder
   * @param cdr: ChangeDetectorRef
   * @param supervisorsService: supervisorsService
   * @param route: 1
   * @param firebaseService: firebaseService
   * @param chat: ChatService
   */
  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private supervisorsService: SupervisorsService,
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    private chat: ChatService,
  ) {
    this.unsubscribe = new Subject();
  }

  /*
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.initRegisterForm();
    this.getAllSupervisors();
    this.getEmailData();
    this.filterSupervisorsSubscribe();
    this.getEmailTemplates()
  }

  getEmailData() {
    this.firebaseService.getEmailAsSupervisorAssigned().pipe(take(1)).subscribe((template: any) => {
      this.emailData = template.content;
    });
  }

  /*
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
    this.supervisorsSubscription.unsubscribe();
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

  filterSupervisorsSubscribe() {
    this.searchValue.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((v) => {
      this.filterSupervisorsList(v);
    });
  }

  filterSupervisorsList(value) {
    if (!this.supervisorsArray) {
      return;
    }
    this.supervisorsArray = JSON.parse(JSON.stringify(this.supervisorsArrayOriginal))
    const numberSearch = /^\d+$/.test(value);
    if (numberSearch) {
      this.supervisorsArray = this.supervisorsArray.filter(user => user.id.toLowerCase().indexOf(value) > -1)
    } else {
      // tslint:disable-next-line:max-line-length
      this.supervisorsArray = this.supervisorsArray.filter(user => user.name?.toLowerCase().indexOf(value) > -1 ? user.name.toLowerCase().indexOf(value) > -1 : user.email.toLowerCase().indexOf(value) > -1)
    }
  }


  getAllSupervisors() {
    this.supervisorsArray = [];
    this.supervisorsSubscription = this.supervisorsService.getAllSupervisors().subscribe((users) => {
      this.supervisorsArray = users;
      this.supervisorsArrayOriginal = JSON.parse(JSON.stringify(users));
      this.supervisorsArray.forEach((user) => {
        if (user.id === '359352') {
          const indexToDelete = this.supervisorsArray.findIndex(user => user.id === '359352');
          this.supervisorsArray.splice(indexToDelete, 1);
        }
      })
    });
  }


  /**
   * Form initalization
   * Default params, validators
   */
  initRegisterForm() {
    this.registerForm = this.fb.group(
      {
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
            Validators.maxLength(320),
          ]),
        ],
        username: [
          null,
          Validators.compose([
            Validators.minLength(3),
            Validators.maxLength(100),
            Validators.required
          ]),
        ],
        age: [
          null,
          Validators.compose([
            Validators.minLength(1),
            Validators.maxLength(3),
          ]),
        ],
        gender: [null, Validators.compose([])],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        allowedSupervisors: [['359352'], Validators.required],
        // confirmPassword: ['', Validators.compose([
        // 	Validators.required,
        // 	Validators.minLength(3),
        // 	Validators.maxLength(100)
        // ])
        // ],
        agree: [false, Validators.compose([Validators.required])],
        isSupervisor: [false],
        supervisorCode: [''],
        recaptcha: [null , [Validators.required]],
        supervisorFromList: [this.supervisorFromList]
      }
      // {
      // 	validator: ConfirmPasswordValidator.MatchPassword
      // }
    );
    this.getQueryParams();
  }

  getQueryParams() {
    this.queryParamSubscription = this.route.queryParams.subscribe((param) => {
      if (param.id) {
        this.supervisorFromList = true;
        this.firebaseService.getUidByUserId(param.id).pipe(take(1)).subscribe((supervisor) => {
          this.mySupervisor = supervisor[0];
        });
        setTimeout(() => {
          this.registerForm.patchValue({
            allowedSupervisors: [param.id, '359352'],
            supervisorFromList: true
          });
          this.supervisorFromList = true;
          this.registerForm.controls.allowedSupervisors.disable();
          this.registerForm.controls.supervisorFromList.disable();
          this.registerForm.controls.isSupervisor.disable();
        },1000);
      }
    });
  }
  handleSuccess($event){}
  triggerSupervisorField() {
    this.isSupervisor = !this.isSupervisor;
    if (this.isSupervisor) {
      this.registerForm.get('allowedSupervisors').clearValidators();
    } else {
      this.registerForm.get('allowedSupervisors').setValidators(Validators.required);
      this.registerForm.patchValue({
        allowedSupervisors: ['359352'],
      });
      this.registerForm.get('supervisorCode').updateValueAndValidity();
    }
    this.registerForm.get('allowedSupervisors').updateValueAndValidity()
    this.cdr.markForCheck();
    if (this.isSupervisor) {
      this.registerForm.patchValue({
        allowedSupervisors: []
      });
    }
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.registerForm.controls;
    if (this.registerForm.controls.supervisorCode.value !== 'EEGeniusExpert' && this.isSupervisor) {
      this.registerForm.controls.supervisorCode.setErrors({errors: true});
    }
    // check form
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

    if (!controls.agree.value) {
      // you must agree the terms and condition
      // checkbox cannot work inside mat-form-field https://github.com/angular/material2/issues/7891
      this.authNoticeService.setNotice(
        'You must agree the terms and condition',
        'danger'
      );
      this.loading = false;
      return;
    }

    const _user: any = {};
    _user.email = controls.email.value;
    _user.username = controls.username.value;
    _user.password = controls.password.value;
    _user.gender = controls.gender.value;
    _user.age = controls.age.value;
    _user.allowedSupervisors = controls.allowedSupervisors.value
    _user.supervisorCode = controls.supervisorCode.value
    _user.email = _user.email.toLowerCase();

    this.auth
      .register(_user)
      .subscribe(
        () => {
          this.firebaseService.getUidByEmail(_user.email).pipe(takeUntil(this.unsubscribe)).subscribe((registeredUser:any) => {
            registeredUser = registeredUser[0];
            _user.allowedSupervisors.forEach((id) => {
                // @ts-ignore
                this.firebaseService.getUidByUserId(id).pipe(take(1)).subscribe((supervisor:any) => {
                  supervisor = supervisor[0];
                  if (id !== '359352') {
                    this.sendChatMessageFromSupervisor(supervisor, registeredUser.id);
                  }
                  this.sendEmailToSupervisor(supervisor, _user);
                  this.authNoticeService.setNotice(
                    this.translate.instant('AUTH.REGISTER.SUCCESS'),
                    'success'
                  );
                  this.registerForm.reset();
                  Object.keys(this.registerForm.controls).forEach((key) => {
                    this.registerForm.controls[key].setErrors(null);
                  });
                  this.registerForm.markAsUntouched();
                  this.captchaElem.resetCaptcha();
                  setTimeout(() => {
                    this.authNoticeService.setNotice(null, 'success');
                  }, 5000);
                  this.loading = false;
                  this.router.navigateByUrl('/auth/login');
                });
            });
          })
        },
        (error) => {
          this.firebaseService.getUidByEmail(_user.email).pipe(take(1)).subscribe((data) => {
            if (data || data.length) {
              this.authNoticeService.setNotice(
                this.translate.instant('AUTH.VALIDATION.ALREADY_EXISTS'),
                'danger'
              );
            } else {
              this.authNoticeService.setNotice(
                this.translate.instant('AUTH.REGISTER.FAILURE'),
                'danger'
              );
            }
          });
          setTimeout(() => {
            this.authNoticeService.setNotice(null, 'success');
          }, 5000);
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.cdr.markForCheck();
        }
      );
  }
  sendChatMessageFromSupervisor(supervisor, registeredUserId) {
    const chatId = supervisor.id + registeredUserId;
    this.chatRoom.users = [supervisor.id, registeredUserId];
    this.firebaseService.createChat(chatId, this.chatRoom).then(() => {
      this.firebaseService.getFirebaseMessages(chatId).pipe(take(1)).subscribe((chatRoom:any) => {
        const message = {
          date: Date.now(),
          text: 'Hello :)\n' +
            'This is a welcome message, I am now your supervisor.',
          senderId: supervisor.id,
          seen: [supervisor.id]
        }
        chatRoom.messages.push(message);
        this.firebaseService.sendChatMessage(chatId, chatRoom);
      });
    });
  }

  sendEmailToSupervisor(supervisor, newUser) {
    const subject = this.approvalMessageEn.subject;
    const template = this.approvalMessageEn;
    template.content = template.content.replace('{user}' , `${newUser.username}, (${newUser.email})`);
    this.firebaseService.sendEmail(Date.now().toString(), supervisor.email, template.content, subject);
    if (!supervisor.pendingList) {
      supervisor.pendingList = [];
    }
    const foundUser = supervisor.pendingList.findIndex(user => user.email === newUser.email);
    if (foundUser < 0) {
      const pendingUser = {
        id: '',
        date: Date.now(),
        email: newUser.email,
        displayName: newUser.username ? newUser.username : null
      }
      supervisor.pendingList.push(pendingUser);
      this.firebaseService.updateUser(supervisor.uid, supervisor);
    }
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }
    return control.hasError(validationType) && (control.dirty || control.touched);
  }
  handleSupervisorCode() {
    if (this.registerForm.controls.supervisorCode.status === 'INVALID') {
      return true;
    }
  }
  handleSupervisorsList(event) {
    this.supervisorFromList = event;
    if (event) {
      this.registerForm.patchValue({
        supervisorFromList: this.supervisorFromList,
        allowedSupervisors: []
      });
    } else {
      this.registerForm.patchValue({
        supervisorFromList: this.supervisorFromList,
        allowedSupervisors: ['359352']
      })
    }
  }

  shortName(fullName: string): any {
    if (fullName.split(/\W+/).length === 1) {
      return fullName[0];
    } else {
      return fullName
        .split(' ')
        .map(n => n[0])
        .join('.');
    }
  }

  getEmailTemplates() {
    this.firebaseService.getApproval().pipe(take(1)).subscribe((template: any) => {
      this.approvalMessageEn = template;
    });
    this.firebaseService.getApprovalDE().pipe(take(1)).subscribe((template: any) => {
      this.approvalMessageDE = template;
    });
  }
}
