import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TrainingTemplateDataService} from '../../../../services/training-template-data/training-template-data.service';
import {RecordService} from '../../../../services/record/record.service';
import {TrainingScreenComponent} from '../training-screen.component';
import {IUser} from '../../../../core/interfaces/user';
import {AutoTrainingService} from '../../../../services/auto-training/auto-training.service';
import {AutothresholdService} from '../../../../services/autothreshold/autothreshold.service';
import {RewardService} from '../../../../services/reward/reward.service';
import {VideoTimeService} from '../../../../services/video-time/video-time.service';
import {FirebaseService} from '../../../../services/firebase/firebase.service';
import {MuscleDataService} from '../../../../services/muscle-data.service';
import {Subject, Subscription} from 'rxjs';
import {takeUntil, throttleTime} from 'rxjs/operators';
import {TemplateTranslateService} from "../../../../services/template-translate/template-translate.service";
import {TranslationService} from "../../../../core/_base/layout";
import moment from "moment";
import {ChangeVideoPauseService} from "../../../../services/change-video-pause/change-video-pause.service";
import {ExgPacketDecoder} from "../../../../core/interfaces/exg_interface";

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'kt-trainer-template',
  templateUrl: './trainer-template.component.html',
  styleUrls: ['./trainer-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrainerTemplateComponent implements OnInit, OnChanges, OnDestroy {

  @Input() template;
  @Output() handlePause = new EventEmitter<boolean>();
  @Output() handleStart = new EventEmitter<boolean>();
  @Output() resumeTraining = new EventEmitter<any>();
  @Output() autoResume = new EventEmitter<any>();
  @Output() isStopped = new EventEmitter<any>();
  @Output() totalTrainingSeconds = new EventEmitter<any>();
  trainingEnded = false;
  @Input() user : IUser;
  selectedTemplate = {
    name: '',
    data: []
  }
  running = false;
  trainingPaused = false;

  templateIndex = 0;
  maximumTrainingTime;
  currentTemplate;
  trainingInterval;
  templateMinutes;
  seconds;
  totalSeconds = 0;
  minutes;
  totalMinutes;
  displayTimer = '00:00';
  totalDisplayTimer = '00:00';
  userRole = '';
  showNoteText = true;
  videoNameDelay;

  templateToSave = {
    name: '',
    data: []
  }

  userId = '';
  pausedAt = null;
  textSeconds = '0';
  totalTextSeconds = '0';
  statSec = 60;
  totalStatSec = 60;
  showArtefaktWarning = false;
  currentArtefakt: number;
  artefaktSubscription: Subscription;
  impedanceSubscription: Subscription;
  artefaktValue: number;
  impedanceValue: number;
  pausedByApp = false;
  templateForTraining;
  currentLanguage;
  pauseByChangeSubscription: Subscription;
  goodSignalSeconds = 0;
  previousErrorCount = 0;
  sensorErrorsTimeout;
  unsubscribe = new Subject();
  currentTemplateSeconds: number;

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private trainingData: TrainingTemplateDataService,
    private record: RecordService,
    private trainingScreen: TrainingScreenComponent,
    private autoTraining: AutoTrainingService,
    private autoThresholdService: AutothresholdService,
    private reward: RewardService,
    private videoTime: VideoTimeService,
    private firebase: FirebaseService,
    private muscleData: MuscleDataService,
    private templateTranslate: TemplateTranslateService,
    private translates: TranslationService,
    private pauseByChange : ChangeVideoPauseService
  ) { }

  ngOnInit(): void {
    this.listenForStartTraining();
    this.getCurrentLanguage();
    this.subscribeOnPauseByChangeVideo();
    this.subscribeOnYoutubePlaylistChange();
    this.subscribeOnCustomVideoChange();
    // this.handleShowWarning();
  }

  ngOnDestroy(): void {
    clearInterval(this.trainingInterval);
    this.trainingData.currentTemplate.next([]);
    this.artefaktSubscription.unsubscribe();
    if (this.impedanceSubscription) {
      this.impedanceSubscription.unsubscribe();
    }
    if (this.pauseByChangeSubscription) {
      this.pauseByChangeSubscription.unsubscribe();
    }
    this.unsubscribe.next('');
    this.unsubscribe.complete();
  }

  ngOnChanges(changes:SimpleChanges) {
    if (changes.template && changes.template.currentValue) {
      this.templateForTraining = changes.template.currentValue;
      this.selectedTemplate.data = [];
      this.selectedTemplate.name = changes.template.currentValue.name;
      if(this.impedanceSubscription) {
        this.impedanceSubscription.unsubscribe();
      }
      this.handleShowWarning();
      changes.template.currentValue.data.forEach((template) => {
        if (template.checked) {
          this.totalSeconds += +template.length * 60;
          this.selectedTemplate.data.push(template)
        }
      });
    }
    if (changes.user && changes.user.currentValue) {
      this.userRole = changes.user.currentValue.role;
      this.userId = changes.user.currentValue.id;
    }
  }

  listenForStartTraining() {
    this.trainingData.startTraining.subscribe((data) => {
      this.displayTimer = '00:00';
      this.totalDisplayTimer = '00:00';
      this.currentTemplate = null;
      clearInterval(this.trainingInterval);
      this.templateIndex = 0;
      this.running = false;
      const templatesArray = [];
      data.forEach((template) => {
        if (template.checked) {
          templatesArray.push(template);
        }
      });
      this.startTraining(templatesArray);
    });
  }

  repeatTraining(training) {
    this.resumeTraining.emit(training);
  }
  autoResumeTraining() {
    this.showArtefaktWarning = false;
    this.trainingPaused = false;
    this.autoResume.emit(true);
    const timeout = setTimeout(() => {
      this.startTraining(this.selectedTemplate.data, true, true);
      clearTimeout(timeout);
    },3000)
  }

  startTraining(templates, startByClick?, startByResume?) {
    this.autoTraining.fiveSecondsTrigger();
    this.autoTraining.thirtySecondsTrigger();
    this.showArtefaktWarning = false;
    if (startByClick) {
      this.pausedByApp = false;
      if (this.artefaktSubscription) {
        this.artefaktSubscription.unsubscribe();
      }
      if(this.impedanceSubscription) {
        this.impedanceSubscription.unsubscribe();
      }
      this.handleShowWarning();
    }
    if (this.templateIndex === templates.length) {
      clearInterval(this.trainingInterval);
      this.stopTraining();
      return;
    }
    // if (this.templateIndex > this.maximumTrainingTime && this.minutes === 0 && this.seconds === 0 && this.displayTimer === '0:01') {
    //
    // }
    if (startByResume) {
      const rescaleChartTimeout = setTimeout(() => {
        this.autoTraining.triggerChartAutoScale.next(true);
        clearTimeout(rescaleChartTimeout);
      },3000);
    }
    this.handlePause.emit(false);
    this.handleStart.emit(true);
    this.trainingPaused = false;
    this.showNoteText = true;
    this.videoNameDelay = setTimeout(() => {
      this.trainingData.currentTemplate.next(templates[this.templateIndex]);
      this.templateToSave.name = this.selectedTemplate.name;
      clearTimeout(this.videoNameDelay);
    },1000);
    this.record.isNeedRecord.next(true);
    this.trainingScreen.trainerTemplateEnded = false;
    this.running = true;
    this.templateMinutes = templates[this.templateIndex].length;
    this.seconds = this.pausedAt ? this.pausedAt : this.templateMinutes * 60;


    this.maximumTrainingTime = templates.length - 1;
    this.currentTemplate = templates[this.templateIndex];
    this.currentTemplateSeconds = this.currentTemplate.length * 60;
    if (this.currentTemplate.youtubePlaylistUrl) {
      this.triggerYoutubePlaylist(this.currentTemplate.youtubePlaylistUrl);
    }
    if (this.currentTemplate.customVideoUrl) {
      this.triggerCustomVideoUrl(this.currentTemplate.customVideoUrl);
    }

    if (!startByResume) {
      this.triggerTemplateChartOptions(this.currentTemplate);
    }

    if (!this.impedanceSubscription && !this.artefaktSubscription) {
      this.handleShowWarning();
    }

    if (this.trainingInterval) {
      return;
    }
    this.trainingInterval = setInterval(() => {
      this.minutes = Math.floor(this.seconds / 60);
      this.displayTimer =`${this.minutes}:${this.textSeconds}`;
      this.seconds--;
      if (this.statSec !== 0) this.statSec--;
      else this.statSec = 59;

      if (this.statSec < 10) {
        this.textSeconds = '0' + this.statSec;
      } else {
        // @ts-ignore
        this.textSeconds = this.statSec;
      }

      this.handleTotalTime();

      if (this.currentTemplate.youtubePlaylistUrl || this.currentTemplate.customVideoUrl) {
        this.currentTemplate.videoConfig = this.videoTime.getVideoConfig();
        if (!this.templateToSave.data[this.templateIndex]) {
          this.templateToSave.data.push(this.currentTemplate);
        } else {
          this.templateToSave.data[this.templateIndex].videoConfig.time = this.currentTemplate.videoConfig.time;
        }
      }
      if (this.currentTemplateSeconds - this.seconds >= this.currentTemplateSeconds) {
        clearInterval(this.trainingInterval);
        this.trainingInterval = null;
        this.templateIndex += 1;
        this.pausedAt = null;
        this.startTraining(this.selectedTemplate.data);
        this.autoTraining.templateIndexChanged.next(true);
        this.autoThresholdService.clearMinuteSamples();
      }
    this.cd.markForCheck();
    },1000);
    this.trainingData.trainingStarted.next(true);
    const noteTimeout = setTimeout(() => {
      this.hideNoteText();
      clearTimeout(noteTimeout);
      this.cd.markForCheck();
    }, 30 * 1000);
  }

  handleTotalTime() {
    this.totalMinutes = Math.floor(this.totalSeconds / 60);
    this.totalDisplayTimer =`${this.totalMinutes}:${this.totalTextSeconds}`;
    this.totalSeconds--;
    if (this.totalStatSec !== 0) this.totalStatSec--;
    else this.totalStatSec = 59;

    if (this.totalStatSec < 10) {
      this.totalTextSeconds = '0' + this.totalStatSec;
    } else {
      // @ts-ignore
      this.totalTextSeconds = this.totalStatSec;
    }
  }

  triggerYoutubePlaylist(url: string):void {
    this.trainingData.youtubePlaylistSubject.next(url);
  }

  triggerCustomVideoUrl(url: string): void {
    this.trainingData.customVideoSubject.next(url);
  }

  triggerTemplateChartOptions(template) {
    const chartOptions = {
      frequencyOne: template.frequencyOne,
      frequencyTwo: template.frequencyTwo,
      threshHoldType: template.threshHoldType,
      trainingProtocol: template.trainingProtocol,
      thresholdDifficult: template.thresholdDifficult,
      showLineChart: template.showLineChart,
      showBarChart: template.showBarChart,
      showVideo: template.showVideo
    }
    this.trainingData.chartOptionsSubject.next(chartOptions);
  }

  pauseTraining(pausedByClick?) {
    this.trainingPaused = true;
    this.running = false;
    this.pausedAt = this.seconds;
    clearInterval(this.trainingInterval);
    this.trainingInterval = null;
    this.handlePause.emit(true);
    if (pausedByClick) {
      this.pausedByApp = false;
      this.artefaktSubscription.unsubscribe();
      if (this.impedanceSubscription) {
        this.impedanceSubscription.unsubscribe();
      }
    }
    this.autoTraining.clearTimeouts();
  }

  stopTraining(isStopped?) {
    this.handleStart.emit(false);
    this.handlePause.emit(true);
    this.displayTimer = '00:00';
    this.totalDisplayTimer = '00:00';
    this.currentTemplate = null;
    clearInterval(this.trainingInterval);
    this.trainingInterval = null;
    this.templateIndex = 0;
    this.running = false;
    this.record.isNeedRecord.next(false);
    this.record.stopRecording.next(true);
    this.trainingScreen.trainerTemplateEnded = true;
    this.trainingData.trainingStarted.next(false);
    this.reward.canPlayVideo.next(false);
    this.cd.markForCheck();
    this.autoTraining.unSubscribeOnSuccessValue();
    this.autoTraining.canTrigger = false;
    this.hideNoteText();
    clearTimeout(this.videoNameDelay);
    this.pausedAt = null;
    this.textSeconds = '0';
    this.statSec = 60;
    this.autoThresholdService.clearMinuteSamples();
    if (this.artefaktSubscription) {
      this.artefaktSubscription.unsubscribe();
    }
    this.trainingEnded = true;
    if (this.impedanceSubscription) {
      this.impedanceSubscription.unsubscribe();
    }
    this.firebase.saveVideoTime(this.userId, this.templateToSave).then(() => {
      // this.templateToSave.data = [];
      // this.templateToSave.name = '';
    });
    if (isStopped) {
      this.isStopped.next(true);
    }
  }
  handleShowWarning() {
    this.artefaktSubscription = this.muscleData.artefaktSubject.pipe(throttleTime(1000)).subscribe((value) => {
      this.handleSignalQuality()
      this.artefaktValue = value;
      this.cd.markForCheck();
      if (this.artefaktValue > 6) {
        this.pauseTraining();
        this.pausedByApp = true;
        this.showArtefaktWarning = true;
        this.reward.canPlayVideo.next(false);
        this.playWarningSound();
      }
    });
    this.impedanceSubscription = this.muscleData.muscleDataSubject.subscribe((impedance) => {
      if (impedance && impedance.data.impedance.length) {
        this.impedanceValue = +(impedance.data.impedance[0] / 1000).toFixed(1);
        this.cd.markForCheck();
        if (this.impedanceValue > 20 || this.impedanceValue < 0) {
          this.pauseTraining();
          this.pausedByApp = true;
          this.showArtefaktWarning = true;
          this.reward.canPlayVideo.next(false);
          this.playWarningSound();
        }
        if(ExgPacketDecoder.getSensorInfo().totalErrorCount !== this.previousErrorCount) {
          clearTimeout(this.sensorErrorsTimeout);
          this.sensorErrorsTimeout = null;
        }
        if (ExgPacketDecoder.getSensorInfo().totalErrorCount > 0) {
          this.previousErrorCount = ExgPacketDecoder.getSensorInfo().totalErrorCount;
          if (!this.sensorErrorsTimeout) {
            clearTimeout(this.sensorErrorsTimeout);
            this.pauseTraining();
            this.sensorErrorsTimeout = setTimeout(() => {
              // tslint:disable-next-line:max-line-length
              if (this.previousErrorCount === ExgPacketDecoder.getSensorInfo().totalErrorCount) {
                ExgPacketDecoder.resetErrors();
                this.previousErrorCount = 0;
              }
              clearTimeout(this.sensorErrorsTimeout);
              this.sensorErrorsTimeout = null;
            }, 15000);
          }
        }
      }
    });
  }
  playWarningSound() {
    const audio = new Audio();
    audio.src = 'assets/mp3/skin-sound.wav';
    audio.play();
  }

  handleSignalQuality() {
    if (this.showArtefaktWarning) {
      if (this.artefaktValue < 6 && this.impedanceValue < 20) {
        this.goodSignalSeconds++;
        if (this.goodSignalSeconds === 3 && this.trainingScreen.trainingCountDown === 0) {
          this.autoResumeTraining();
          this.goodSignalSeconds = 0;
        }
      } else {
        this.goodSignalSeconds = 0;
      }
    }
  }
  art() {
    this.artefaktValue = 10;
    this.cd.markForCheck()
  }

  artOk() {
    this.artefaktValue = 5;
    this.cd.markForCheck()
  }

  getRatioName(index) {
    switch (index) {
      case 0 :
        return 'Delta'
      case 1 :
        return 'Theta'
      case 2 :
        return 'Lo-alpha'
      case 3 :
        return 'Alpha'
      case 4 :
        return 'Hi-alpha'
      case 5 :
        return 'Smr, lo-beta'
      case 6 :
        return 'Beta 1'
      case 7 :
        return 'Beta 2'
      case 8 :
        return 'Hi-Beta'
      case 9 :
        return 'Artefakt'
    }
  }

  hideNoteText() {
    this.showNoteText = false;
    this.cd.markForCheck();
  }

  getCurrentLanguage() {
    this.translates.currentLanguage.subscribe((language: string) => {
      if (language) {
        this.currentLanguage = language;
        this.cd.markForCheck();
      }
    });
    if (!this.currentLanguage) {
      this.currentLanguage = this.translates.getSelectedLanguage();
      this.cd.markForCheck();
    }
  }

  getCurrentTemplateNoteTranslation(template) {
    if (template.note.includes('${de}') || template.note.includes('${en}')) {
      return this.templateTranslate.getTemplateTranslation(this.currentLanguage, template, 'note');
    } else {
      return template.note;
    }
  }
  getTemplateTitle(template, character) {
    if (template[character]) {
      if (template[character].includes('${de}') || template[character].includes('${en}')) {
        return this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), template, character);
      } else {
        return template[character];
      }
    }
  }

  subscribeOnPauseByChangeVideo() {
    this.pauseByChangeSubscription = this.pauseByChange.pauseSubject.subscribe((value) => {
      if (value) {
        this.pauseTraining();
      }
    });
  }
  subscribeOnYoutubePlaylistChange() {
    this.trainingData.customYouTubeVideoSubject.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
      if (url && this.templateToSave.data[this.templateIndex]) {
        this.templateToSave.data[this.templateIndex].youtubePlaylistUrl = url;
        this.templateToSave.data[this.templateIndex].videoUrlChanged = true;
        this.trainingData.currentTemplate.next(this.templateToSave);
      }
    });
  }
  subscribeOnCustomVideoChange() {
    this.trainingData.changedCustomVideoSubject.pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
      if (url && this.templateToSave.data[this.templateIndex]) {
        this.templateToSave.data[this.templateIndex].customVideoUrl = url;
        this.templateToSave.data[this.templateIndex].videoUrlChanged = true;
        this.trainingData.currentTemplate.next(this.templateToSave);
      }
    });
  }
}
