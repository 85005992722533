import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IUser} from '../../core/interfaces/user';
import {FirebaseService} from '../../services/firebase/firebase.service';
import {ChatService} from '../../services/chat/chat.service';
import {IMessages} from '../../core/interfaces/imessage';
import {take} from 'rxjs/operators';
import {TransformDateService} from '../../services/transform-date/transform-date.service';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SupervisorsService} from '../../services/supervisors.service';
import {MatDialog} from '@angular/material/dialog';
import {FilePreviewComponent} from './file-preview/file-preview.component';
import {Subscription} from 'rxjs';
import {TranslationService} from '../../core/_base/layout';
import {EMOJI18} from '../../core/consts/emoji-i18';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {CHAT_NOTIFICATION, CHAT_NOTIFICATION_DE} from '../../core/consts/chat-templates';
import {ChatTemplate} from '../../core/interfaces/chatTemplateInterface';
import date from "../../../assets/plugins/formvalidation/src/js/validators/date";
import {UnregisterDeviceDialogComponent} from "../../views/pages/device-overview/unregister-device-dialog/unregister-device-dialog.component";
import {AddTextAnswerDialogComponent} from "./add-text-answer-dialog/add-text-answer-dialog.component";
import {QuickAnswer} from "../../views/pages/profile/quick-answers/quick-answers.component";
import {EmailTemplatesService} from "../../services/email-templates/email-templates.service";

@Component({
  selector: 'kt-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss'],
  animations: [
    trigger('expandChat', [
      // state('collapsed', style({height: '0px', minHeight: '0', width: '0'})),
      // state('expanded', style({height: '*'})),
      state('collapsed', style({opacity: '0'})),
      state('expanded', style({opacity: '1'})),
      transition('expanded <=> collapsed', animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('400ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          style({opacity: 0})
        ])
      ]
    )
  ],
})
export class ChatWidgetComponent implements OnInit {

  @Input() currentUser: IUser;
  @ViewChild('messagesList') private messagesContainer: ElementRef;
  chatRoom: IMessages = {
    messages: [{
      date: '',
      text: '',
      senderId: '',
      seen: [],
      file: {
        fileName: '',
        fileRef: ''
      }
    }],
    users: []
  };
  chatStyle = 'collapsed';
  allowedSupervisors: Array<IUser> = [];
  messagesArray: Array<IMessages> = [];
  chatUser: IUser;
  messageText = '';
  showEmoji = false;
  chatId = '';
  message = {
    date: 0,
    text: '',
    senderId: '',
    seen: [],
    file: {
      fileName: '',
      fileRef: ''
    }
  }
  file = null;
  uploadProgress = 0;
  uploadSubscription: Subscription;
  messagesSubscription: Subscription;
  isUnreadMessages = false;
  initialEnter = true;
  emoji18 = EMOJI18;
  fileExtensions = ['image/png, image/jpg, image/jpeg, image/gif, application/pdf'];
  searchValue: FormControl = new FormControl();
  searchUserValue: FormControl = new FormControl();
  chatRoomOriginal;
  searchValueLength = 0;
  showSearchField = false;
  chatTemplates: ChatTemplate[] = [];
  allowedSupervisorsOriginal: IUser[];
  showSuggestions = false;
  currentQuestion = null;
  quickAnswers = [];
  canSendMessage = true;
  chatEmailTemplate;
  chatEmailTemplateDE;

  constructor(
    private firebase: FirebaseService,
    private chat: ChatService,
    private transformDateService: TransformDateService,
    protected translate: TranslateService,
    private cd: ChangeDetectorRef,
    private supervisorsService: SupervisorsService,
    private dialog: MatDialog,
    private translations: TranslationService,
    private router: Router,
    private emailTemplates: EmailTemplatesService
  ) {
  }

  ngOnInit(): void {
    if (this.currentUser.role === 'normal') {
      this.getAllowedSupervisors();
    } else {
      this.getSupervisorUsers();
    }
    this.getQuickAnswers();
    this.searchValueSubscribe();
    this.getChatTemplates();
    this.getChatEmailTemplates();
  }
  scrollToBottomChart() {
    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
  }
  getSupervisorUsers() {
    this.supervisorsService.getMySupervisors(this.currentUser.id).subscribe((users: any) => {
      this.allowedSupervisors = users;
      this.allowedSupervisorsOriginal = this.allowedSupervisors;
      if (this.chatUser) {
        this.handleUserStatus();
      }
      this.handleUnreadMessages();
    });
  }
  getAllowedSupervisors() {
    this.currentUser.allowedSupervisors.forEach((id, index) => {
      // @ts-ignore
      this.firebase.getUidByUserId(id).subscribe((supervisor: IUser) => {
        supervisor = supervisor[0];
        const supervisorIndex = this.allowedSupervisors.findIndex(user => user.id === supervisor.id);
        if (supervisorIndex > -1) {
          this.allowedSupervisors[supervisorIndex].status = supervisor.status;
          this.allowedSupervisors[supervisorIndex].lastSeen = supervisor.lastSeen;
        } else {
          this.allowedSupervisors.push(supervisor);
        }
        this.allowedSupervisorsOriginal = this.allowedSupervisors;
        if (this.chatUser) {
          this.handleUserStatus();
        }
        if (index === this.currentUser.allowedSupervisors.length - 1) {
          this.handleUnreadMessages();
        }
      });
    });
  }
  handleUserStatus() {
    if (this.chatUser) {
      this.chatUser.status = this.allowedSupervisors.find(user => user.id === this.chatUser.id).status;
      this.cd.markForCheck();
    }
  }
  handleUnreadMessages() {
    this.allowedSupervisors.forEach((user) => {
      user.unreadMessages = 0;
      const chatId = this.chat.getChatId(user.id, this.currentUser);
      this.firebase.getFirebaseMessages(chatId).pipe(take(1)).subscribe((chatRoom: any) => {
        let unreadCount = 0;
        if (chatRoom) {
          chatRoom.messages.forEach((message) => {
            if (!message.seen.includes(this.currentUser.id)) {
              unreadCount++;
              user.unreadMessages = unreadCount;
              this.isUnreadMessages = true;
              this.sortByUnreadMessages()
              this.cd.markForCheck();
            }
          });
        }
      });
    });
  }
  getMessages() {
    this.chatId = this.chat.getChatId(this.chatUser.id, this.currentUser);
    this.messagesSubscription = this.firebase.getFirebaseMessages(this.chatId).subscribe((chatRoom: any) => {
      if (chatRoom) {
        chatRoom.messages = chatRoom.messages.slice(-100);
        this.chatRoom = chatRoom;
        this.chatRoomOriginal = JSON.parse(JSON.stringify(chatRoom));
        const currentChatUser = this.allowedSupervisors.findIndex(user => user.id === this.chatUser.id);
        this.allowedSupervisors[currentChatUser].unreadMessages = 0;
        this.cd.markForCheck();
        setTimeout(() => {
          this.scrollToBottomChart()
        }, 0)
      } else {
        this.chatRoom.users = [this.currentUser.id, this.chatUser.id];
        this.firebase.createChat(this.chatId, this.chatRoom);
      }
    });
  }
  readAllMessages() {
    this.chatRoom.messages.forEach((message) => {
      if (!message.seen.includes(this.currentUser.id)) {
        message.seen.push(this.currentUser.id);
      }
    });
    if (this.initialEnter) {
      this.initialEnter = false;
      if (this.messagesSubscription) {
        this.messagesSubscription.unsubscribe();
      }
      this.updateRoom();
      const timeout = setTimeout(() => {
        this.getMessages();
        clearTimeout(timeout);
      }, 1500);
    }
  }
  setChatUser(user) {
    this.initialEnter = true;
    user.unreadMessages = 0;
    this.isUnreadMessages = false;
    this.chatUser = user;
    this.getMessages();
    const timeout = setTimeout(() => {
      this.readAllMessages();
      clearTimeout(timeout);
    }, 1500);
  }
  expandChart() {
    this.chatStyle = 'expanded';
    this.cd.markForCheck();
  }
  collapseChart() {
    this.chatStyle = 'collapsed';
  }
  // tslint:disable-next-line:no-shadowed-variable
  transformDate(date) {
    return this.transformDateService.transformDate(date);
  }
  // tslint:disable-next-line:no-shadowed-variable
  getTimeSince(date) {
    moment.locale(this.translate.currentLang);
    return moment(date).fromNow();
  }
  setMessageText(value) {
    this.messageText = value;
  }
  addEmoji(event) {
    this.messageText = this.messageText + event.emoji.native;
    this.showEmoji = false;
  }
  toggleEmojiPicker() {
    this.showEmoji = !this.showEmoji;
  }
  hideEmojiPicker() {
    this.showEmoji = false;
  }
  sendMessage(question) {
    this.readAllMessages();
    this.message.date = Date.now();
    this.message.text = this.messageText;
    this.message.senderId = this.currentUser.id;
    if (this.chatUser.status === 'online') {
      this.message.seen = [this.chatUser.id, this.currentUser.id];
    } else {
      this.message.seen = [...this.message.seen, this.currentUser.id];
      this.sendEmailNotification();
    }
    this.chatRoom.messages.push(this.message);
    if (question) {
      const answer = {
        date: Date.now(),
        text: question.messageAnswer,
        senderId: 'bot',
        seen: [this.chatUser.id, this.currentUser.id]
      }
      this.chatRoom.messages.push(answer);
      this.message.seen = [this.chatUser.id, this.currentUser.id]
    }
    this.chatRoom.users = [this.currentUser.id, this.chatUser.id];
    if (this.file) {
      this.uploadSubscription = this.chat.setFileDownloadUrl(this.chatId, this.file).subscribe((uploadProgress) => {
        this.uploadProgress = uploadProgress;
        this.cd.markForCheck();
        if (uploadProgress === 100 && this.file) {
          this.message.file.fileRef = `chat/${this.chatId}/${this.file.name}`;
          this.message.file.fileName = this.file.name;
          this.uploadSubscription.unsubscribe();
          this.uploadProgress = 0;
          this.updateMessages();
        }
      });
    } else {
      this.updateMessages();
    }
  }
  sendQuickAnswer(answer){
    this.messageText = answer;
    this.sendMessage(null)
  }
  updateMessages() {
    this.canSendMessage = false;
    this.firebase.sendChatMessage(this.chatId, this.chatRoom).then(() => {
      this.messageText = '';
      this.message.seen = [];
      this.scrollToBottomChart();
      this.deleteFile();
      this.isUnreadMessages = false;
      this.canSendMessage = true;
      this.cd.markForCheck();
    }).catch(() => {
      this.canSendMessage = true;
    })
  }
  updateRoom() {
    this.firebase.sendChatMessage(this.chatId, this.chatRoom);
  }
  uploadFile(event) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);
    fileReader.onloadend = () => {
      this.file = event.target.files[0];
      this.cd.markForCheck();
    };
  }
  deleteFile() {
    this.file = null;
    this.message.file.fileRef = null
    this.message.file.fileName = null;
  }
  fileSizeInMb(size) {
    return (size / (1024 * 1024)).toFixed(2) + 'mb';
  }

  downloadFile(file) {
    this.chat.downloadFile(this.chatId, file);
  }

  previewFile(file) {
    this.dialog.open(FilePreviewComponent, {
      data: {
        file,
        chatId: this.chatId
      }
    })
  }
  isValidPreviewType(name) {
    name = name.split('.').pop();
    const validTypes = ['gif', 'jpeg', 'jpg', 'png', 'pdf', 'PNG'];
    if (validTypes.includes(name)) {
      return true;
    }
  }
  handleEnterEvent(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.sendMessage(null);
    }
  }
  getEmojiTranslations() {
    return this.emoji18[this.translations.getSelectedLanguage()]
  }
  searchValueSubscribe() {
    this.searchValue.valueChanges.subscribe((value: string) => {
      this.searchValueLength = value.length;
      this.chatRoom = JSON.parse(JSON.stringify(this.chatRoomOriginal));
      // @ts-ignore
      this.chatRoom.messages = this.chatRoom.messages.filter(message => message.text.toLowerCase().indexOf(value) > -1)
    });
  }
  triggerSearchField() {
    this.showSearchField = true;
  }
  hideSearchField() {
    this.showSearchField = false;
  }
  navigateToRecord(message) {
    const host: string = location.origin;
    // tslint:disable-next-line:max-line-length
    const url: string = host + String(this.router.createUrlTree(['/analysis'], {queryParams: {ids: JSON.stringify([message.commentLink.recordId]), userId: JSON.stringify(message.commentLink.userId)}}));
    window.open(url, '_blank');
  }
  sendEmailNotification() {
    // tslint:disable-next-line:max-line-length
    const template = this.chatUser.language === 'en' ? JSON.parse(JSON.stringify(this.chatEmailTemplate)) : JSON.parse(JSON.stringify(this.chatEmailTemplateDE));
    const subject = template.subject;
    template.content = template.content.replace('{currentUser}', `${this.currentUser.displayName}`);
    template.content = template.content.replace('{user}', `${this.chatUser.displayName}`);
    template.content = template.content.replace('{message}', `${this.messageText}`);
    // template = template.replace('href="#"', `href="https://esense-eegenius.com/profile"`);
    this.firebase.sendEmail(Date.now().toString(), this.chatUser.email, template.content, subject)
  }
  getChatTemplates() {
    this.firebase.getChatTemplates().pipe(take(1)).subscribe((templates: Array<ChatTemplate>) => {
      this.chatTemplates = templates;
    });
  }
  toggleSuggestions() {
    this.showSuggestions = !this.showSuggestions;
  }
  sendMessageFromSuggestions(question) {
    this.messageText = question.messageQuestion;
    this.sendMessage(question);
    this.currentQuestion = question;
  }
  sendMessageFromChild(child) {
    this.messageText = child.messageQuestion;
    this.sendMessage(child);
    this.currentQuestion = null;
  }
  getCurrentLanguage() {
    return localStorage.getItem('language') || this.translate.getDefaultLang();
  }
  suggestionsTooltip() {
    return this.translate.instant('CHAT_WIDGET.SUGGESTIONS_TOOLTIP');
  }

  dataFilterBySearch() {
    const searchValue = this.searchUserValue.value.trim().toLowerCase();
    const allSupervisors = this.allowedSupervisorsOriginal;

    if (!searchValue) {
      this.allowedSupervisors = allSupervisors;
    } else {
      this.allowedSupervisors = allSupervisors.filter(user =>
        user.email?.toLowerCase().includes(searchValue) || user.displayName?.toLowerCase().includes(searchValue));
    }
  }
  addTextToQuickAnswer() {
    const dialogRef = this.dialog.open(AddTextAnswerDialogComponent, {
      minWidth: '40vw',
      data: { message: this.messageText }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = {
          id: Date.now().toString(),
          title: result.title,
          answer: result.answer
        };
        this.firebase.saveQuickAnswer(this.currentUser.id, data);
      }
    });
  }
  getQuickAnswers(){
    this.firebase.getQuickAnswers(this.currentUser.id).subscribe((answers) => {
      this.quickAnswers = answers;
      this.cd.markForCheck();
    })
  }

  sortByUnreadMessages() {
    this.allowedSupervisors = this.allowedSupervisors.sort((a,b) => {
      return b.unreadMessages - a.unreadMessages;
    })
  }
  getChatEmailTemplates() {
    this.emailTemplates.getChatMessageTemplate().pipe(take(1)).subscribe((template) => {
      this.chatEmailTemplate = template;
    });
    this.emailTemplates.getChatMessageTemplateDE().pipe(take(1)).subscribe((template) => {
      this.chatEmailTemplateDE = template;
    });
  }
}
