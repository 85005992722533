import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MUSCLE_CMD_WRITE, MUSCLE_CONVERT_FAC, RATIO_CHANNELS} from '../../../core/consts/consts';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {auditTime, distinctUntilChanged, distinctUntilKeyChanged, skip, take} from 'rxjs/operators';
import {MuscleDataService} from '../../../services/muscle-data.service';
import {GeniusChartService} from '../../../services/genius-chart/genius-chart.service';
import {AverageRatioService} from '../../../services/average-ratio/average-ratio.service';
import {CalculateAverageService} from '../../../services/calculateAverage/calculate-average.service';
import {RewardService} from '../../../services/reward/reward.service';
import {FirebaseService} from '../../../services/firebase/firebase.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../core/reducers';
import {currentUser} from '../../../core/auth';
import {SimulatorService} from '../../../services/simulator/simulator.service';
import {BrandComponent} from '../../theme/brand/brand.component';
import {RecordService} from '../../../services/record/record.service';
import {MatDialog} from '@angular/material/dialog';
import {NoteDialogComponent} from './note-dialog/note-dialog/note-dialog.component';
import {AutothresholdService} from '../../../services/autothreshold/autothreshold.service';
import {OtherChartsService} from '../../../services/other-charts/other-charts.service';
import {TrainingTemplateDataService} from '../../../services/training-template-data/training-template-data.service';
import {SupervisorsService} from '../../../services/supervisors.service';
import {HideRecordButtonsService} from '../../../services/hideRecordButtons/hide-record-buttons.service';
import {TrainingSizesService} from '../../../services/training-page-sizes/training-sizes.service';
import {ToastrMessageService} from '../../../services/toastr-message/toastr-message.service';
import {TranslateService} from '@ngx-translate/core';
import {EmailDateService} from '../../../services/email-date/email-date.service';
import {TourService} from 'ngx-ui-tour-md-menu';
import {AutoTrainingService} from '../../../services/auto-training/auto-training.service';
import {VideoTimeService} from '../../../services/video-time/video-time.service';
import {TemplateTranslateService} from '../../../services/template-translate/template-translate.service';
import {TranslationService} from '../../../core/_base/layout';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MatSnackBar} from '@angular/material/snack-bar';
import {superUser} from 'src/app/core/consts/super-user';
import {ChartLabelsService} from '../../../services/chart-labels/chart-labels.service';
import {ExgPacketDecoder} from '../../../core/interfaces/exg_interface';
import {RecordRatingService} from '../../../services/record-rating/record-rating.service';
import {TrackingService} from '../../../services/tracking/tracking.service';
import {LimitSessionsService} from '../../../services/limit-sessions/limit-sessions.service';
import {IUser} from '../../../core/interfaces/user';

@Component({
  selector: 'kt-training-screen',
  templateUrl: './training-screen.component.html',
  styleUrls: ['./training-screen.component.scss']
})
export class TrainingScreenComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('mainRatioChart', {static: true}) mainRatioChart: any;
  @ViewChild('mainBarChart', {static: true}) mainBarChart: any;
  dataType: string;
  selectedChannel = 'bp_ch1';
  threshHoldType = 'inhibit';
  threshHoldValue = 1;
  thresholdDifficult = '70-80%';
  thresholdAdjust = 0;
  selectedAverage = 1;
  frequencyOne = 1;
  trainingType = 'ratio'
  frequencyTwo = 5;
  maxChartZoom = 16;
  zoomStep = 1;
  maxChartSeconds = 10;
  ratioChannels = RATIO_CHANNELS
  timeArray = [];
  muscleDataSubscription: Subscription;
  dataConditionSubscription: Subscription;
  refreshChartSubscription: Subscription;
  bluetoothServiceSubscription: Subscription;
  bluetoothService;
  startTimeSaved = false;
  isDataStreaming = false;
  liveSensorData = {
    data: {}
  }
  averageRatioOne: number;
  chartLabelsLength: number;
  test = false;
  datasetInterval = null;
  signalDistance = 0;
  minuteSuccess = 0;
  rateInterval;
  rateIntervalStarted = false;
  successRateArray = [];
  successRateArrayMaxLength = 600;
  changeChartWidth = false;
  userId = '';
  trainingShareInterval = null;
  trainerCredential = '';
  formError = false;
  public getUserSubscription: Subscription;
  chartStepSize = 1;
  showOptions = true;
  showOptionsManually = false;
  isSimulator = false;
  lighterColor = '';
  barAverage;
  public trainerId;
  recordSubscription: Subscription;
  isNeedRecord = false;
  recordData = {
    id: '',
    recordDate: new Date(),
    chartType: 'MENU.TRAINING_SCREEN',
    trainingTemplate: '',
    threshHoldType: this.threshHoldType,
    demoMode: false,
    badArtefaktInSeconds: 0,
    seconds: 0,
    trainingType: '',
    frequencyOne: 0,
    frequencyTwo: 0,
    note: '',
    delta: {
      data : []
    },
    theta: {
      data : []
    },
    loAlpha: {
      data : []
    },
    alpha: {
      data : []
    },
    hiAlpha: {
      data : []
    },
    smrLoBeta: {
      data : []
    },
    betaOne: {
      data : []
    },
    betaTwo: {
      data : []
    },
    hiBeta: {
      data : []
    },
    artefakt: {
      data : []
    },
    timeArray: [],
    templatesList: [],
    isCompleted: true,
    refreshInterval: 0,
    supervisorNote: '',
    sensorInfo: {
      errors: 0,
      lostPackets: 0,
      interruptions: 0
    }
  }

  dataIsComing = false;

  simulatorDataSubscription: Subscription;
  simulatorDataCondition: Subscription;
  isArtefaktTooHight = false;
  badSignalArtefactInterval;
  badSignalArtefactIntervalStarted = false;
  badSignalCount = 0;
  trainerTemplates = [];
  public trainerTemplateSelected = false;
  selectedTemplate;
  currentUser;

  public trainerTemplateEnded = false;
  trainingStarted = false;
  showCharts = {
    showLineChart: true,
    showBarChart: true
  }

  contentLoaded = false;

  contentLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  germanEmailTemplate;
  englishEmailTemplate;
  germanAddSupervisor;
  englishAddSupervisor;
  pageLoading = true;

  usersAutocomplete = [];
  autoThresholdSubscription: Subscription;
  triggerTrainingThreshold: Subscription;
  trainingThresholdCondition: Subscription;
  trainingSecondsSubscription: Subscription;

  trainingPaused = false;
  currentArtefakt: number;

  thresholdValues = {
    rewardThreshold: 0,
    inhibitThreshold:0
  }

  public trainingCountDown = 3;
  showCountDown = false;
  animateTrainingName = false;
  trainingPrepared = false;
  isTablet = false;
  recordId = null;
  shareTrainingSpeed = 500;
  customThresholdSubscription: Subscription;
  superUser = superUser;
  stopRecordSubscription: Subscription;
  dataIntervalSubscription: Subscription;
  chartUpdateIntervalSubscription: Subscription;
  databaseRecordSpeed = 0;
  databaseAverageValues = 0;
  collectedDataArray = [[],[],[],[],[],[],[],[],[],[]];
  arrayOfAverages = [];
  showEndingSpinner = false;
  sensorStateSubscription: Subscription;

  constructor(
    private muscleData: MuscleDataService,
    private chartService: GeniusChartService,
    private averageRatio: AverageRatioService,
    private average: CalculateAverageService,
    private reward: RewardService,
    private firebase: FirebaseService,
    private store: Store<AppState>,
    private simulatorService: SimulatorService,
    private aside: BrandComponent,
    private record: RecordService,
    public dialog: MatDialog,
    public autoThresholdService: AutothresholdService,
    public otherChartService: OtherChartsService,
    private trainingData: TrainingTemplateDataService,
    private supervisorsService: SupervisorsService,
    private cd: ChangeDetectorRef,
    private hideRecordButtons: HideRecordButtonsService,
    private chartSizes: TrainingSizesService,
    private toast: ToastrMessageService,
    private translate: TranslateService,
    private emailDate: EmailDateService,
    private tour: TourService,
    private autoTraining: AutoTrainingService,
    private videoTime: VideoTimeService,
    private templateTranslate: TemplateTranslateService,
    private translates: TranslationService,
    private detector: DeviceDetectorService,
    private snackBar: MatSnackBar,
    private chartLabels: ChartLabelsService,
    private recordRating: RecordRatingService,
    private trackingService: TrackingService,
    private monthSessions: LimitSessionsService
  ) {
  }
  ngOnInit(): void {

    this.detectDevice();
    this.setBodyBackGround();
    this.destroyHeader();
    this.subscribeOnVideoShow();
    this.subscribeOnAutoThreshold();
    this.subscribeToBluetoothService();
    const body = document.getElementsByTagName('body');
    body[0].style.overflow = 'hidden';
    this.contentLoadedSubject.subscribe((loaded) => {
      if (loaded) {
        this.contentLoaded = true;
        setTimeout(() => {
          this.createTrainingChart();
          this.createBarChart();
          this.getStreamingCondition();
          this.getSensorData();
          this.changeTrainingType('ratio');
          this.handleChartWidth();
          this.getUserId();
          this.subscribeOnSimulatorMode();
          this.getLighterColor();
          this.getRecordCondition();
          this.artefactSignalCondition();
          this.reward.showOptions.next(this.showOptionsManually);
          this.otherChartService.clearAmplitudeData();
          this.otherChartService.clearRatioData();
          this.getTrainingTemplateData();
          this.getTrainingCondition();
          this.handleChangeTrainerTemplate();
          this.subscribeOnChartAutoScale();
          this.subscribeOnTrainer();
          this.handleSensorConnection();
          // this.triggerLineChart();
          // this.triggerBarChart();
          this.pageLoading = false;
          // body[0].style.overflow = 'auto';
        },2000);
      }
    });
    setTimeout(() => {
      if (!this.dataIsComing) {
        this.toast.message(`${this.translate.instant('CHART_OPTIONS.CONNECT_SENSOR')}`, '', 'toast-warn');
        this.reward.canPlayVideo.next(false);
      }
    },4000);
  }
  handleSensorConnection() {
    this.sensorStateSubscription = this.muscleData.sensorConnectState.subscribe((state) => {
      if (state === false && this.selectedTemplate) {
        this.record.stopRecording.next(true);
        this.toast.message(`${this.translate.instant('TRAINING_SCREEN.SENSOR_DISCONNECT')}`, '', 'toast-danger', 10000);
      }
    });
  }

  setBodyBackGround() {
    const body = document.getElementsByTagName('body');
    body[0].style.backgroundColor = 'rgb(0 0 0 / 2%)';
  }

  deleteBodyBackground() {
    const body = document.getElementsByTagName('body');
    body[0].style.backgroundColor = 'rgb(255, 255, 255)';
  }

  getEmailTemplates() {
    this.firebase.getEmailTrainingEmailData().pipe(take(1)).subscribe((template: any) => {
      this.englishEmailTemplate = template;
    });
    this.firebase.getEmailTrainingEmailDataDE().pipe(take(1)).subscribe((template: any) => {
      this.germanEmailTemplate = template;
    });
    this.firebase.getEmailSupervisorAssignedDE().pipe(take(1)).subscribe((template: any) => {
      this.germanAddSupervisor = template;
    });
    this.firebase.getEmailSupervisorAssigned().pipe(take(1)).subscribe((template: any) => {
      this.englishAddSupervisor = template;
    });
  }

  ngAfterViewInit() {
    this.contentLoadedSubject.next(true);
  }

  artefactSignalCondition() {
    this.muscleData.tooHightArtefact.subscribe((condition) => {
      // this.isArtefaktTooHight = condition;
    })
  }

  ngOnDestroy(): void {
    this.startTimeSaved = true;
    if (this.dataConditionSubscription) {
      this.dataConditionSubscription.unsubscribe();
    }
    if (this.muscleDataSubscription) {
      this.muscleDataSubscription.unsubscribe();
    }
    this.nullifyChart();
    this.nullifyFirebaseData();
    this.mainRatioChart.destroy();
    this.averageRatio.resetArrays();
    this.mainRatioChart.data.labels = [];
    clearInterval(this.datasetInterval);
    clearInterval(this.rateInterval);
    clearInterval(this.trainingShareInterval)
    if (this.getUserSubscription) {
      this.getUserSubscription.unsubscribe();
    }
    this.reward.trainer.next('');
    if(this.simulatorDataSubscription) {
      this.simulatorDataSubscription.unsubscribe();
    }
    if(this.simulatorDataCondition) {
      this.simulatorDataCondition.unsubscribe();
    }
    if (this.badSignalArtefactInterval) {
      clearInterval(this.badSignalArtefactInterval);
    }
    if (this.autoThresholdSubscription) {
      this.autoThresholdSubscription.unsubscribe();
    }
    this.reward.canPlayVideo.next(false);
    this.trainingData.trainingStarted.next(false);
    this.chartSizes.chartSizes.next(undefined);
    this.displayHeader();
    this.deleteBodyBackground();
    this.tour.end();
    this.autoTraining.unSubscribeOnSuccessValue();
    this.autoTraining.triggerThresholdSubject.next(false);
    if (this.triggerTrainingThreshold) {
      this.triggerTrainingThreshold.unsubscribe();
    }
    if (this.trainingThresholdCondition) {
      this.trainingThresholdCondition.unsubscribe();
    }
    this.videoTime.clearYoutubeSeekTime.next(undefined);
    if (this.trainingSecondsSubscription) {
      this.trainingSecondsSubscription.unsubscribe();
    }
    this.record.isNeedRecord.next(false);
    if (this.recordSubscription) {
      this.recordSubscription.unsubscribe();
    }
    this.isNeedRecord = false;
    this.trainingData.chartOptionsSubject.next(null);
    this.autoThresholdService.clearMinuteSamples();
    this.autoTraining.unSubscribeOnSuccessValue();
    if (this.customThresholdSubscription) {
      this.customThresholdSubscription.unsubscribe();
    }
    if (this.stopRecordSubscription) {
      this.stopRecordSubscription.unsubscribe();
    }
    if (this.dataIntervalSubscription) {
      this.dataIntervalSubscription.unsubscribe();
    }
    if (this.chartUpdateIntervalSubscription) {
      this.chartUpdateIntervalSubscription.unsubscribe();
    }
    if (this.sensorStateSubscription) {
      this.sensorStateSubscription.unsubscribe();
    }
    this.muscleData.sensorConnectState.next(undefined);
  }

  refreshTraining() {
    this.trainerTemplates = [];
    this.getAllSupervisors();
    this.getOwnTrainings();
    this.getDefaultTrainingTemplates();
  }

  handleChangeTrainerTemplate() {
    this.autoTraining.templateIndexChanged.subscribe((changed) =>{
      if (changed) {
        this.successRateArray = [];
        this.minuteSuccess = 0;
        this.cd.markForCheck();
      }
    });
  }

  getUserId() {
    this.store.pipe(select(currentUser)).subscribe(user => {
      if(user && !this.currentUser) {
        this.currentUser = user;
        this.userId = user.id;
        this.getEmailTemplates();
        this.checkForUserTrainer();
        if (!this.trainerTemplates.length) {
          this.getAllSupervisors();
          this.getOwnTrainings();
          this.getDefaultTrainingTemplates();
        }
        this.subscribeOnScreenOptions();
        if (user.showCoachMarks) {
          // this.showCoachMarks();
        }
        if (this.currentUser.allowedSupervisors.length) {
          this.getMySupervisors();
        }
        this.getCustomThresholdEntry();
      }
    });
  }
  setCustomThresholdField() {
    const customThreshold = {
      value: null
    }
    this.firebase.setCustomThresholdEntry(this.userId, customThreshold);
  }

  getCustomThresholdEntry() {
    this.customThresholdSubscription = this.firebase.getCustomThresholdEntry(this.currentUser.id).pipe(skip(1)).subscribe((data:any) => {
      if (data.value) {
        this.snackBar.open(`${this.translate.instant('TRAINING_SCREEN.THRESHOLD_CHANGED')}`, '', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        this.autoTraining.clearTimeouts();
        this.autoTraining.unSubscribeOnSuccessValue();
        this.threshHoldValue = data.value;
        this.mainBarChart.options.annotation.annotations[0].value = data.value;
        this.mainRatioChart.options.annotation.annotations[0].value = data.value;
        this.cd.markForCheck();
      }
    });
  }

  showCoachMarks() {
    this.tour.initialize([
      {
        anchorId: 'hide-chart-tour',
        content: `${this.translate.instant('SUPERVISOR_TRAINING_COACHMARKS.HIDE_ELEMENT')}`,
      },
      {
        anchorId: 'hide-options-tour',
        content: `${this.translate.instant('SUPERVISOR_TRAINING_COACHMARKS.HIDE_OPTIONS')}`,
      },
      {
        anchorId: 'youtube-tour',
        content: `${this.translate.instant('SUPERVISOR_TRAINING_COACHMARKS.YOUTUBE_TEXT')}`,
      },
      {
        anchorId: 'page-tour',
        content: `${this.translate.instant('SUPERVISOR_TRAINING_COACHMARKS.PAGE_TEXT')}`,
      },
      {
        anchorId: 'signal-distance-tour',
        content: `${this.translate.instant('SUPERVISOR_TRAINING_COACHMARKS.SIGNAL_DISTANCE')}`,
      },
      {
        anchorId: 'atrefakt-tour',
        content: `${this.translate.instant('SUPERVISOR_TRAINING_COACHMARKS.ARTEFACT_TEXT')}`,
      },
      {
        anchorId: 'record-actions',
        content: `${this.translate.instant('SUPERVISOR_TRAINING_COACHMARKS.RECORD_ACTIONS')}`,
      },
    ]);
    if (this.currentUser?.role !== 'supervisor') {
      this.tour.steps.splice(6, 1);
    }
    this.tour.steps.forEach((step) => {
      step.nextBtnTitle = `${this.translate.instant('COACHMARKS_BUTTONS.NEXT')}`;
      step.prevBtnTitle = `${this.translate.instant('COACHMARKS_BUTTONS.PREV')}`
      step.enableBackdrop = true
    });
    setTimeout(() => {
      this.tour.start();
    },1000);
  }

  getTrainingCondition() {
    this.trainingData.trainingStarted.subscribe((value) => {
      this.trainingStarted = value;
      this.cd.markForCheck();
      if (this.datasetInterval) {
        this.nullifyChart();
      }
    });
  }

  getTrainingTemplateData() {
    this.trainingData.chartOptionsSubject.subscribe((options: any) => {
      if (options) {
        this.isNeedRecord = true;
        this.frequencyOne = options.frequencyOne;
        this.frequencyTwo = options.frequencyTwo;
        this.threshHoldType = options.threshHoldType;
        this.trainingType = options.trainingProtocol;
        this.thresholdDifficult = options.thresholdDifficult ? options.thresholdDifficult : '70-80%'
        this.showCharts.showLineChart = options.showLineChart;
        this.showCharts.showBarChart = options.showBarChart;
        this.nullifyChart();
        this.triggerLineChart(this.showCharts.showLineChart);
        this.triggerBarChart(this.showCharts.showBarChart);
        this.cd.markForCheck();
        this.resetTrainingAverage();
      }
    });
  }

  subscribeOnScreenOptions() {
    let lineChartState;
    this.firebase.getUserScreenOptions(this.userId).subscribe((options: any) => {
      if (options && this.dataIsComing && this.currentUser.trainerId) {
        this.showCharts.showLineChart = options.showLineChart;
        this.showCharts.showBarChart = options.showBarChart;
        lineChartState = options.showLineChart;
        this.cd.markForCheck();
      }
    });
  }


  getRecordCondition() {
    this.recordSubscription = this.record.isNeedRecord.subscribe((recordCondition) => {
      this.isNeedRecord = recordCondition;
    });
    this.stopRecordSubscription = this.record.stopRecording.pipe(take(1)).subscribe((condition) => {
      if (condition) {
        const trackingData = this.trackingService.trackingData;
        if (trackingData.length) {
          const todayTraining = trackingData.find(item => item.page === 'MENU.TRAINING_SCREEN');
          if (todayTraining) {
            todayTraining.seconds += this.recordData.seconds;
            this.trackingService.seconds = this.recordData.seconds;
            this.firebase.updateTrackingData(this.currentUser.id, todayTraining.id, todayTraining).then(() => {
              this.trackingService.seconds = 0;
            });
          } else {
            const pageObject = {
              seconds: this.recordData.seconds,
              page: 'MENU.TRAINING_SCREEN',
              date: new Date()
            }
            this.firebase.setTrackingData(this.currentUser.id, pageObject).then(() => {
              this.trackingService.seconds = 0;
            });
          }
        }
        this.sendEmailEndTrainingNotificationToUser();
        this.recordRating.getFirstPartValues(this.recordData).subscribe((record: any) => {
          this.dialog.open(NoteDialogComponent, {data: record}).afterClosed().subscribe((note) => {
            if (note) {
              record.note = note;
            }
            let sessionLength = 0;
            this.selectedTemplate.data.forEach((part) => {
              if (part.checked) {
                sessionLength += +part.length * 60;
              }
            });
            if (record.seconds > sessionLength) {
              record.seconds = sessionLength;
            }
            if (record.isCompleted && record.seconds < sessionLength) {
              record.seconds = sessionLength;
            }
            this.showEndingSpinner = true;
            if (record.isCompleted) {
              this.handleUserMonthLimit();
            }
            this.firebase.saveProcedure(this.userId, record, 'EEG')
            this.showEndingSpinner = false;
            const trainingData = {
              name: this.selectedTemplate?  this.selectedTemplate.name : '',
              day: new Date(),
              seconds: record.seconds,
              chartType: record.chartType,
              frequencyOne: this.frequencyOne,
              frequencyTwo: this.frequencyTwo,
              threshHoldType: this.threshHoldType,
              trainingType: this.trainingType,
              demoMode: record.demoMode,
              note: record.note
            }
            this.firebase.saveStatisticRecord(this.userId, trainingData);
            this.trainerTemplateSelected = false;
            this.trainingPrepared = false;
            this.selectedTemplate = null;
            this.thresholdAdjust = 0;
            setTimeout(() => {
              this.dataIntervalSubscription.unsubscribe();
              this.chartUpdateIntervalSubscription.unsubscribe();
              this.firebase.generatedId = null;
              this.isNeedRecord = false;
              this.recordData.badArtefaktInSeconds = 0;
              this.recordData.seconds = 0;
              this.recordData.note = '';
              this.collectedDataArray = [[],[],[],[],[],[],[],[],[],[]];
              this.recordData.templatesList = [];
              this.nullifyFirebaseData();
              this.resetAllDataArrays();
              this.videoTime.clearYoutubeSeekTime.next(true);
              ExgPacketDecoder.resetTrainingErrors();
              this.stopRecordSubscription.unsubscribe();
              this.getRecordCondition();
            },1500);
          });
        });
      }
    });
  }

  handleIncompleteRecord(record) {
    if (record.note === 'INCOMPLETE' || record.note === 'UNVOLLSTÄNDIG') {
      return true;
    }
  }

  resetAllDataArrays() {
    this.averageRatio.resetTrainingBarAverage();
    this.averageRatio.resetFirstRatio();
    this.averageRatio.resetSecondRatio();
    this.averageRatio.resetThirdRatio();
    this.averageRatio.resetTrainingAverage();
    this.averageRatio.resetArrays();
    this.autoThresholdService.clearMinuteSamples();
    this.successRateArray = [];
  }

  sendEmailEndTrainingNotificationToUser() {
    // const subject = `Training has been done succesfully ${this.selectedTemplate.value.name} ${this.transformDate(new Date())}`;
    // let template;
    // if (this.currentUser.langusge === 'en') {
    //   template = this.englishEmailTemplate;
    // } else if (this.currentUser.langusge === 'de') {
    //   template = this.germanEmailTemplate;
    // } else {
    //   template = this.englishEmailTemplate;
    // }
    // const year = new Date().getFullYear();
    // template.content = template.content.replace('2020' , `${year}`);
    // this.firebase.sendTrainingNotification(this.currentUser.uid, this.currentUser.email, template.content, subject).then(() => {
    //   console.log('message send')
    // }).catch((error) => {
    //   console.log(error)
    // });
  }

  sendEmailNotificationToUser(trainer) {
    let subject = `A new supervisor has been assigned to you`;
    let template;
    if (this.currentUser.language === 'en') {
      template = this.englishAddSupervisor;
      subject = this.englishAddSupervisor.subject;
    } else if (this.currentUser.language === 'de') {
      template = this.germanAddSupervisor;
      subject = this.germanAddSupervisor.subject;
    } else {
      template = this.englishAddSupervisor;
      subject = this.englishAddSupervisor.subject;
    }
    const year = new Date().getFullYear();
    template.content = template.content.replace('2020' , `${year}`);
    const date = this.emailDate.transformDate(new Date());
    template.content = template.content.replace('{date}' , `${date}`);
    template.content = template.content.replace('{supervisor}' , `${trainer.displayName}`);
    template.content = template.content.replace('{currentUser}' , `${this.currentUser.displayName}`);
    template.content = template.content.replace('{supervisorEmail}' , `${trainer.email}`);
    this.firebase.sendTrainingNotification(this.currentUser.uid, this.currentUser.email, template.content, subject).then(() => {
      console.log('message send')
    }).catch((error) => {
      console.log(error)
    });
  }

  startTrainingInterval(event) {
    if (event) {
      if (this.trainingSecondsSubscription) {
        this.trainingSecondsSubscription.unsubscribe();
      }
      this.trainingPaused = false;
      this.trainingSecondsSubscription = interval(1000).subscribe(() => {
        this.recordData.seconds++;
      });
    }
  }

  stopTrainingInterval(event) {
    if (event) {
      this.trainingPaused = true;
      this.trainingSecondsSubscription.unsubscribe();
      clearInterval(this.datasetInterval);
      this.datasetInterval = null;
    }
  }

  getSessionTimeInSeconds(record) {
    const firstTick = new Date(record.timeArray[0]);
    const lastTick = new Date(record.timeArray.slice(-1).pop());
    return Math.floor(((lastTick.getTime() - firstTick.getTime()) / 1000));
  }

  badArtifactSignalPercentage(record) {
    const recordLength = record.seconds;
    const badArtifactSeconds = record.badArtefaktInSeconds;
    const percentage = +((badArtifactSeconds * 100) / recordLength).toFixed(2);
    if (percentage > 100) {
      return 100
    } else {
      return percentage;
    }
  }

  subscribeOnSimulatorMode() {
    this.simulatorDataCondition = this.simulatorService.isSimulatorMode.subscribe((isSimulator) => {
      this.dataIsComing = isSimulator;
      this.isSimulator = isSimulator;
      // @ts-ignore
      if (this.isSimulator) {
        this.selectedAverage = 1;
        this.processSimulatorData();
      }
    })
  }

  processSimulatorData() {
    this.simulatorDataSubscription = this.simulatorService.simulatorData.subscribe((dataArray) => {
      this.selectedChannel = 'bp_ch1';
      // @ts-ignore
      this.liveSensorData.data.bp_ch1 = dataArray;
    })
  }

  startCountArtefatkBadSignal() {
    if(!this.badSignalArtefactIntervalStarted) {
      this.badSignalArtefactIntervalStarted = true;
      this.badSignalArtefactInterval = setInterval(() => {
        this.badSignalCount = this.badSignalCount + 1;
        this.recordData.badArtefaktInSeconds = this.badSignalCount;
        this.badSignalArtefactIntervalStarted = false;
        clearInterval(this.badSignalArtefactInterval);
      }, 1000);
    }
  }

  async sendDataToActivateEEGmode(bluetoothService) {
    const cmdString = new TextEncoder().encode(`SET_PACKET(0x45,ON)`).buffer;
    const CmdWriteCharacteristic = await bluetoothService.getCharacteristic(MUSCLE_CMD_WRITE);
    await CmdWriteCharacteristic.writeValue(cmdString);
  }

  subscribeToBluetoothService() {
    this.bluetoothServiceSubscription = this.muscleData.bluetoothServiceSubject.subscribe((service) => {
      if (service) {
        this.bluetoothService = service;
      }
    })
  }

  getStreamingCondition() {
    this.dataConditionSubscription = this.muscleData.dataStreamingSubject.subscribe((dataCondition) => {
      if (dataCondition) {
        this.isDataStreaming = dataCondition;
      } else {
        if (this.refreshChartSubscription) {
          this.refreshChartSubscription.unsubscribe();
        }
      }
    });
  }

  handleChartWidth() {
    this.reward.changeChartWidth.subscribe((change) => {
      this.changeChartWidth = change;
      this.cd.markForCheck();
    })
  }

  triggerHideOptions() {
    this.showOptionsManually = !this.showOptionsManually;
    this.reward.showOptions.next(this.showOptionsManually);
    this.cd.markForCheck();
  }

  subscribeOnTrainer() {
    this.reward.trainer.subscribe((trainer) => {
      if (trainer) {
        this.shareTrainingInterval();
      } else {
        clearInterval(this.trainingShareInterval);
        this.trainingShareInterval = null;
      }
    });
  }
  shareTrainingInterval() {
    this.trainingShareInterval = setInterval(() => {
      if (this.trainingPrepared && this.trainerId) {
        this.shareSensorData();
      }
    },this.shareTrainingSpeed);
  }
  changeShareTrainingInterval() {
    clearInterval(this.trainingShareInterval);
    this.trainingShareInterval = null;
    this.shareTrainingInterval();
  }

  getSensorData(): void {
    this.muscleDataSubscription = this.muscleData.muscleDataSubject.subscribe(async (data) => {
      if (data && data.data[this.selectedChannel]) {
        const artefactValue = ((data.data[this.selectedChannel].slice(-1).pop()) * MUSCLE_CONVERT_FAC).toFixed(2);
        this.muscleData.artefaktSubject.next(artefactValue);
        this.currentArtefakt = +artefactValue;
        this.dataIsComing = true;
        this.dataType = data.data_type_id_text;
        if (this.dataType === 'EEG_BP' && !this.isArtefaktTooHight) {
          this.liveSensorData = data;
        }
        if (!this.startTimeSaved && this.isDataStreaming) {
          this.startTimeSaved = true;
          await this.sendDataToActivateEEGmode(this.bluetoothService);
        }
      } else {
        this.isDataStreaming = false;
      }
    })
  }
  sensorDataInterval() {
    const dataInterval = interval(this.databaseRecordSpeed);
    this.dataIntervalSubscription = dataInterval.subscribe(() => {
        if (this.isNeedRecord) {
          this.processSensorDataToFirebase(this.arrayOfAverages);
        }
    });
    const chartUpdateInterval = interval(this.isIosTablet() ? 100 : 100);

    this.chartUpdateIntervalSubscription = chartUpdateInterval.subscribe(() => {
      this.processSensorData();
      this.collectDataForAveraging(this.liveSensorData.data[this.selectedChannel]);
    });
  }
  collectDataForAveraging(sensorData) {
    if (sensorData && sensorData.length) {
      sensorData.forEach((value, index) => {
        if (value && index >= 0 && index < 10) {
          this.collectedDataArray[index].push(value);
          if (this.collectedDataArray[index].length > this.databaseAverageValues) {
            this.collectedDataArray[index].shift();
          }
        }
      });
      this.setDatabaseAverageArrays(this.collectedDataArray);
    }
  }
  setDatabaseAverageArrays(arrays) {
    this.arrayOfAverages = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < arrays.length; i++) {
      const subarray = arrays[i];
      const sum = subarray.reduce((acc, value) => +acc + +value, 0);
      const average = sum / subarray.length;
      this.arrayOfAverages.push(+average.toFixed(2));
    }
    return this.arrayOfAverages;
  }

  subscribeOnVideoShow() {
    setTimeout(() => {
      this.reward.youtubeVideoShow.pipe(distinctUntilChanged()).subscribe((condition) => {
        const youtubeContainer = document.getElementsByClassName('training-right');
        const trainingLeft = document.getElementsByClassName('training-left');
        if (!condition) {
          // @ts-ignore
          youtubeContainer[0].style.width = '20%';
          // @ts-ignore
          trainingLeft[0].style.width = '75%';
        } else {
          // @ts-ignore
          youtubeContainer[0].style.width = '45%';
          // @ts-ignore
          trainingLeft[0].style.width = '50%';
        }
      });
    },1500);
  }

  rescaleChartResponsive() {
    const body = document.getElementsByTagName('body')[0];
    const originalBodyWidth = body.getBoundingClientRect().width;
    let rescaledWidth = originalBodyWidth - 100;
    body.style.width = `calc(${originalBodyWidth}px - 100px)`;
    const updateBodyInterval = setInterval(() => {
      rescaledWidth++;
      body.style.width = `${rescaledWidth}px`
      if (rescaledWidth === originalBodyWidth) {
        clearInterval(updateBodyInterval);
        this.setBodyAutoWidth();
      }
    },16);
  }

  setBodyAutoWidth() {
    const body = document.getElementsByTagName('body')[0];
    body.style.width = null;
  }

  triggerLineChart(conditionByTemplate?) {
    if (conditionByTemplate !== undefined) {
      this.showCharts.showLineChart = conditionByTemplate;
    } else {
     this.showCharts.showLineChart = !this.showCharts.showLineChart;
    }
    if (this.showCharts.showLineChart) {
      // this.rescaleChartResponsive();
    }
    const chartsContainer = document.getElementsByClassName('training-left');
    if (!this.showCharts.showLineChart && !this.showCharts.showBarChart) {
      // @ts-ignore
      chartsContainer[0].style.width = '4%';
      // @ts-ignore
      chartsContainer[0].style.marginRight = '40px'
      this.chartSizes.chartSizes.next(this.showCharts);
    } else if (!this.showCharts.showLineChart && this.showCharts.showBarChart) {
      setTimeout(() => {
        const barChartContainer = document.getElementsByClassName('bar-chart');
        const youtubeContainer = document.getElementsByClassName('training-right');
        // @ts-ignore
        youtubeContainer[0].style.width = '100%';
        // @ts-ignore
        barChartContainer[0].style.width = '100%';
        // @ts-ignore
        chartsContainer[0].style.width = '10%';
        // @ts-ignore
        chartsContainer[0].style.marginRight = '40px'
      },1000);
    }
    else if (this.showCharts.showLineChart && this.showCharts.showBarChart) {
      setTimeout(() => {
        const barChartContainer = document.getElementsByClassName('bar-chart');
        const youtubeContainer = document.getElementsByClassName('training-right');
        // @ts-ignore
        youtubeContainer[0].style.width = '45%';
        // @ts-ignore
        barChartContainer[0].style.width = '12%';
        // @ts-ignore
        chartsContainer[0].style.width = '50%';
        // @ts-ignore
        chartsContainer[0].style.marginRight = '0'
      },1000);
    } else {
      // @ts-ignore
      chartsContainer[0].style.width = '50%';
      this.chartSizes.chartSizes.next(undefined);
    }
    this.cd.markForCheck();
  }

  triggerBarChart(conditionByTemplate?) {
    if (conditionByTemplate !== undefined) {
      this.showCharts.showBarChart = conditionByTemplate;
    } else {
     this.showCharts.showBarChart = !this.showCharts.showBarChart;
    }
    const chartsContainer = document.getElementsByClassName('training-left');
    if (!this.showCharts.showLineChart && !this.showCharts.showBarChart) {
      // @ts-ignore
      chartsContainer[0].style.width = '4%';
      this.chartSizes.chartSizes.next(this.showCharts);
    } else if (this.showCharts.showLineChart && !this.showCharts.showBarChart) {
      const youtubeContainer = document.getElementsByClassName('training-right');
      // @ts-ignore
      youtubeContainer[0].style.width = '60%';
      // @ts-ignore
      chartsContainer[0].style.width = '39%';
    } else if (!this.showCharts.showLineChart && this.showCharts.showBarChart) {
      setTimeout(() => {
        const barChartContainer = document.getElementsByClassName('bar-chart');
        // @ts-ignore
        barChartContainer[0].style.width = '100%';
        // @ts-ignore
        chartsContainer[0].style.width = '10%';
        // @ts-ignore
        chartsContainer[0].style.marginRight = '40px'
      },1000);
    }
    else {
      // @ts-ignore
      chartsContainer[0].style.width = '50%';
      this.chartSizes.chartSizes.next(undefined);
    }
    this.cd.markForCheck();
  }

  createBarChart() {
    const barChartCanvas = document.getElementById('mainBarChart');
    if (barChartCanvas) {
      this.mainBarChart = this.chartService.createTrainingBarChart(barChartCanvas, this.maxChartZoom, this.threshHoldValue);
      this.mainBarChart.update();
      this.cd.markForCheck();
    }
  }

  getChartStepSize() {
    if (this.maxChartSeconds <= 60) {
      return 1
    } else if (this.maxChartSeconds >= 60) {
      return 20
    } else {
      return 30;
    }
  }

  createTrainingChart() {
    const stepSize = this.getChartStepSize();
    this.chartStepSize = stepSize;
    const lineChartCanvas = document.getElementById('mainLineChart');
    if (lineChartCanvas) {
      this.mainRatioChart = this.chartService.createTrainingRatioChart(lineChartCanvas, stepSize, this.maxChartZoom, this.threshHoldValue);
      this.createChartLabels(this.mainRatioChart);
    }
  }

  changeTrainingType(event) {
    this.averageRatio.resetTrainingAverage();
    this.trainingType = event;
    if (event === 'amplitude' && this.mainRatioChart) {
      this.selectedAverage = 1;
      this.maxChartZoom = 16;
      this.zoomStep = 4;
      this.mainRatioChart.options.scales.yAxes[0].ticks.max = 16;
      this.mainBarChart.options.scales.yAxes[0].ticks.max = 16;
      this.changeChartColor(this.frequencyOne);
      this.cd.markForCheck();
    }
    if (event === 'ratio' && this.mainRatioChart) {
      this.selectedAverage = 1;
      this.maxChartZoom = 5;
      this.zoomStep = 1;
      this.mainRatioChart.options.scales.yAxes[0].ticks.max = 5;
      this.mainBarChart.options.scales.yAxes[0].ticks.max = 5;
      this.changeChartColor(9);
      this.cd.markForCheck();
    }
  }

  createChartLabels(chart) {
    chart.data.labels = this.chartLabels.generateChartLabels(this.maxChartSeconds, this.isIosTablet() ? 100 : 100);
    this.chartLabelsLength = chart.data.labels.length;
    chart.update();
    this.cd.markForCheck();
  }

  changeMovingAverage($event) {
    this.selectedAverage = +$event.target.value;
    this.averageRatio.resetArrays();
  }

  changeMovingAverageBar($event) {
    this.averageRatio.resetTrainingBarAverage();
  }

  getMySupervisors() {
    this.usersAutocomplete = [];
    this.currentUser.allowedSupervisors.forEach((id) => {
      this.firebase.getUidByUserId(id).pipe(take(1)).subscribe((supervisor: any) => {
        supervisor = supervisor[0];
        if (supervisor) {
          this.usersAutocomplete.push(supervisor);
        }
      });
    });
  }

  getSelectedUsers(event) {
    this.trainerCredential = event.value;
  }

  addTrainer() {
    if (this.trainerCredential.includes('@')) {
      this.getUserSubscription = this.firebase.getUidByEmail(this.trainerCredential).pipe(take(1)).subscribe((trainer:any) => {
        if (trainer.length && trainer[0].id !== this.userId) {
          this.reward.trainer.next(trainer[0]);
          this.createTrainerFields(trainer[0].id);
          this.formError = false;
          this.cd.markForCheck();
          this.setCustomThresholdField();
          this.getCustomThresholdEntry();
        } else {
          this.formError = true;
          this.cd.markForCheck();
        }
      });
    } else {
      this.getUserSubscription = this.firebase.getUidByUserId(this.trainerCredential).pipe(take(1)).subscribe((trainer:any) => {
        if (trainer.length && trainer[0].id !== this.userId) {
          this.reward.trainer.next(trainer[0]);
          this.createTrainerFields(trainer[0].id);
          this.formError = false;
          this.cd.markForCheck();
          this.setCustomThresholdField();
          this.getCustomThresholdEntry();
        } else {
          this.formError = true;
          this.cd.markForCheck();
        }
      })
    }
  }

  destroyHeader() {
    this.aside.toggleAsideClick();
  }

  displayHeader() {
    this.aside.toggleAsideClick();
    this.aside.collapseData = false;
    document.body.classList.toggle('aside-minimize');
    const header = document.getElementById('kt_header');
    const content = document.getElementById('kt_wrapper');
    content.style.paddingTop = null;
    header.style.opacity = '1';
    header.style.zIndex = '1';
  }

  transformDate(date) {
    if (date) {
      const time = new Date(date);
      const trainingDay = time.toLocaleDateString();
      const hour = time.getHours();
      const minutes = time.getMinutes();
      if (minutes < 10) {
        return trainingDay + ' ' + '-' + ' ' + hour + ':' + `0${minutes}`;
      }
      return trainingDay + ' ' + '-' + ' ' + hour + ':' + minutes;
    }
  }

  checkForUserTrainer() {
    this.firebase.getUidByUserId(this.userId).pipe(take(1)).subscribe((user:any) => {
      if (user[0].trainerId) {
        this.firebase.getUidByUserId(user[0].trainerId).pipe(take(1)).subscribe((trainer:any)=> {
          this.firebase.getTrainerOptions(user[0].trainerId, 'Training').subscribe((options: any) => {
            if (options[0].usersList.includes(this.userId)) {
              this.reward.trainer.next(trainer[0]);
              this.subscribeOnTrainerOptions(user[0].trainerId);
            }
          })
        })
      }
    });
  }

  getAllSupervisors() {
    if (this.currentUser.allowedSupervisors) {
      this.currentUser.allowedSupervisors.forEach((id) => {
        this.getTrainerTemplates(id);
      });
    }
  }

  getTrainerTemplates(trainerId) {
    this.firebase.getTrainingConfiguration(trainerId).pipe(take(1)).subscribe((templates:any) => {
      templates.forEach((template) => {
        template.selectedUsers.forEach((item) => {
          if (item === this.userId) {
            if (template.name.includes('${de}') || template.name.includes('${en}')) {
              template.name = this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), template, 'name');
            }
            this.trainerTemplates.push(template);
            this.cd.markForCheck();
          }
        });
      });
    });
  }
  setupTrainingComplete(training) {
    if (training.templateToTrain) {
      this.setTrainerTemplate(training.templateToTrain);
    }
    if (this.currentUser.role !== 'normal') {
      training.listOfTrainings.forEach((item) => {
        const itemIndex = this.trainerTemplates.findIndex(template => template.id === item.id)
        if (itemIndex < 0) {
          this.trainerTemplates.push(item);
        }
      })
    }
    // if (!this.trainerTemplates.length && this.currentUser.role === 'normal') {
    //   if (trainings && trainings.length) {
    //     this.trainerTemplates = [];
    //     this.trainerTemplates = [...trainings];
    //   }
    // } else {
    //   if (trainings && trainings.length) {
    //     trainings.forEach((training)  => {
    //       this.trainerTemplates.push(training)
    //     });
    //   }
    // }
    this.trainingPrepared = true;
    this.cd.markForCheck();
  }

  getDefaultTrainingTemplates() {
    // this.firebase.getDefaultTrainingConfigurations().subscribe((data: any) => {
    //   data.forEach((item) => {
    //     item.defaultConfiguration = true;
    //     if (item.name.includes('${de}') || item.name.includes('${en}')) {
    //       item.name = this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), item, 'name');
    //     }
    //     this.trainerTemplates.push(item);
    //     this.trainerTemplates = this.trainerTemplates.sort((a, b) => a.name.localeCompare(b.name));
    //     this.cd.markForCheck();
    //   });
    // });
  }

  getOwnTrainings() {
    if (this.currentUser && this.currentUser.role === 'normal') {
      return;
    }
    this.firebase.getTrainingConfiguration(this.currentUser.id).subscribe((data: any) => {
      if (data) {
        data.forEach((template) => {
          if (template.name.includes('${de}') || template.name.includes('${en}')) {
            template.name = this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), template, 'name');
          }
          this.trainerTemplates.push(template);
          this.cd.markForCheck();
        })
      }
    })
  }

  setTrainerForUser(trainerId) {
    this.firebase.getUidByUserId(this.userId).pipe(take(1)).subscribe((user:any) => {
      const userCredentials = user[0];
      userCredentials.trainerId = trainerId;
      this.firebase.updateUser(user[0].uid, userCredentials);
    })
  }

  createTrainerFields(trainerId) {
    this.firebase.getTrainerOptions(trainerId, 'Training').pipe(take(1)).pipe(skip(1)).subscribe((options:any) => {
      if(options.length) {
        if (options[0].usersList.indexOf(this.userId) < 0) {
          options[0].usersList.push(this.userId);
          this.firebase.setTrainingOptions(trainerId, 'Training' , options[0]);
        }
      } else {
        const chartOptions = {
          maxChartZoom: this.maxChartZoom,
          selectedChannel: this.selectedChannel,
          maxChartSeconds: this.maxChartSeconds,
          zoomStep: this.zoomStep,
          selectedAverage: this.selectedAverage,
          threshHoldType: this.threshHoldType,
          thresholdValue: this.threshHoldValue,
          usersList: [this.userId],
          trainerUserId: trainerId,
          showUserOptions: this.showOptions,
          ratioOne: this.frequencyOne,
          ratioTwo: this.frequencyTwo,
          trainingType: this.trainingType,
        }
        this.firebase.setTrainingOptions(trainerId, 'Training' , chartOptions);
      }
    })
    this.subscribeOnTrainerOptions(trainerId);
  }

  subscribeOnTrainerOptions(trainerId) {
    this.trainerId = trainerId;
    this.setTrainerForUser(trainerId);
    this.getUserSubscription = this.firebase.getTrainerOptions(trainerId, 'Training').pipe(skip(1)).subscribe((options: any) => {
      if (!options[0].selectedUserIds.length){
        this.setTrainerOptions(options);
      }
      if (options[0].selectedUserIds.length) {
        if (options[0].selectedUserIds.includes(this.userId) && options[0].usersList.includes(this.userId)) {
          this.setTrainerOptions(options)
        }
      }
    })
  }

  setTrainerOptions(options) {
    if (options.length) {
      if (this.maxChartSeconds !== options[0].maxChartSeconds) {
        this.changeChartUpdateInterval({target: {value: +options[0].maxChartSeconds}});
      }
      if(this.selectedAverage !== options[0].selectedAverage) {
        this.changeMovingAverage({target: {value: +options[0].selectedAverage}})
      }
      if (options[0].trainingTypeChanged) {
        this.snackBar.open(`${this.translate.instant('TRAINING_SCREEN.THRESHOLD_AUTO')}`, '', {
          duration: 4000,
          horizontalPosition: 'center'
        });
        this.autoTraining.clearTimeouts();
        this.autoTraining.unSubscribeOnSuccessValue();
        this.autoTraining.fiveSecondsTrigger();
        this.autoTraining.thirtySecondsTrigger();
      }
      this.maxChartZoom = +options[0].maxChartZoom;
      this.selectedChannel = options[0].selectedChannel;
      this.maxChartSeconds = options[0].maxChartSeconds;
      this.zoomStep = options[0].zoomStep;
      this.selectedAverage = options[0].selectedAverage;
      this.threshHoldType = options[0].threshHoldType;
      this.showOptions = options[0].showUserOptions;
      this.frequencyOne = options[0].ratioOne;
      this.frequencyTwo = options[0].ratioTwo;
      this.trainingType = options[0].trainingType;
      this.thresholdDifficult = options[0].thresholdDifficult ? options[0].thresholdDifficult : '80-90%';
      if (options[0].thresholdDifficult) {
        this.setTrainingDifficult()
      }
      this.changeTrainingType(options[0].trainingType);
      this.changeMovingAverage({target: {value:options[0].selectedAverage}})
      this.changeChartZoomLevel({target: {value: +options[0].maxChartZoom}});
      this.cd.markForCheck();
    }
  }


  changeThreshHoldValue($event) {
    this.mainBarChart.options.annotation.annotations[0].value = +$event.target.value;
    this.mainRatioChart.options.annotation.annotations[0].value = +$event.target.value;
    this.threshHoldValue = +$event.target.value;
    this.cd.markForCheck();
  }

  resetChartArrayData() {
    this.timeArray = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.mainRatioChart.data.datasets.length; i++) {
      this.mainRatioChart.data.datasets[i].data = [];
    }
    this.cd.markForCheck()
  }

  processSensorData() {
    if (this.selectedTemplate && this.trainingCountDown !== 0) {
      return;
    }
    if (this.trainerTemplateEnded || this.trainingPaused) {
      this.reward.canPlayVideo.next(false);
      return;
    }
    const channelDataOne = this.liveSensorData.data[this.selectedChannel][this.frequencyOne];
    const channelDataTwo = this.liveSensorData.data[this.selectedChannel][this.frequencyTwo];

    let averageChannels;
    if (this.trainingType === 'ratio') {
      averageChannels = (channelDataOne / channelDataTwo);
    }
    if (this.trainingType === 'amplitude' && !this.isSimulator) {
      averageChannels = (channelDataOne * MUSCLE_CONVERT_FAC).toFixed(2);
    }
    if (this.trainingType === 'amplitude' && this.isSimulator) {
      averageChannels = channelDataOne;
    }
    if (this.liveSensorData && this.liveSensorData.data[this.selectedChannel] && !this.isArtefaktTooHight) {
      // tslint:disable-next-line:max-line-length
      const average = +this.averageRatio.trainingAverage(+averageChannels, this.isSimulator? this.selectedAverage * 25 : this.selectedAverage);
      this.thresholdValues = this.autoThresholdService.getThresholdValues();
      if (average > this.mainRatioChart.options.scales.yAxes[0].ticks.max ) {
        this.setChartAutoScale();
      }
      if (average && this.mainRatioChart) {
        this.averageRatioOne = average;
        this.drawChartData(average);
        this.cd.markForCheck();
        this.handleRewardType(average);
        this.rateIntervalCount(average);
      }
    }
  }

  subscribeOnAutoThreshold() {
    this.autoThresholdSubscription = this.autoTraining.triggerThresholdSubject.subscribe((condition) => {
      if (condition) {
        this.setAutoThreshold();
      }
    });
  }

  checkBetween(value, min, max) {
    return value >= min && value <= max;
  }

  clearThresholdAdjust() {
    this.thresholdAdjust = 0;
  }

  setAutoThreshold() {
    if (this.threshHoldType === 'inhibit' && this.dataIsComing) {
      const autoThreshold = this.thresholdValues.inhibitThreshold;
      this.mainBarChart.options.annotation.annotations[0].value = +autoThreshold;
      this.mainRatioChart.options.annotation.annotations[0].value = +autoThreshold;
      this.threshHoldValue = +autoThreshold;
      this.maxChartZoom = this.mainRatioChart.options.scales.yAxes[0].ticks.max;
      this.cd.markForCheck();
    } else if (this.threshHoldType === 'reward' && this.dataIsComing) {
      const autoThreshold = this.thresholdValues.rewardThreshold;
      this.mainBarChart.options.annotation.annotations[0].value = +autoThreshold;
      this.mainRatioChart.options.annotation.annotations[0].value = +autoThreshold;
      this.threshHoldValue = +autoThreshold;
      this.maxChartZoom = this.mainRatioChart.options.scales.yAxes[0].ticks.max;
      this.cd.markForCheck()
    }
  }

  shareSensorData() {
    const liveData = {
      data: this.mainRatioChart.data.datasets[0].data,
      averageOscilloscope: this.selectedAverage,
      thresholdValue: this.threshHoldValue,
      successRate: this.minuteSuccess,
      signalDistance: this.signalDistance,
      lineColor: this.mainRatioChart.data.datasets[0].borderColor,
      labels: this.mainRatioChart.data.labels,
      chartZoom: +this.maxChartZoom,
      trainingType: this.trainingType,
      barColor: this.mainBarChart.data.datasets[0].backgroundColor,
      stepSize: this.chartStepSize,
      showUserOptions: this.showOptions,
      threshHoldType: this.threshHoldType,
      lighterColor: this.lighterColor,
      sensorValue: this.averageRatioOne,
      ratioOne: this.frequencyOne,
      ratioTwo: this.frequencyTwo,
      // barAverage: this.barAverage,
    }
    this.firebase.shareSession(this.userId, liveData, 'Sharing');
  }

  calculateThresholdPercent() {
    const x = this.averageRatioOne;
    let y = this.threshHoldValue;
    if (y === 0) {
      y = 0.1;
    }
    this.signalDistance = +((x / y * 100) - 100).toFixed(2);
  }

  processSensorDataToFirebase(dataArray) {
    if (dataArray.length < 10 && !this.isSimulator) {
      dataArray =  JSON.parse(JSON.stringify(ExgPacketDecoder.lastCorrectData.data.bp_ch1));
    }
    if (this.trainingPaused) {
      return;
    }
    if (this.isSimulator) {
      this.recordData.demoMode = true;
      if (dataArray.length) {
       try {
         this.recordData.delta.data.push(Number(dataArray[0]));
         this.recordData.theta.data.push(Number(dataArray[1]));
         this.recordData.loAlpha.data.push(Number(dataArray[2]));
         this.recordData.alpha.data.push(Number(dataArray[3]));
         this.recordData.hiAlpha.data.push(Number(dataArray[4]));
         this.recordData.smrLoBeta.data.push(Number(dataArray[5]));
         this.recordData.betaOne.data.push(Number(dataArray[6]));
         this.recordData.betaTwo.data.push(Number(dataArray[7]));
         this.recordData.hiBeta.data.push(Number(dataArray[8]));
         this.recordData.artefakt.data.push(Number(dataArray[9]));
         this.recordData.frequencyOne = this.frequencyOne;
         this.recordData.frequencyTwo = this.frequencyTwo;
         this.recordData.trainingType = this.trainingType;
         if (this.threshHoldType === 'reward') {
           this.recordData.threshHoldType = 'CHART_OPTIONS.THRESHOLD_TYPE_REWARD';
         } else {
           this.recordData.threshHoldType = 'CHART_OPTIONS.THRESHOLD_TYPE_INHIBIT';
         }
       }
       catch (error) {
         this.toast.message(`${error}`, '', 'toast-danger');
       }
      }
    } else {
      this.recordData.demoMode = false;
      if (dataArray.length) {
        // if (dataArray[9] * MUSCLE_CONVERT_FAC > 6) {
        //   return;
        // }
        try {
          this.recordData.delta.data.push(dataArray[0] * MUSCLE_CONVERT_FAC);
          this.recordData.theta.data.push(dataArray[1] * MUSCLE_CONVERT_FAC);
          this.recordData.loAlpha.data.push(dataArray[2] * MUSCLE_CONVERT_FAC);
          this.recordData.alpha.data.push(dataArray[3] * MUSCLE_CONVERT_FAC);
          this.recordData.hiAlpha.data.push(dataArray[4] * MUSCLE_CONVERT_FAC);
          this.recordData.smrLoBeta.data.push(dataArray[5] * MUSCLE_CONVERT_FAC);
          this.recordData.betaOne.data.push(dataArray[6] * MUSCLE_CONVERT_FAC);
          this.recordData.betaTwo.data.push(dataArray[7] * MUSCLE_CONVERT_FAC);
          this.recordData.hiBeta.data.push(dataArray[8] * MUSCLE_CONVERT_FAC);
          this.recordData.artefakt.data.push(dataArray[9] * MUSCLE_CONVERT_FAC);
          this.recordData.frequencyOne = this.frequencyOne;
          this.recordData.frequencyTwo = this.frequencyTwo;
          this.recordData.trainingType = this.trainingType;
          this.recordData.sensorInfo.errors = ExgPacketDecoder.getTrainingErrors().errors;
          this.recordData.sensorInfo.lostPackets = ExgPacketDecoder.getTrainingErrors().lostPackets;
          if (this.threshHoldType === 'reward') {
            this.recordData.threshHoldType = 'CHART_OPTIONS.THRESHOLD_TYPE_REWARD';
          } else {
            this.recordData.threshHoldType = 'CHART_OPTIONS.THRESHOLD_TYPE_INHIBIT';
          }
        }
        catch (error) {
          this.toast.message(`${error}`, '', 'toast-danger')
        }
      }
    }
  }

  changeChartColor(index) {
    if (this.mainRatioChart.data.datasets[0]) {
      switch (index) {
        case 0 :
          this.mainRatioChart.data.datasets[0].borderColor = '#FF00FF';
          break;
        case 1 :
          this.mainRatioChart.data.datasets[0].borderColor = '#0000FF';
          break;
        case 2 :
          this.mainRatioChart.data.datasets[0].borderColor = '#00FF00';
          break;
        case 3 :
          this.mainRatioChart.data.datasets[0].borderColor = '#00FF00';
          break;
        case 4 :
          this.mainRatioChart.data.datasets[0].borderColor = '#198200';
          break;
        case 5 :
          this.mainRatioChart.data.datasets[0].borderColor = '#d1d103';
          break;
        case 6 :
          this.mainRatioChart.data.datasets[0].borderColor = '#ffaa00';
          break;
        case 7 :
          this.mainRatioChart.data.datasets[0].borderColor = '#d86c00';
          break;
        case 8 :
          this.mainRatioChart.data.datasets[0].borderColor = '#FF0000';
          break;
        case 9 :
          this.mainRatioChart.data.datasets[0].borderColor = '#000000';
      }
      this.cd.markForCheck();
    }
  }

  getLighterColor() {
    this.reward.lighterColor.subscribe((color) => {
      this.lighterColor = color;
    })
  }

  rateIntervalCount(average) {
    if (!this.rateIntervalStarted) {
      this.rateIntervalStarted = true;
      this.rateInterval = setInterval(() => {
        if (this.threshHoldType === 'reward') {
          if (average >= this.threshHoldValue) {
            this.successRateArray.push(true)
          }
          else{
            this.successRateArray.push(false)
          }
        }
        else if (this.threshHoldType === 'inhibit') {
          if (average <= this.threshHoldValue){
            this.successRateArray.push(true)
          }
          else{
            this.successRateArray.push(false)
          }
        }
        if (this.successRateArray.length > 60) {
          this.successRateArray.splice(0 ,this.successRateArray.length - this.successRateArrayMaxLength);
        }
        let successCount = 0;
        this.successRateArray.forEach((item) => {
          if (item) {
            successCount++;
          }
        });
        this.minuteSuccess = Number(((successCount * 100) / this.successRateArray.length).toFixed(2));
        this.autoTraining.successRateValue = this.minuteSuccess;
        this.autoTraining.handleSuccessRateValue();
        this.rateIntervalStarted = false;
        clearInterval(this.rateInterval);
      },100)
    }
  }

  handleRewardType(average) {
    if(this.threshHoldType === 'reward' && average >= this.threshHoldValue) {
      this.mainBarChart.data.datasets[0].backgroundColor = '#00FF00';
      this.reward.canPlayVideo.next(true);
    } else if (this.threshHoldType === 'reward' && average < this.threshHoldValue) {
      this.mainBarChart.data.datasets[0].backgroundColor = '#FF0000';
      this.reward.canPlayVideo.next(false);
    }
    if(this.threshHoldType === 'inhibit' && average >= this.threshHoldValue) {
      this.mainBarChart.data.datasets[0].backgroundColor = '#FF0000';
      this.reward.canPlayVideo.next(false);
    } else if (this.threshHoldType === 'inhibit' && average < this.threshHoldValue) {
      this.mainBarChart.data.datasets[0].backgroundColor = '#00FF00';
      this.reward.canPlayVideo.next(true);
    }
  }
  drawChartData(sensorValue) {
    let inhibtion;
    let reward;
    switch (this.thresholdDifficult) {
      case '80-90%':
        inhibtion = 85;
        reward = 15;
        break;
      case '70-80%':
        inhibtion = 75;
        reward = 25;
        break;
      case '60-70%':
        inhibtion = 65;
        reward = 35;
        break;
    }
    if (this.minuteSuccess < 50) {
      switch (this.thresholdDifficult) {
        case '80-90%':
          inhibtion = 90;
          reward = 10;
          break;
        case '70-80%':
          inhibtion = 85;
          reward = 15;
          break;
        case '60-70%':
          inhibtion = 75;
          reward = 25;
          break;
      }
    }

    if (this.minuteSuccess > 90) {
      switch (this.thresholdDifficult) {
        case '80-90%':
          inhibtion = 75;
          reward = 25;
          break;
        case '70-80%':
          inhibtion = 65;
          reward = 35;
          break;
        case '60-70%':
          inhibtion = 60;
          reward = 40;
          break;
      }
    }
    if (this.mainRatioChart && this.trainingType === 'amplitude') {
      this.changeChartColor(this.frequencyOne);
    }
    if (this.mainRatioChart.data.datasets[0].data.length >= this.chartLabelsLength) {
      this.mainRatioChart.data.datasets[0].data.length = this.chartLabelsLength;
      this.mainRatioChart.data.datasets[0].data.shift();
      this.mainRatioChart.data.datasets[0].data.push(sensorValue);
      this.autoThresholdService.setMinuteSamples(+sensorValue, 600, inhibtion, reward);
    }
    if (this.mainRatioChart.data.datasets[0].data.length < this.chartLabelsLength) {
      this.mainRatioChart.data.datasets[0].data.push(sensorValue);
      this.autoThresholdService.setMinuteSamples(+sensorValue, 600, inhibtion, reward);
    }
    if (this.mainRatioChart) {
      this.mainRatioChart.update();
    }
    if (this.mainBarChart) {
      this.mainBarChart.data.datasets[0].data = [sensorValue];
      this.mainBarChart.update();
    }
  }

  changeChartUpdateInterval($event) {
    this.maxChartSeconds = +$event.target.value
    if ($event.target.value > 600) {
      this.maxChartSeconds = 600;
      this.cd.markForCheck();
    }
    this.mainRatioChart.data.labels = this.chartLabels.generateChartLabels(this.maxChartSeconds, 100);
    this.chartLabelsLength = this.mainRatioChart.data.labels.length;
    this.chartService.trainingChartStepSize = this.getChartStepSize();
    if (this.refreshChartSubscription) {
      this.refreshChartSubscription.unsubscribe();
    }
    this.nullifyChart();
  }

  disableSelectedRatio(index: number, condition: boolean) {
    condition = !condition
    this.mainRatioChart.data.datasets[index].hidden = condition;
  }

  changeChartZoomLevel($event) {
    if ($event.target.value < 0) {
      $event.target.value = 0;
      return;
    }
    this.mainRatioChart.options.scales.yAxes[0].ticks.max = Number($event.target.value);
    this.mainBarChart.options.scales.yAxes[0].ticks.max = Number($event.target.value);
    this.maxChartZoom = $event.target.value;
    this.cd.markForCheck();
  }

  changeChannel($event) {
    this.selectedChannel = $event.value;
    this.averageRatio.resetArrays();
  }

  markAsStopped() {
    this.recordData.isCompleted = false;
  }
  autoResumeTraining() {
    this.showCountDown = true;
    this.trainingCountDown = 3;
    this.recordData.sensorInfo.interruptions++;
    const countdownInterval = setInterval(() => {
      this.trainingCountDown--;
      this.cd.markForCheck();
      if (this.trainingCountDown === 0) {
        clearInterval(countdownInterval);
        this.showCountDown = false;
      }
    }, 1000);
  }
  setDatabaseWriteSettings(sessionLength) {
    this.databaseRecordSpeed = 200;
    this.databaseAverageValues = 2;
    // if (sessionLength === 10) {
    //   this.databaseRecordSpeed = 200;
    //   this.databaseAverageValues = 2;
    // } else if (sessionLength > 10 && sessionLength <= 20) {
    //   this.databaseRecordSpeed = 300;
    //   this.databaseAverageValues = 3;
    // } else {
    //   this.databaseRecordSpeed = 1000;
    //   this.databaseAverageValues = 10;
    // }
    this.recordData.refreshInterval = this.databaseRecordSpeed;
  }

  setTrainerTemplate(template) {
    ExgPacketDecoder.resetDecoder();
    this.recordData.sensorInfo.errors = 0;
    this.recordData.sensorInfo.lostPackets = 0;
    this.recordData.sensorInfo.interruptions = 0;
    let sessionLength = 0;
    template.data.forEach((part) => {
      if (part.checked) {
        sessionLength += +part.length;
      }
    });
    this.setDatabaseWriteSettings(sessionLength);
    this.sensorDataInterval();
    this.recordData.trainingTemplate = template.name;
    this.parseTemplatesList(template);
    this.showCountDown = true;
    this.trainingCountDown = 3;
    this.nullifyChart();
    clearInterval(this.datasetInterval);
    this.selectedTemplate = template;
    const countdownInterval = setInterval(() => {
      this.trainingCountDown--;
      this.cd.markForCheck();
      if (this.trainingCountDown === 0) {
        this.animateTrainingName = true;
        this.showCountDown = false;
        this.minuteSuccess = 0;
        clearInterval(countdownInterval);
        this.trainerTemplateSelected = true;
        this.hideRecordButtons.recordButtons.next(true);
        this.trainingData.startTraining.next(template.data);
        const upperValue = this.thresholdDifficult.split('-')[1].replace('%' , '');
        const lowerValue = this.thresholdDifficult.split('-')[0]
        this.autoTraining.upperDifficulty = +upperValue;
        this.autoTraining.lowerDifficulty = +lowerValue;
        this.cd.markForCheck();
        this.successRateArray = [];
        this.clearThresholdAdjust();
        this.datasetInterval = null;
        this.recordData.isCompleted = true;
        const rescaleChartTimeout = setTimeout(() => {
          this.setChartAutoScale();
          clearTimeout(rescaleChartTimeout);
          const animateText = setTimeout(() => {
            this.animateTrainingName = false;
            clearTimeout(animateText);
          },2000)
        },1000)
      }
    },1000);
  }

  parseTemplatesList(template) {
    template.data.forEach((item) => {
      if (item.checked) {
        this.recordData.templatesList.push(item);
      }
    });
  }

  setTrainingDifficult(event?) {
    const upperValue = this.thresholdDifficult.split('-')[1].replace('%' , '');
    const lowerValue = this.thresholdDifficult.split('-')[0];
    this.autoTraining.upperDifficulty = +upperValue;
    this.autoTraining.lowerDifficulty = +lowerValue;
    this.successRateArray = [];
    this.minuteSuccess = 0;
    this.cd.markForCheck();
    if (event) {
      this.mainBarChart.options.annotation.annotations[0].value = +event.target.value;
      this.mainRatioChart.options.annotation.annotations[0].value = +event.target.value;
    }
    this.setAutoThreshold();
  }

  subscribeOnChartAutoScale() {
    this.autoTraining.triggerChartAutoScale.subscribe((scale) => {
      if(scale) {
        this.setChartAutoScale();
      }
    })
  }

  setChartAutoScale() {
    if (this.mainRatioChart.data.datasets[0].data) {
        const currentSensorValue = this.averageRatioOne;
      // tslint:disable-next-line:max-line-length
        const lineMinValue = (Math.min.apply(null, this.mainRatioChart.data.datasets[0].data) - currentSensorValue < 0) ? 0 : (Math.min.apply(null, this.mainRatioChart.data.datasets[0].data) - currentSensorValue);
        const lineMaxValue = +(Math.max.apply(null, this.mainRatioChart.data.datasets[0].data) + currentSensorValue).toFixed(0);
        this.mainRatioChart.options.scales.yAxes[0].ticks.max = lineMaxValue;
        this.mainRatioChart.options.scales.yAxes[0].ticks.min = lineMinValue;
        this.mainBarChart.options.scales.yAxes[0].ticks.max = lineMaxValue;
        this.maxChartZoom = this.mainRatioChart.options.scales.yAxes[0].ticks.max;
    }
  }

  public changeTrashHoldType($event) {
    this.threshHoldType = $event.value;
    this.successRateArray = [];
    this.clearThresholdAdjust();
  }

  zoomInChart() {
    this.mainRatioChart.options.scales.yAxes[0].ticks.max = this.mainRatioChart.options.scales.yAxes[0].ticks.max + +this.zoomStep;
    this.mainBarChart.options.scales.yAxes[0].ticks.max = this.mainBarChart.options.scales.yAxes[0].ticks.max + +this.zoomStep;
    this.maxChartZoom = this.mainRatioChart.options.scales.yAxes[0].ticks.max;
  }

  zoomOutChart() {
    this.mainRatioChart.options.scales.yAxes[0].ticks.max = this.mainRatioChart.options.scales.yAxes[0].ticks.max - +this.zoomStep;
    this.mainBarChart.options.scales.yAxes[0].ticks.max = this.mainBarChart.options.scales.yAxes[0].ticks.max - +this.zoomStep;
    this.maxChartZoom = this.mainRatioChart.options.scales.yAxes[0].ticks.max;
  }

  setBarChartThreshold(event) {
    this.mainBarChart.options.annotation.annotations[0].value = event;
    this.mainRatioChart.options.annotation.annotations[0].value = event;
    this.threshHoldValue = event;
    this.cd.markForCheck();
  }

  resetTrainingAverage() {
    if (this.trainingType === 'ratio') {
      this.changeChartColor(9);
    } else if (this.trainingType === 'amplitude') {
      this.changeChartColor(this.frequencyOne);
    }
    this.mainBarChart.data.datasets[0].data = [];
    this.averageRatio.resetTrainingAverage();
  }

  getTextColor() {
    if (this.mainRatioChart) {
      return {
        color: `${ this.mainRatioChart.data.datasets[0].borderColor}`
      };
    }
  }

  nullifyChart() {
    this.timeArray = [];
    if (this.mainRatioChart) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.mainRatioChart.data.datasets.length; i++) {
        this.mainRatioChart.data.datasets[i].data = [];
      }
    }
  }

  nullifyFirebaseData() {
    this.recordData.delta.data = [];
    this.recordData.theta.data = [];
    this.recordData.loAlpha.data = [];
    this.recordData.alpha.data = [];
    this.recordData.hiAlpha.data = [];
    this.recordData.smrLoBeta.data = [];
    this.recordData.betaOne.data = [];
    this.recordData.betaTwo.data = [];
    this.recordData.hiBeta.data = [];
    this.recordData.artefakt.data = [];
    this.recordData.timeArray = [];
  }

  getRatioName(index) {
    switch (index) {
      case 0 :
        return 'Delta'
      case 1 :
        return 'Theta'
      case 2 :
        return 'Lo-alpha'
      case 3 :
        return 'Alpha'
      case 4 :
        return 'Hi-alpha'
      case 5 :
        return 'Smr, lo-beta'
      case 6 :
        return 'Beta 1'
      case 7 :
        return 'Beta 2'
      case 8 :
        return 'Hi-Beta'
      case 9 :
        return 'Artefakt'
    }
  }

  shortName(fullName: string): any {
    if (fullName.split(/\W+/).length === 1) {
      return fullName[0];
    } else {
      return fullName
        .split(' ')
        .map(n => n[0])
        .join('.');
    }
  }

  placeholderTranslate() {
    return  this.translate.instant('CHART_OPTIONS.CHOOSE_ONE')
  }

  detectDevice() {
    this.isTablet = this.detector.isTablet();
  }

  getTemplateSelectName(template) {
    if (template.name.includes('${de}') || template.name.includes('${en}')) {
      return this.templateTranslate.getTemplateTranslation(this.translates.getSelectedLanguage(), template, 'name');
    } else {
      return template.name;
    }
  }

  randomValue(length) {
    const randomArray = [];

    for (let i = 0; i < length; i++) {
      const randomValue = Math.random();
      randomArray.push(randomValue);
    }

    return randomArray;
  }

  fakeData() {
    this.recordData.refreshInterval = 200;
    this.recordData.trainingTemplate = '2min 0.2'
    this.recordData.seconds = 1200;
    this.recordData.delta.data=this.randomValue(6000)
    this.recordData.theta.data=this.randomValue(6000)
    this.recordData.loAlpha.data=this.randomValue(6000)
    this.recordData.alpha.data=this.randomValue(6000)
    this.recordData.hiAlpha.data=this.randomValue(6000)
    this.recordData.smrLoBeta.data=this.randomValue(6000)
    this.recordData.betaOne.data=this.randomValue(6000)
    this.recordData.betaTwo.data=this.randomValue(6000)
    this.recordData.hiBeta.data=this.randomValue(6000)
    this.recordData.artefakt.data=this.randomValue(6000)
    this.recordData.templatesList = [
      {
        checked: true,
        length: '10',
        trainingProtocol: 'ratio',
        frequencyOne: 4,
        frequencyTwo: 1,
        threshHoldType: 'reward',
        thresholdDifficult: '70-80%',
        youtubePlaylistUrl: '',
        note: '',
        customText: '',
        showLineChart: true,
        showBarChart: true,
        showVideo: true,
        paybackSpeed: 1,
        feedBackType: 'startStop'
      },
      {
        checked: true,
        length: '10',
        trainingProtocol: 'ratio',
        frequencyOne: 3,
        frequencyTwo: 6,
        threshHoldType: 'inhibit',
        thresholdDifficult: '80-90%',
        youtubePlaylistUrl: '',
        note: '',
        customText: '',
        showLineChart: false,
        showBarChart: false,
        showVideo: true,
        paybackSpeed: 1,
        feedBackType: 'startStop'
      },
    ]
    // this.recordRating.getFirstPartValues(this.recordData).subscribe((record) => {
    //   this.firebase.saveProcedure('3514610', record, 'EEG')
    // })
    this.handleUserMonthLimit();
  }
  handleUserMonthLimit() {
    this.monthSessions.getUserMonthSessions(this.currentUser.uid).pipe(take(1)).subscribe((user: IUser) => {
      const training = {
        seconds: this.recordData.seconds,
        date: Date.now()
      }
      if (!user.monthSessions) {
        user.monthSessions = {
          sessionsSecondsPerDay: this.recordData.seconds,
          catStartSession: true,
          sessionsList: [],
          nextSessionAvailableInHours: null,
          monthTimeLeft: 0,
          dayLimitReached: false
        }
      }
      const userLastDayTrainings = user.monthSessions.sessionsList.filter((session) => {
        return this.monthSessions.calculateHoursBetweenDates(new Date(session.date), new Date()) < 24;
      });
      user.monthSessions.sessionsList = this.monthSessions.filterMonthSessions(user.monthSessions.sessionsList)
      user.monthSessions.sessionsSecondsPerDay = this.monthSessions.calculateUserSessionsTime(userLastDayTrainings);
     if (user.monthSessions.sessionsSecondsPerDay < this.monthSessions.maxSecondsPerDay) {
       user.monthSessions.sessionsList.push(training);
       user.monthSessions.dayLimitReached = false;
     } else {
       user.monthSessions.dayLimitReached = true;
     }
      user.monthSessions.catStartSession = user.monthSessions.sessionsSecondsPerDay < this.monthSessions.maxSecondsPerDay;
      if (user.monthSessions.catStartSession) {
        user.monthSessions.sessionsSecondsPerDay = this.monthSessions.calculateUserSessionsTime(user.monthSessions.sessionsList);
        user.monthSessions.catStartSession = true;
        user.monthSessions.nextSessionAvailableInHours = null;
      } else {
        const lastUserSession = user.monthSessions.sessionsList.slice(-1).pop();
        // tslint:disable-next-line:max-line-length
        user.monthSessions.nextSessionAvailableInHours = 24 - this.monthSessions.calculateHoursBetweenDates(new Date(lastUserSession.date), new Date());
      }
      if (this.monthSessions.calculateUserSessionsTime(user.monthSessions.sessionsList) > this.monthSessions.maxSecondsPerMonth) {
        user.monthSessions.catStartSession = false;
        user.monthSessions.nextSessionAvailableInHours = null;
      }
      user.monthSessions.monthTimeLeft = this.monthSessions.calculateMonthTimeLeft(user.monthSessions.sessionsList);
      this.monthSessions.setUserMonthSessions(user);
    });
  }
  sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  isIosTablet() {
    return this.detector.isTablet(this.detector.userAgent);
  }
}
