import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver, Injectable,
  Injector, Input, OnChanges,
  OnDestroy,
  OnInit, SimpleChanges,
  ViewChild
} from '@angular/core';
import {PlaylistService} from '../../../services/playlist/playlist.service';
import {RewardService} from '../../../services/reward/reward.service';
import {MuscleDataService} from '../../../services/muscle-data.service';
import {CdkPortal, DomPortalHost} from '@angular/cdk/portal';
import {Subject, Subscription} from 'rxjs';
import {FirebaseService} from '../../../services/firebase/firebase.service';
import {TrainingScreenComponent} from '../training-screen/training-screen.component';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../core/reducers';
import {currentUser} from '../../../core/auth';
import {auditTime, distinctUntilChanged, take, takeUntil, throttleTime} from 'rxjs/operators';
import {TrainingTemplateDataService} from '../../../services/training-template-data/training-template-data.service';
import {TrainingSizesService} from '../../../services/training-page-sizes/training-sizes.service';
import {EmailDateService} from '../../../services/email-date/email-date.service';
import {VideoService} from '../../../services/video-service/video.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {VideoTimeService} from '../../../services/video-time/video-time.service';
import * as moment from 'moment';
import {
  AddYoutubePlaylistComponent
} from '../training-configuration/add-youtube-playlist/add-youtube-playlist.component';
import {MatDialog} from '@angular/material/dialog';
import {ChangeVideoPauseService} from "../../../services/change-video-pause/change-video-pause.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {TranslationService} from "../../../core/_base/layout";

@Component({
  selector: 'kt-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubeVideoComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild(CdkPortal) portal: CdkPortal;
  @Input() sessionTime;
  private externalWindow = null;
  public videoId = '1SLr62VBBjw';
  public player;
  options = {
    cc_lang_pref: 'en',
    controls: 0,
    autoplay: 0,
    rel: 0,
  };
  playlistUrl = '';
  videosArray = [];
  hideVideo = false;
  videoMuted = false;
  videoVolume = 50;
  artefactTresHold = '6';
  artefaktValue: number;
  showGreenColor = false;
  showYellowColor = false;
  showRedColor = false;
  carouselHeight = 150;
  showPortal = false;
  host;
  playVideoSubscription: Subscription;
  currentVideoId;
  trainerUser;
  deleteTrainerSubscription: Subscription;
  lighterColorInterval;
  currentUserId = '';
  showOptions = false;
  showArtefaktSignal = true;
  showVideo = true;
  currentUser;

  germanDeleteSupervisor;
  englishDeleteSupervisor;
  public videoType = 'youtube';
  videoPlayer = null;
  public videoTimeBeforeStop: number;
  public youtubeVideoTimeBeforeStop: number;
  previousTemplate = null;
  currentTemplateSubscription: Subscription;
  customVideoVolume = 50;
  muteCustomVideo = false;
  userVideoTimings = [];

  showSeekHint = false;
  youtubeSeekValue: number;
  youtubeSeekVideoIndex: number;
  customVideoSeekValue: number;
  clearYoutubeVideoSubscription: Subscription;
  hintSeconds: number;

  dbVideoTimingUpdated = false;
  videoTimeBeforeHide: number;
  customVideoTimeBeforeHide: number;
  customVideoLastUrl;
  trainingTemplate = null;
  videoIndex = 0;
  currentYoutubeTime = null;
  currentCustomVideoTime = null;
  maxYoutubeTime = null;
  maxCustomVideoTime = null;
  paybackRates = [0.5, 1, 1.5, 2];
  videoChangedByUser = new Subject();
  youtubeVideoSubscription: Subscription;
  customVideoSubscription: Subscription;
  trainingTime = 0;
  paybackRate = 1;
  feedBackType = 'startStop';
  isTabletIos = false;

  constructor(
    private playlist: PlaylistService,
    private reward: RewardService,
    private cd: ChangeDetectorRef,
    private muscleData: MuscleDataService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private firebase: FirebaseService,
    private trainingScreen: TrainingScreenComponent,
    private store: Store<AppState>,
    private trainingData: TrainingTemplateDataService,
    private chartSizes: TrainingSizesService,
    private emailDate: EmailDateService,
    private video: VideoService,
    private afstorage: AngularFireStorage,
    private videoTime: VideoTimeService,
    private dialog: MatDialog,
    private pauseByChange : ChangeVideoPauseService,
    private detector: DeviceDetectorService,
    private translates: TranslationService,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.trainingTime = changes.sessionTime.currentValue;
  }
  ngOnInit(): void {
    this.subscribeOnTrainerUser();
    this.subscribeOnHideOptions();
    this.getUserId();
    this.subscribeOnYoutubeUrl();
    this.subscribeOnCustomVideoUrl();
    this.subscribeOnChartSizes();
    this.handleYoutubeStop();
  }

  ngAfterViewInit(): void {
    this.initPlayer();
    this.initPlaylist();
    this.subscribeToArtefaktSignal();
    this.getScreenWidth();
    this.playVideoCondition();
    setTimeout(() => {
      this.subscribeOnScreenOptions();
    },2000);
    this.getEmailTemplates();
    this.subscribeOnCurrentTemplate();
  }

  handleYoutubeStop() {
    this.clearYoutubeVideoSubscription = this.videoTime.clearYoutubeSeekTime.subscribe((value) => {
      if (value) {
        this.clearPlayerTime();
      }
    });
  }

  getUserId() {
    this.store.pipe(select(currentUser)).subscribe(user => {
      if(user) {
        this.currentUser = user;
        this.currentUserId = user.id;
        this.getUserVideoTiming();
      }
    });
  }

  getUserVideoTiming() {
    this.firebase.getVideoTimingData(this.currentUserId).subscribe((timings:any) => {
      this.dbVideoTimingUpdated = true;
      this.userVideoTimings = [];
      timings.forEach((el) => {
        if (el.name.includes('/')) {
          el.name = el.name.split('/')[0];
        }
        el.data.forEach((timing) => {
          this.userVideoTimings.push(timing);
        })
      });
    });
  }

  getEmailTemplates() {
    this.firebase.getEmailSupervisorUnassignedDE().pipe(take(1)).subscribe((template: any) => {
      this.germanDeleteSupervisor = template;
    });
    this.firebase.getEmailSupervisorUnassigned().pipe(take(1)).subscribe((template: any) => {
      this.englishDeleteSupervisor = template;
    });
  }

  subscribeOnCurrentTemplate() {
    this.currentTemplateSubscription = this.trainingData.currentTemplate.pipe(distinctUntilChanged()).subscribe((template:any) => {
      if (template && (template.youtubePlaylistUrl || template.customVideoUrl) && this.dbVideoTimingUpdated) {
        this.trainingTemplate = template;
        const foundedTiming = this.userVideoTimings.find(element => element.youtubePlaylistUrl === template.youtubePlaylistUrl);
        const foundedTimingCustomVideo = this.userVideoTimings.find(element => element.customVideoUrl === template.customVideoUrl);
        if (foundedTiming && this.videoType === 'youtube' && foundedTiming.youtubePlaylistUrl) {
          this.youtubeSeekValue = foundedTiming.videoConfig.time;
          if (foundedTiming.videoConfig && foundedTiming.videoConfig.videoIndex !== 0) {
            this.youtubeSeekVideoIndex = foundedTiming.videoConfig.videoIndex;
          }
          this.showSeekHint = true;
          this.dbVideoTimingUpdated = false;
          this.hintSeconds = foundedTiming.videoConfig.time;
        }
        if (foundedTimingCustomVideo && this.videoType === 'custom' && foundedTimingCustomVideo.customVideoUrl) {
          this.customVideoSeekValue = foundedTimingCustomVideo.videoConfig.time;
          this.showSeekHint = true;
          this.dbVideoTimingUpdated = false;
          this.hintSeconds = foundedTimingCustomVideo.videoConfig.time;
        }
        this.triggerVideoHide(this.trainingTemplate.showVideo);
        this.changeFeedbackType(this.trainingTemplate.feedBackType);
        if (template) {
          if (!template.youtubePlaylistUrl && !template.customVideoUrl) {
            this.trainingData.youtubePlaylistSubject.next(null);
            this.videoType = 'youtube';
            this.videoId = this.translates.getSelectedLanguage() === 'en' ? 'hTfue5D3gNY' : 'czylygDwK0U';
            this.setVideo(this.videoId);
          }
        }
      } else if (template) {
        this.trainingTemplate  = template;
      }
    });
  }

  transformSeconds(seconds) {
    return moment.utc(seconds * 1000).format('HH:mm:ss');
  }

  clearPlayerTime() {
    if (this.player && this.videoType === 'youtube') {
      this.videoIndex = 0;
      this.player.seekTo(0);
      this.videoPlayer = null;
    } else if (this.videoPlayer && this.videoType === 'custom') {
      this.video.setVideoTime(this.videoPlayer, 0);
      this.player = null;
    }
    this.showSeekHint = false;
  }

  setVideoTime() {
    if (this.youtubeSeekValue > 1 && this.videoType === 'youtube') {
      if (this.youtubeSeekVideoIndex) {
        this.videoIndex = this.youtubeSeekVideoIndex
        this.videoId = this.videosArray[this.youtubeSeekVideoIndex].id;
        this.setVideo(this.videoId);
      }
      this.player.seekTo(this.youtubeSeekValue);
      this.videoPlayer = null;
      this.showSeekHint = false;
    } else if (this.customVideoSeekValue > 1 && this.videoType === 'custom') {
      this.video.setVideoTime(this.videoPlayer, this.customVideoSeekValue);
      this.player = null;
      this.showSeekHint = false;
    }
  }

  closeYoutubeHint() {
    this.showSeekHint = false;
  }

  subscribeOnChartSizes() {
    this.chartSizes.chartSizes.subscribe((data) => {
      const youtubeContainer = document.getElementsByClassName('training-right');
      if (data) {
        // @ts-ignore
        youtubeContainer[0].style.width = '100%';
      } else {
        // @ts-ignore
        youtubeContainer[0].style.width = '45%';
        // @ts-ignore
      }
    });
  }

  subscribeOnCustomVideoUrl() {
    this.customVideoSubscription = this.trainingData.customVideoSubject.pipe(takeUntil(this.videoChangedByUser)).pipe(distinctUntilChanged()).subscribe((url) => {
      if (url) {
        this.videoType = 'custom';
        const ref = this.afstorage.ref(url);
        const downloadUrl = ref.getDownloadURL();
        downloadUrl.subscribe((videoUrl) => {
          const timeout = setTimeout(() => {
            if (this.customVideoLastUrl !== videoUrl) {
              this.createVideoPlayer(videoUrl);
            }
            this.customVideoLastUrl = videoUrl;
            clearTimeout(timeout);
          },1500)
        })
      }
    });
  }

  subscribeOnYoutubeUrl() {
    this.youtubeVideoSubscription = this.trainingData.youtubePlaylistSubject.pipe(takeUntil(this.videoChangedByUser)).subscribe((url) => {
      if (url) {
        if (url.includes('PL')) {
          this.videoType = 'youtube';
          this.playlistUrl = url;
          this.initPlayer();
          this.initPlaylist();
          this.cd.markForCheck();
        } else {
          this.videoId = url;
          this.setVideo(url);
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.playVideoSubscription) {
      this.playVideoSubscription.unsubscribe();
    }
    if (this.lighterColorInterval) {
      clearInterval(this.lighterColorInterval);
    }
    this.playlistUrl = '';
    if (this.currentTemplateSubscription) {
      this.currentTemplateSubscription.unsubscribe();
    }
    if (this.clearYoutubeVideoSubscription) {
      this.clearYoutubeVideoSubscription.unsubscribe();
    }
    if (this.youtubeVideoSubscription) {
      this.youtubeVideoSubscription.unsubscribe();
    }
    if (this.customVideoSubscription) {
      this.customVideoSubscription.unsubscribe();
    }
    this.trainingTemplate = null;
  }

  subscribeOnScreenOptions() {
    this.firebase.getUserScreenOptionsVideo(this.currentUser.id).subscribe((options: any) => {
      if (options) {
        this.showVideo = options.showYoutubeVideo;
        this.showArtefaktSignal = options.showArtefakt;
        this.cd.markForCheck();
      }
    });
  }

  openPortal() {
    this.reward.changeChartWidth.next(true);
    this.showPortal = !this.showPortal;
    this.externalWindow = window.open('', '', `width=${window.innerWidth},height=${window.innerHeight - 190},left=200,top=0`);
    this.externalWindow.addEventListener('beforeunload', (ev) => {
      if (ev) {
        this.closePortal();
      }
    })
    const host = new DomPortalHost(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );
    const cardBody = document.getElementsByTagName('body');
    cardBody[0].style.backgroundColor = '#fff';
    document.querySelectorAll('link, style').forEach(htmlElement => {
      this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });
    this.host = host;
    this.host.attach(this.portal);
    this.cd.markForCheck();
    setTimeout(() => {
      if (this.currentVideoId) {
        this.setVideo(this.currentVideoId);
      } else {
        this.setVideo(this.videoId);
      }
    },1000)
  }


  closePortal() {
    this.showPortal = false;
    this.host.detach();
    this.cd.markForCheck();
    this.externalWindow.close();
    this.reward.changeChartWidth.next(false);
  }

  subscribeToArtefaktSignal() {
    this.muscleData.artefaktSubject.subscribe((value) => {
      this.artefaktValue = value;
    });
    this.lighterColorInterval = setInterval(() => {
      this.handleLighterColor();
    },500);
  }

  subscribeOnHideOptions() {
    this.reward.showOptions.subscribe((condition) => {
      this.showOptions = condition;
      this.cd.markForCheck();
      setTimeout(() => {
        const youtube = document.getElementById('youtube');
        // youtube.style.height = '60vh';
      },0)
    });
  }

  getScreenWidth() {
    if (window.innerWidth <= 1366) {
      this.carouselHeight = 50;
      this.cd.markForCheck();
    }
  }

  handleLighterColor() {
    if (this.artefaktValue) {
      if (this.artefactTresHold === '2') {
        if (this.artefaktValue < 1.8) {
          this.showGreenColor = true;
          this.showYellowColor = false;
          this.showRedColor = false;
          this.reward.lighterColor.next('#198200');
          this.muscleData.tooHightArtefact.next(false);
        } else if (this.artefaktValue > 2) {
          this.showGreenColor = false;
          this.showYellowColor = false;
          this.reward.lighterColor.next('#FF0000');
          this.muscleData.tooHightArtefact.next(true);
          this.showRedColor = true;
        } else if (this.checkBetween(this.artefaktValue, 1.8, 2)) {
          this.showYellowColor = true;
          this.showGreenColor = false;
          this.showRedColor = false;
          this.reward.lighterColor.next('#FFFF00');
          this.muscleData.tooHightArtefact.next(false);
        }
        this.cd.markForCheck();
        return;
      }
      if (this.artefactTresHold === '4') {
        if (this.artefaktValue < 3) {
          this.showGreenColor = true;
          this.showYellowColor = false;
          this.showRedColor = false;
          this.reward.lighterColor.next('#198200');
          this.muscleData.tooHightArtefact.next(false);
        } else if (this.artefaktValue > 4) {
          this.showGreenColor = false;
          this.showYellowColor = false;
          this.reward.lighterColor.next('#FF0000');
          this.muscleData.tooHightArtefact.next(true);
          this.showRedColor = true;
        } else if (this.checkBetween(this.artefaktValue, 3, 4)) {
          this.showYellowColor = true;
          this.showGreenColor = false;
          this.showRedColor = false;
          this.reward.lighterColor.next('#FFFF00');
          this.muscleData.tooHightArtefact.next(false);
        }
        this.cd.markForCheck();
        return;
      }
      if (this.artefactTresHold === '6') {
        if (this.artefaktValue < 4) {
          this.showGreenColor = true;
          this.showYellowColor = false;
          this.showRedColor = false;
          this.reward.lighterColor.next('#198200');
          this.muscleData.tooHightArtefact.next(false);
        } else if (this.artefaktValue > 6) {
          this.showGreenColor = false;
          this.showYellowColor = false;
          this.reward.lighterColor.next('#FF0000');
          this.muscleData.tooHightArtefact.next(true);
          this.showRedColor = true;
        } else if (this.checkBetween(this.artefaktValue, 4, 6)) {
          this.showYellowColor = true;
          this.showGreenColor = false;
          this.showRedColor = false;
          this.reward.lighterColor.next('#FFFF00');
          this.muscleData.tooHightArtefact.next(false);
        }
        this.cd.markForCheck();
        return;
      }
    }
  }

  checkBetween(value, min, max) {
    return value >= min && value <= max;
  }

  setArtefakt(event) {
    this.artefactTresHold = event.value;
  }

  initPlayer() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  initPlaylist() {
    if (this.playlistUrl) {
      this.playlist.getPlayList(this.playlistUrl).subscribe((playlist: any) => {
        this.videosArray = [];
        playlist.items.forEach((item) => {
          const videoObject = {
            id: item.snippet.resourceId.videoId,
            name: item.snippet.title,
            imageUrl: item.snippet.thumbnails.medium?.url
          }
          this.videosArray.push(videoObject);
          this.videoId = playlist.items[this.videoIndex].snippet.resourceId.videoId;
        });
      });
    }
  }


  savePlayer($event) {
    if($event) {
      this.player = $event.target;
      if (this.isIosTablet()) {
        this.player.mute();
        this.videoMuted = true;
      }
      this.player.pauseVideo();
      this.setVideoVolume(this.videoVolume);
      this.player.seekTo(this.videoTimeBeforeHide);
      this.setPlayerPlaybackRate(this.trainingTemplate && this.trainingTemplate.paybackSpeed ? this.trainingTemplate.paybackSpeed : 1);
      this.cd.markForCheck();
      this.player.addEventListener('onStateChange', (event) => {
        if (event.data === 0) {
          this.videoIndex++
          if (this.videoIndex >= this.videosArray.length) {
            this.videoIndex = 0
          }
          if (this.videosArray.length) {
            this.videoId = this.videosArray[this.videoIndex].id;
            this.setVideo(this.videoId);
          } else {
            this.seekYoutubeVideo(0);
          }
        }
      });
    }
  }

  setVideo(videoLink: string, index?) {
    this.currentVideoId = videoLink;
    if (this.player && videoLink) {
      this.player.setPlaybackQuality('hd720');
      this.player.loadVideoById(`${videoLink}`);
      this.player.stopVideo();
    }
    if (index) {
      this.videoIndex = index;
    }
  }

  createVideoPlayer(url) {
    const isIosTablet = this.isIosTablet();
    this.videoPlayer = document.getElementById('video-player');
    if (isIosTablet) {
      this.muteCustomVideo = true;
    }
    this.video.setVideo(this.videoPlayer, url, isIosTablet);
    this.video.setVideoVolume(this.videoPlayer, this.customVideoVolume);
    this.setPlayerPlaybackRate(this.trainingTemplate && this.trainingTemplate.paybackSpeed ? this.trainingTemplate.paybackSpeed : 1);
    this.videoPlayer.addEventListener('loadedmetadata', () => {
      this.maxCustomVideoTime = this.videoPlayer.duration;
    });
  }

  playVideoCondition() {
    this.playVideoSubscription = this.reward.canPlayVideo.subscribe((canPlay) => {
      if (!this.trainingTemplate) {
        return;
      }
      if (this.videoType === 'youtube') {
        this.handleYoutubeVideoTime();
        if(canPlay && this.showVideo && this.artefaktValue && this.player) {
          this.videoTime.setVideoConfig(this.player.getCurrentTime(), this.videoType, this.videoIndex);
          if (this.player.g) {
            this.player.playVideo();
          }
          this.setPlayerPlaybackRate(this.paybackRate);
          const timeout = setTimeout(() => {
            this.hideVideo = false;
            if (this.player) {
              this.youtubeVideoTimeBeforeStop = this.player.getCurrentTime();
            }
            clearTimeout(timeout);
          },300);
        } else if (!canPlay && this.player && this.artefaktValue > 0 && this.showVideo) {
          this.hideVideo = true;
          const timeout = setTimeout(() => {
            if (this.feedBackType === 'startStop') {
              if (this.player.g) {
                this.player.pauseVideo();
              }
            } else {
              if (this.artefaktValue > 6 || this.muscleData.currentImpedance > 20) {
                this.player.pauseVideo();
              } else {
                this.setPlayerPlaybackRate(0.5);
              }
            }
            clearTimeout(timeout);
          },300);
        }
      }
      if (this.videoType === 'custom') {
        if (canPlay && this.videoPlayer) {
          this.video.playVideo(this.videoPlayer);
          this.setPlayerPlaybackRate(this.paybackRate);
          this.currentCustomVideoTime = this.videoPlayer.currentTime;
          this.videoTime.setVideoConfig(this.videoPlayer.currentTime, this.videoType);
          if (this.videoPlayer && this.videoPlayer.currentTime) {
            this.videoTimeBeforeStop = this.videoPlayer.currentTime;
          }
        } else if (!canPlay && this.artefaktValue > 0) {
          if (this.feedBackType === 'startStop') {
            this.video.pauseVideo(this.videoPlayer);
          } else {
            if (this.artefaktValue > 6 || this.muscleData.currentImpedance > 20) {
              this.video.pauseVideo(this.videoPlayer);
            } else {
              this.setPlayerPlaybackRate(0.5);
            }
          }
        }
      }
    });
  }
  handleYoutubeVideoTime() {
    if (this.videoId && this.player) {
      this.currentYoutubeTime = this.player.getCurrentTime();
      this.maxYoutubeTime = this.player.getDuration();
      this.cd.markForCheck();
    }
  }

  youtubeVideoTiming(value) {
    if(value) {
      return moment.utc(value * 1000).format('HH:mm:ss');
    }
  }
  setPlayerPlaybackRate(rate, byUserClick?) {
    if (byUserClick) {
      this.paybackRate = rate;
    }
    if (this.videoType === 'youtube' && this.player.g) {
      this.player.setPlaybackRate(rate);
    } else {
      this.video.setVideoPaybackRate(this.videoPlayer ,rate)
    }
  }

  changeFeedbackType(type) {
    this.paybackRate = 1;
    this.feedBackType = type;
  }

  seekYoutubeVideo(value) {
    this.player.seekTo(value);
  }

  seekCustomVideo(value) {
    this.video.setVideoTime(this.videoPlayer, value)
  }

  setVideoVolume(event) {
    this.videoVolume = event;
    this.player.setVolume(event);
  }

  setCustomVideoVolume(event) {
    this.customVideoVolume = event;
    this.video.setVideoVolume(this.videoPlayer, this.customVideoVolume);
  }

  nextYoutubeVideo() {
    this.videoIndex++
    if (this.videoIndex >= this.videosArray.length) {
      this.videoIndex = 0
    }
    this.videoId = this.videosArray[this.videoIndex].id;
    this.setVideo(this.videoId);
  }

  previousYoutubeVideo() {
    this.videoIndex--;
    if (this.videoIndex < this.videosArray.length) {
      this.videoIndex = 0
    }
    this.videoId = this.videosArray[this.videoIndex].id;
    this.setVideo(this.videoId);
  }

  setVideoVolumeWindow(event) {
    this.videoVolume = +event.target.value;
    this.player.setVolume(+event.target.value);
  }

  muteVideo() {
    if (this.videoType === 'youtube') {
      this.videoMuted = !this.videoMuted;
      if (this.videoMuted) {
        this.player.mute();
      } else if (!this.videoMuted) {
        this.player.unMute();
        this.setVideoVolume(this.videoVolume);
      }
    } else {
      this.muteCustomVideo = !this.muteCustomVideo;
      this.video.muteVideo(this.videoPlayer, this.muteCustomVideo);
    }
  }

  subscribeOnTrainerUser() {
    this.reward.trainer.subscribe((trainer:any) => {
      this.trainerUser = trainer;
      this.cd.markForCheck();
      if (trainer) {
        this.getTrainerPlaylist(trainer.id)
      }
    })
  }

  getTrainerPlaylist(trainerId) {
    this.firebase.getTrainerOptions(trainerId, 'Training').pipe(take(1)).subscribe((options:any) => {
      options = options[0];
      if (options.youtubePlaylist) {
        this.videoType = 'youtube';
        this.playlistUrl = options.youtubePlaylist;
        this.initPlayer();
        this.initPlaylist();
        this.cd.markForCheck();
        if (this.videosArray.length) {
          setTimeout(() => {
            this.setVideo(this.videosArray[0].id);
            this.videoId = this.videosArray[0].id;
            this.cd.markForCheck();
          }, 2000)
        }
      }
      if (options.customVideoUrl) {
        this.videoType = 'custom';
        const ref = this.afstorage.ref(options.customVideoUrl);
        const downloadUrl = ref.getDownloadURL();
        downloadUrl.subscribe((videoUrl) => {
          const timeout = setTimeout(() => {
            if (this.customVideoLastUrl !== videoUrl) {
              this.createVideoPlayer(videoUrl);
            }
            this.customVideoLastUrl = videoUrl;
            clearTimeout(timeout);
          },1500)
        });
      }
    });
  }

  deleteTrainer() {
    this.deleteTrainerSubscription = this.firebase.getUidByUserId(this.trainerUser.id).pipe(take(1)).subscribe((trainer:any) => {
      if (trainer.length) {
        this.trainingScreen.showOptions = true;
        this.trainingScreen.trainerId = null;
        const updatedUser = Object.assign({}, this.currentUser);
        updatedUser.trainerId = '';
        this.firebase.updateUser(this.currentUser.uid, updatedUser);
        this.firebase.deleteTrainer(trainer[0].id, 'Training', this.currentUserId);
        this.deleteTrainerSubscription.unsubscribe();
        this.reward.trainer.next(null);
        if (this.trainingScreen.getUserSubscription) {
          this.trainingScreen.getUserSubscription.unsubscribe();
        }
      }
    });
  }

  transformDate(date) {
    if (date) {
      const time = new Date(date);
      const trainingDay = time.toLocaleDateString();
      const hour = time.getHours();
      const minutes = time.getMinutes();
      if (minutes < 10) {
        return trainingDay + ' ' + '-' + ' ' + hour + ':' + `0${minutes}`;
      }
      return trainingDay + ' ' + '-' + ' ' + hour + ':' + minutes;
    }
  }

  triggerArtefakt() {
    this.showArtefaktSignal = !this.showArtefaktSignal;
    this.cd.markForCheck();
  }

  triggerVideoHide(conditionByTemplate?) {
    if (conditionByTemplate !== undefined) {
      this.showVideo = conditionByTemplate;
    } else {
      this.showVideo = !this.showVideo;
    }
    if (this.showVideo && this.videoType === 'youtube') {
      this.reward.youtubeVideoShow.next(true);
      if (!this.player) {
        this.initPlayer();
        this.initPlaylist();
      }
      this.cd.markForCheck();
    } else if (this.showVideo && this.videoType === 'custom') {
      const timeout = setTimeout(() => {
        this.createVideoPlayer(this.customVideoLastUrl);
        this.video.setVideoTime(this.videoPlayer, this.customVideoTimeBeforeHide);
        clearTimeout(timeout);
      },1500);
    }
    else {
      if (this.videoType === 'youtube') {
        this.videoTimeBeforeHide = this.player.getCurrentTime();
        this.reward.youtubeVideoShow.next(false);
      }
      if (this.videoType === 'custom') {
        this.customVideoTimeBeforeHide = this.videoPlayer.currentTime;
        this.videoPlayer = null;
      }
    }
    this.cd.markForCheck();
  }

  limitOfCharacters(nameLength: number): number {
    if (nameLength > 20) {
      return 15;
    }
    return 120;
  }
  changeVideoDialog() {
    this.pauseByChange.pauseSubject.next(true);
    this.dialog.open(AddYoutubePlaylistComponent, {
      disableClose: true,
      panelClass: 'dialog-scroll',
      data: this.options
    }).afterClosed().subscribe((data) => {
      if (!data) {
        this.pauseByChange.pauseSubject.next(false);
        return;
      }
      this.videoChangedByUser.next('');
      this.videoChangedByUser.complete();
      this.dbVideoTimingUpdated = true;
      if (data.video && data.video.downloadUrl) {
        this.videoType = 'custom';
        const ref = this.afstorage.ref(data.video.downloadUrl);
        const downloadUrl = ref.getDownloadURL();
        downloadUrl.subscribe((videoUrl) => {
          const timeout = setTimeout(() => {
            if (this.customVideoLastUrl !== videoUrl) {
              this.createVideoPlayer(videoUrl);
            }
            this.customVideoLastUrl = videoUrl;
            this.trainingData.changedCustomVideoSubject.next(videoUrl);
            clearTimeout(timeout);
          },1500)
        });
        this.pauseByChange.pauseSubject.next(false);
        this.cd.markForCheck();
      } else {
        this.videoType = 'youtube';
        if (data.includes('&ab')) {
          data = data.split('&ab')[0];
        }
        if (data.includes('PL')) {
          this.playlistUrl = data;
          this.initPlaylist();
          this.trainingData.customYouTubeVideoSubject.next(data);
        } else {
          this.videoId = data;
          this.setVideo(data);
          this.trainingData.customYouTubeVideoSubject.next(data);
        }
        this.pauseByChange.pauseSubject.next(false);
        this.cd.markForCheck();
      }
    });
  }

  isIosTablet() {
    return this.detector.isTablet(this.detector.userAgent);
  }
  isEmptyVideo() {
    return !!(this.videoId === 'hTfue5D3gNY' || this.videoId === 'czylygDwK0U');
  }
}
