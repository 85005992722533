import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, OnInit} from '@angular/core';
import {MuscleDataService} from '../../services/muscle-data.service';
import {
  MUSCLE_CHARACTERISTIC_ID,
  MUSCLE_CMD_READ,
  MUSCLE_CMD_SERVICE,
  MUSCLE_CMD_WRITE,
  MUSCLE_EXG_SERVICE,
  MUSCLE_SERVICE_ID
} from '../../core/consts/consts';
import {TimerService} from '../../services/timer/timer.service';
import {MatDialog} from '@angular/material/dialog';
import {InfoDialogComponent} from '../info-dialog/info-dialog/info-dialog.component';
import {SimulatorService} from '../../services/simulator/simulator.service';
import {NavigationStart, Router} from '@angular/router';
import {OtherChartsService} from '../../services/other-charts/other-charts.service';
import {RewardService} from '../../services/reward/reward.service';
import {IUser} from '../../core/interfaces/user';
import {CurrentUserService} from '../../services/current-user/current-user.service';
import {CoachmarkService} from '../../services/coachmark/coachmark.service';
import {CurrentRouteService} from '../../services/current-route/current-route.service';
import {interval, Subject, Subscription} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {SensorStateService} from '../../services/sensor-state/sensor-state.service';
import {TranslationService} from '../../core/_base/layout';
import {ExgPacketDecoder} from '../../core/interfaces/exg_interface';
import {TranslateService} from '@ngx-translate/core';
import {FirebaseService} from "../../services/firebase/firebase.service";
import {ToastrMessageService} from "../../services/toastr-message/toastr-message.service";
import {BindSensorComponent} from "./bind-sensor/bind-sensor.component";


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'kt-genius-settings',
  templateUrl: './genius-settings.component.html',
  styleUrls: ['./genius-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeniusSettingsComponent implements OnInit {
  constructor(
    private muscleData: MuscleDataService,
    private timer: TimerService,
    public dialog: MatDialog,
    public simulatorService: SimulatorService,
    private router: Router,
    private otherCharts: OtherChartsService,
    private reward: RewardService,
    private firebaseUser: CurrentUserService,
    private coachMarkService: CoachmarkService,
    private currentRoute: CurrentRouteService,
    private cd: ChangeDetectorRef,
    private sensorState: SensorStateService,
    private translations: TranslationService,
    private translate: TranslateService,
    private firebase: FirebaseService,
    private toast: ToastrMessageService,
  ) {}

  percentage: number;
  isSensorConnected = false;
  device;
  service;
  isLoading = false;
  checkBatteryLevelInterval;
  checkBatteryIntervalStarted = false;
  selectedMode;
  timerView = '00:00';
  sensorConnectedTime: Date;
  connectionInterval;
  showTimer = false;
  isSimulatorMode = false;
  simulatorInterval;
  simulatorIntervalStared = false;
  simulatorArray = [[],[],[],[],[],[],[],[],[],[]];
  currentPage = '';
  firmwareVersion = '';
  isImpedanceData = false;
  impedanceFirstChannelKOhms;
  impedanceFirstPhase;
  impedanceSecondChannelKOhms;
  impedanceSecondPhase;
  currentUser: IUser;
  stopSimulator: Subject<any> = new Subject();
  previousErrorCount = 0;
  previousPacketDrop = 0;
  sensorErrorsTimeout;
  disconnectEventAdded = false;
  deviceList = [];

  options = {
    filters: [
      {services: [MUSCLE_EXG_SERVICE]},
      {services: [MUSCLE_CMD_SERVICE]},
      {services: [MUSCLE_CMD_READ]},
      {services: [MUSCLE_CMD_WRITE]},
    ],
  }

  page = '';


  ngOnInit(): void {
    this.checkBatteryLevel();
    this.getDevice();
    this.subscribeOnRouterEvent();
    this.simulatorService.isSimulatorMode.subscribe((mode) => {
      this.isSimulatorMode = mode;
      if (!this.isSimulatorMode) {
        this.simulatorIntervalStared = false;
        clearInterval(this.simulatorInterval);
      }
      this.cd.markForCheck();
    });
    this.getImpedanceData();
    this.getUser();
    this.getCurrentRoute();
    this.getDevicesList();
  }

  getCurrentRoute() {
    this.currentRoute.currentPage.subscribe((page) => {
      this.page = page;
    });
  }

  getUser() {
    this.firebaseUser.getCurrentUser().pipe(take(1)).subscribe((user: IUser) => {
      this.currentUser = user;
      this.cd.markForCheck();
    });
  }
  getDevicesList() {
    this.firebase.getDevicesList().pipe(take(1)).subscribe((devices) => {
      this.deviceList = devices;
    });
  }

  openYoutubeLink() {
    const language = this.translations.getSelectedLanguage();
    window.open('https://foxly.link/eSense-EEGenius-Erklaervideos', '_blank')
    // if (language === 'de') {
    //   window.open('https://www.youtube.com/watch?v=0fk_i-Xiqxc&feature=youtu.be', '_blank');
    // } else  {
    //   window.open('https://www.youtube.com/watch?v=04xyg9GZVw4&feature=youtu.be', '_blank');
    // }
  }


  toggleConnectDevice() {
    // tslint:disable-next-line:radix
    const serviceUuid = parseInt('0x' + MUSCLE_SERVICE_ID);
    // @ts-ignore
    this.options.filters.push({services: [serviceUuid]});
    this.isLoading = true;
    if (this.device) {
      this.device.gatt.disconnect();
      this.device = null;
      this.isSensorConnected = false;
      this.isLoading = false;
      this.timerView = '00:00:00';
      this.showTimer = false;
      clearInterval(this.connectionInterval);
      this.cd.markForCheck();
      this.muscleData.dataStreamingSubject.next(false);
      this.reward.canPlayVideo.next(false);
      this.sensorState.isSensorConnected.next(false);
      ExgPacketDecoder.resetDecoder();
      return;
    }
    (navigator as any).bluetooth.requestDevice(this.options).then((device) =>{
      this.muscleData.deviceSubject.next(device);
      this.device = device;
      this.muscleData.sensorConnectState.next(true);
      if (!this.disconnectEventAdded) {
        this.disconnectEventAdded = true;
        this.device.addEventListener('gattserverdisconnected', this.disconnectDevice.bind(this));
      }
      return device.gatt.connect();
    }).then(server => {
        server.getPrimaryService(MUSCLE_CMD_SERVICE).then(async service => {
          this.service = service;
          //
          service = await server.getPrimaryService(MUSCLE_CMD_SERVICE);
          const CmdResponseCharacteristic = await service.getCharacteristic(MUSCLE_CMD_READ);
          await CmdResponseCharacteristic.startNotifications();
          CmdResponseCharacteristic.addEventListener('characteristicvaluechanged',
            this.handleCmdNotifications);
          //
          await this.muscleData.bluetoothServiceSubject.next(service);
          await this.sendDataToGetBatteryLevel(service);
          await this.sendDataToGetFWVersion(service);
          // await this.sendDataToActivateEEGmode(service);
          // tslint:disable-next-line:radix
          const hrUuid = parseInt('0x' + MUSCLE_CHARACTERISTIC_ID);
          service = await server.getPrimaryService(serviceUuid);
          const dataCharacteristic = await service.getCharacteristic(hrUuid);
          await dataCharacteristic.startNotifications();
          dataCharacteristic.addEventListener('characteristicvaluechanged', this.handleNotifications.bind(this.muscleData));
          this.sensorState.isSensorConnected.next(true);
          this.isSensorConnected = true;
          this.isLoading = false;
          this.sensorConnectedTime = new Date();
          this.startTimer();
          this.otherCharts.getUserId();
          this.cd.markForCheck();
          this.handleUserDevice();
        });
    }).catch(() => {
      this.cd.markForCheck();
      this.isLoading = false;
    })
  }

  startTimer() {
    this.showTimer = true;
    this.connectionInterval = setInterval(() => {
      const milliseconds = +Date.now() - +this.sensorConnectedTime;
      this.timerView = this.timer.timerView(milliseconds);
      this.cd.markForCheck();
    },1000)
  }

  getDevice() {
    this.muscleData.deviceSubject.subscribe((device) => {
      this.device = device;
    });
  }

  checkBatteryLevel() {
    this.muscleData.muscleDataSubject.subscribe((data) => {
      if (!this.checkBatteryIntervalStarted && data && data.batteryPercentage) {
        this.checkBatteryIntervalStarted = true;
        this.isSensorConnected = true;
        this.checkBatteryLevelInterval = setInterval(() => {
          this.percentage = +data.batteryPercentage.toFixed(1);
          this.cd.markForCheck();
          this.checkBatteryIntervalStarted = false;
          clearInterval(this.checkBatteryLevelInterval)
        },1000)
      }
    })
  }

  handleCmdNotifications(event) {
    const encoder = new TextDecoder('utf-8');
    let answer = encoder.decode(event.target.value);

    if(-1 !== answer.search('GET_FW_VERSION') ){
      answer = answer.substring(answer.search('eSense'));
      this.firmwareVersion = answer;
    }
  }

  getImpedanceData() {
    this.muscleData.muscleDataSubject.subscribe((impedance) => {
      if (impedance && impedance.data.impedance.length) {
        this.isImpedanceData = true;
        this.impedanceFirstChannelKOhms = (impedance.data.impedance[0] / 1000).toFixed(1);
        this.muscleData.currentImpedance =  +this.impedanceFirstChannelKOhms;
        this.impedanceFirstPhase = (impedance.data.impedance[1]).toFixed(1);
        this.impedanceSecondChannelKOhms = (impedance.data.impedance[2] / 1000).toFixed(1);
        this.impedanceSecondPhase = (impedance.data.impedance[3]).toFixed(1);
        this.cd.markForCheck();
      }
    })
  }

  handleNotifications(event) {
    if (event) {
      this.isSensorConnected = true;
      // @ts-ignore
      this.decodeMuscleData(event.target.value.buffer);
      // @ts-ignore
      this.dataStreamingSubject.next(true);
    }
    if (ExgPacketDecoder.getSensorInfo().packetsDropped > 0 || ExgPacketDecoder.getSensorInfo().totalErrorCount > 0) {
      this.previousErrorCount = ExgPacketDecoder.getSensorInfo().totalErrorCount;
      this.previousPacketDrop = ExgPacketDecoder.getSensorInfo().packetsDropped;
      if (!this.sensorErrorsTimeout) {
        this.sensorErrorsTimeout = setTimeout(() => {
          // tslint:disable-next-line:max-line-length
          if (this.previousErrorCount === ExgPacketDecoder.getSensorInfo().totalErrorCount && this.previousPacketDrop  === ExgPacketDecoder.getSensorInfo().packetsDropped) {
            ExgPacketDecoder.resetErrors();
            this.previousErrorCount = 0;
            this.previousPacketDrop = 0;
          }
          clearTimeout(this.sensorErrorsTimeout);
          this.sensorErrorsTimeout = null;
        },15000);
      }
    }
  }

  async sendDataToGetBatteryLevel(bluetoothService) {
    const cmdString = new TextEncoder().encode(`SET_PACKET(0x65,ON)`);
    const CmdWriteCharacteristic = await bluetoothService.getCharacteristic(MUSCLE_CMD_WRITE);
    await CmdWriteCharacteristic.writeValue(cmdString);
  }

  async sendDataToGetFWVersion(bluetoothService) {
    const cmdString = new TextEncoder().encode(`GET_FW_VERSION`);
    const CmdWriteCharacteristic = await bluetoothService.getCharacteristic(MUSCLE_CMD_WRITE);
    await CmdWriteCharacteristic.writeValue(cmdString);
  }

  async sendDataToActivateEEGmode(bluetoothService) {
    const cmdString = new TextEncoder().encode(`SET_PACKET(0x45,ON)`).buffer;
    const CmdWriteCharacteristic = await bluetoothService.getCharacteristic(MUSCLE_CMD_WRITE);
    await CmdWriteCharacteristic.writeValue(cmdString);
  }

  lineWidth() {
    if (this.percentage <= 20) {
      return {
        width: this.percentage + '%',
        backgroundColor: '#a31c1c;'
      };
    } else if (this.percentage > 100) {
      return {
        width: 100 + '%',
        backgroundColor: '#3DCC93;'
      };
    } else {
      return {
        width: this.percentage + '%',
        backgroundColor: '#3DCC93;'
      };
    }
  }

  getFirstLighterColor() {
    if (this.checkBetween(+this.impedanceFirstChannelKOhms, 0, 10)) {
      return {backgroundColor: '#198200'}
    } else if (this.checkBetween(+this.impedanceFirstChannelKOhms, 10 , 20)) {
      return {backgroundColor: '#ffaa00'}
    } else if (+this.impedanceFirstChannelKOhms > 20) {
      return {backgroundColor: '#FF0000'}
    } else if(+this.impedanceFirstChannelKOhms < 0) {
      return {backgroundColor: '#FF0000'}
    }
    this.cd.markForCheck();
  }

  getSecondLighterColor() {
    if (this.checkBetween(+this.impedanceSecondChannelKOhms, 0, 5)) {
      return {backgroundColor: '#198200'}
    } else if (this.checkBetween(+this.impedanceSecondChannelKOhms, 5 , 20)) {
      return {backgroundColor: '#ffaa00'}
    } else if (+this.impedanceSecondChannelKOhms > 20) {
      return {backgroundColor: '#FF0000'}
    }
    this.cd.markForCheck();
  }

  checkBetween(value, min, max) {
    return value >= min && value <= max;
  }
  openInfoDialog(event) {
    if (event.target.alt === 'youtube') {
      return;
    }
    this.dialog.open(InfoDialogComponent)
  }

  disconnectDevice(){
    // @ts-ignore
    this.muscleData.sensorConnectState.next(false);
  }

  toggleSimulatorMode() {
    this.isSimulatorMode = !this.isSimulatorMode;
    this.simulatorService.isSimulatorMode.next(this.isSimulatorMode);
    if (this.isSimulatorMode) {
      const simulatorInterval = interval(100);
      simulatorInterval.pipe(takeUntil(this.stopSimulator)).subscribe(() => {
        this.clearSimulationArray();
        if (this.currentPage === '/amplitude-overview' || this.currentPage === '/test') {
          this.simulatorArray.forEach((value, index) => {
            if (index === 0) {
              this.simulatorArray[0] = this.randomValue(0, 25);
            } else if (index === 1) {
              this.simulatorArray[1] = this.randomValue(2, 8);
            }
            else if (index === 3) {
              this.simulatorArray[3] = this.randomValue(2, 6);
            }
            else if (index === 4) {
              this.simulatorArray[4] = this.randomValue(2, 7);
            }
            else if (index === 8) {
              this.simulatorArray[8] = this.randomValue(2, 4);
            } else if (index === 9) {
              this.simulatorArray[9] = this.randomValue(2, 5);
            } else {
              this.simulatorArray[index].push(this.randomValue(0, 5));
            }
          })
        } else if (this.currentPage === '/ratio-chart' || this.currentPage === '/training-screen') {
          this.simulatorArray.forEach((value, index) => {
            if (index === 9) {
              const artefaktValue = this.randomValue(3, 5);
              this.simulatorArray[9].push(artefaktValue);
            } else {
              const ratioValue = this.randomValue(0.1, 5)
              this.simulatorArray[index].push(ratioValue);
            }
          });
        }
        if (this.currentPage === '/test' || this.currentPage === '/training-screen') {
          this.muscleData.artefaktSubject.next(this.simulatorArray[9]);
        }
        this.simulatorService.simulatorData.next(this.simulatorArray);
      });
    }
    if (!this.isSimulatorMode) {
      this.stopSimulator.next();
      this.reward.canPlayVideo.next(false);
      this.simulatorIntervalStared = false;
      this.simulatorService.simulatorData.next([]);
      clearInterval(this.simulatorInterval);
    }
  }
  randomValue(min, max) {
    const randomVale =  Math.random() * (max - min + 1) + min;
    return randomVale.toFixed(2);
  }

  clearSimulationArray() {
    this.simulatorArray = [[],[],[],[],[],[],[],[],[],[]]
  }
  subscribeOnRouterEvent() {
    this.currentPage = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url) {
          this.currentPage = event.url;
        }
      }
    });
  }
  bluetoothHint() {
    return this.translate.instant('BLUETOOTH_DEVICE_HINT.TEXT')
  }
  getSensorStats() {
    return ExgPacketDecoder.getSensorInfo();
  }
  getCurrentLanguage() {
    return this.translations.getSelectedLanguage();
  }

  sim() {
    ExgPacketDecoder.simulatePacketLoss(10)
  }

  isSensorErrors() {
    return ExgPacketDecoder.getSensorInfo().packetsDropped > 0 || ExgPacketDecoder.getSensorInfo().totalErrorCount > 0;
  }
  handleUserDevice() {
    const deviceName = this.getDeviceId(this.device.name)
    if (this.currentUser.role === 'super user' || this.currentUser.id === '359352') {
      if (!this.currentUser.devices || !this.currentUser.devices.length) {
        this.currentUser.devices = [];
      }
      if (!this.currentUser.devices.includes(deviceName)) {
        this.currentUser.devices.push(deviceName);
        this.currentUser.hasDevices = true;
        this.firebase.updateUser(this.currentUser.uid, this.currentUser);
      }
    } else {
      if (!this.currentUser.devices || !this.currentUser.devices.length) {
        this.currentUser.devices = [];
        this.dialog.open(BindSensorComponent, {
          disableClose: true,
        }).afterClosed().subscribe((result) => {
          if (result) {
            const deviceId = this.getDeviceId(this.device.name);
            const cloudDevice = this.deviceList.find(device => device.deviceName === deviceId);
            if (cloudDevice) {
              const canRegister = cloudDevice.users.length + 1 <= cloudDevice.maxAllowedUsers;
              if (!canRegister) {
                this.toast.message(this.translate.instant('GENIUS_SETTINGS.BIND_SENSOR_ERROR'), '', 'toast-warn', 5000);
                this.toggleConnectDevice();
              } else {
                const exists = cloudDevice.users.findIndex(user => user.id === this.currentUser.id);
                if (exists < 0) {
                  cloudDevice.users.push(this.currentUser);
                  this.currentUser.devices.push(cloudDevice.deviceName);
                }
                this.currentUser.hasDevices = true;
                this.firebase.updateUser(this.currentUser.uid, this.currentUser);
                this.firebase.setDeviceToList(cloudDevice);
              }
            } else {
              this.currentUser.devices.push(deviceId);
              this.currentUser.hasDevices = true;
              this.firebase.updateUser(this.currentUser.uid, this.currentUser);
              this.firebase.getDeviceByName(deviceName).pipe(take(1)).subscribe((device:any) => {
                if (device) {
                  const exists = device.users.findIndex(user => user.id === this.currentUser.id);
                  if (exists < 0) {
                    device.users.push(this.currentUser);
                  }
                  this.firebase.setDeviceToList(device);
                } else {
                  const deviceToRegister = {
                    deviceName,
                    maxAllowedUsers: 1,
                    users: [this.currentUser]
                  }
                  this.firebase.setDeviceToList(deviceToRegister);
                }
              });
            }
          } else {
            this.toggleConnectDevice();
          }
        });
      } else if (this.currentUser.devices.length && !this.currentUser.devices.includes(deviceName)) {
        this.toggleConnectDevice();
        this.toast.message(`${this.translate.instant('GENIUS_SETTINGS.PERSONAL_DEVICE_ERROR')}`, '', 'toast-danger');
      }
    }
  }
  getDeviceId(inputString) {
    const parts = inputString.split('_');
    if (parts.length >= 3) {
      return parts.slice(2).join('_');
    } else {
      return '';
    }
  }
}
