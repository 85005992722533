import {Injectable} from '@angular/core';
import {dataBySignals} from '../../core/consts/consts';
import {Observable} from "rxjs";
import {AverageRatioAnalysisService} from "../average-ratio-analysis/average-ratio-analysis.service";
import {CalculateAverageService} from "../calculateAverage/calculate-average.service";
import loader from "@angular-devkit/build-angular/src/angular-cli-files/plugins/single-test-transform";

@Injectable({
  providedIn: 'root'
})
export class RecordRatingService {

  dataBySignals = dataBySignals;
  ratingImageGood = './assets/rating-images/rating-good.png';
  ratingImageMiddle = './assets/rating-images/rating-middle.png';
  parsedRecord = null;
  recordTemplatesList = [];
  constructor(
    private averageAnalysis: AverageRatioAnalysisService,
  ) { }

  getFirstPartValues(record) {
    this.parsedRecord = null;
    this.recordTemplatesList = [];
    return new Observable((observer) => {
      if (record.seconds < 180) {
        observer.next(record);
        return;
      }
      this.parsedRecord = record;
      if (record.templatesList) {
        record = JSON.parse(JSON.stringify(record));
        let trainedChannels = [];
        record.templatesList.forEach((template) => {
          trainedChannels = this.parseTrainedChannels(template);
        });
        const step = record.refreshInterval ? record.refreshInterval / 1000 : 1;
        const firstIndex = 60 / step;
        const secondIndex = 90 / step;
        record.templatesList.forEach((template, index) => {
          const totalTimeInMinutes = record.templatesList.reduce((sum, obj) => sum + +obj.length, 0);
          const totalTrainingParts = record.templatesList;
          let accumulatedTime = 0;
          const result = [];

          for (const obj of totalTrainingParts) {
            if (accumulatedTime >= totalTimeInMinutes) break;
            const partStartTiming = accumulatedTime;
            let partEndTiming = accumulatedTime + +obj.length;
            if (partEndTiming > totalTimeInMinutes) {
              partEndTiming = totalTimeInMinutes;
            }
            result.push({ partStartTiming, partEndTiming });
            accumulatedTime += +obj.length;
          }
          this.averageAnalysis.resetAnalysisAverage();
          template.firstMinute = [];
          template.lastMinute = [];
          if (template.trainingProtocol === 'amplitude') {
            const signal = this.getRatioName(template.frequencyOne);
            const averages = []
            const recordData = this.getDataForTimeRange(record[signal].data, result[index].partStartTiming, result[index].partEndTiming);
            const lastIndex = recordData.length - 30 / step;
            recordData.forEach((value) => {
              const average = this.averageAnalysis.analysisAverageThird(value,  750);
              averages.push(+average);
            });
            template.firstMinute = this.getArrayAverage(this.getDataInRange(averages, firstIndex, secondIndex));
            template.lastMinute = this.getArrayAverage(this.getDataInRange(averages, lastIndex, recordData.length));
          } else {
            const averageChannelsDivide = [];
            let lastIndex = 0;
            const firstSignalArray = this.getDataForTimeRange(record[this.getRatioName(template.frequencyOne)].data, result[index].partStartTiming, result[index].partEndTiming);
            const secondSignalArray = this.getDataForTimeRange(record[this.getRatioName(template.frequencyTwo)].data, result[index].partStartTiming, result[index].partEndTiming);
            for (let i = 0; i < firstSignalArray.length; i++) {
              for (let j = 0; j < secondSignalArray.length; j++) {
                if (i === j) {
                  const channelsAverage = (firstSignalArray[i] / secondSignalArray[j]);
                  const average = this.averageAnalysis.analysisAverageThird(channelsAverage,  1500);
                  averageChannelsDivide.push(+average);
                  lastIndex = averageChannelsDivide.length - 30 / step;
                }
              }
            }
            template.firstMinute = this.getArrayAverage(this.getDataInRange(averageChannelsDivide, firstIndex, secondIndex));
            template.lastMinute = this.getArrayAverage(this.getDataInRange(averageChannelsDivide, lastIndex, averageChannelsDivide.length))
          }
        });
        record.templatesList.forEach((template, index) => {
          this.getTemplateRating(template);
          if (index === record.templatesList.length - 1) {
            const timeout = setTimeout(() => {
              observer.next(this.parsedRecord);
              this.averageAnalysis.resetAnalysisAverage();
              clearTimeout(timeout);
            },1000);
          }
        });
      }
    });
  }


  getArrayAverage(array) {
    const sum = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return +(sum / array.length).toFixed(2);
  }
  getTemplateRating(template) {
    if (template.threshHoldType === 'reward' && template.lastMinute >= template.firstMinute) {
      template.ratingImg = this.ratingImageGood;
    } else if (template.threshHoldType === 'reward' && template.lastMinute <  template.firstMinute) {
      template.ratingImg = this.ratingImageMiddle;
    }
    if (template.threshHoldType === 'inhibit' && template.lastMinute >= template.firstMinute) {
      template.ratingImg = this.ratingImageMiddle;
    } else if (template.threshHoldType === 'inhibit' && template.lastMinute < template.firstMinute) {
      template.ratingImg = this.ratingImageGood;
    }
    this.recordTemplatesList.push(template);
    this.parsedRecord.templatesList = this.recordTemplatesList;
  }
  getDataInRange(array, startIndex, endIndex) {
    array = JSON.parse(JSON.stringify(array));
    return array.slice(startIndex, endIndex);
  }

  parseTrainedChannels(template) {
    const trainedChannels = [];
    if (template.trainingProtocol === 'amplitude') {
      const channel = this.getRatioName(template.frequencyOne);
      if (!trainedChannels.includes(channel)) {
        trainedChannels.push(channel);
      }
    } else {
      const channel = this.getRatioName(template.frequencyOne);
      const channelTwo = this.getRatioName(template.frequencyTwo);
      if (!trainedChannels.includes(channel)) {
        trainedChannels.push(channel);
      }
      if (!trainedChannels.includes(channelTwo)) {
        trainedChannels.push(channelTwo);
      }
    }
    return trainedChannels;
  }

  getRatioName(index) {
    switch (index) {
      case 0:
        return 'delta'
      case 1:
        return 'theta'
      case 2:
        return 'loAlpha'
      case 3:
        return 'alpha'
      case 4:
        return 'hiAlpha'
      case 5:
        return 'smrLoBeta'
      case 6:
        return 'betaOne'
      case 7:
        return 'betaTwo'
      case 8:
        return 'hiBeta'
      case 9:
        return 'artefakt'
    }
  }

  calculateTrend(yValues) {
    yValues = yValues.map((x) => {
      if (!isFinite(x) || !x) {
        x = 0
      }
      return x;
    });
    const xValues = yValues.map((_, index) => index);
    const sum = yValues.reduce((acc, y, index) => {
      if (y) {
        acc.xSum += xValues[index];
        acc.ySum += y;
      }
      return acc;
    }, { xSum: 0, ySum: 0 });

    const xMean = sum.xSum / yValues.length;
    const yMean = sum.ySum / yValues.length;
    const slope = yValues.reduce((acc, y, index) => {
      if (y) {
        acc.numerator += (xValues[index] - xMean) * (y - yMean);
      }
      acc.denominator += (xValues[index] - xMean) * (xValues[index] - xMean);
      return acc;
    }, { numerator: 0, denominator: 0 });

    const m = slope.numerator / slope.denominator;
    const b = yMean - m * xMean;
    return xValues.map(x => m * x + b)
  }
  getDataForTimeRange(dataArray, startMin, endMin) {
    const samplesPerSecond = 5; // Since we get a new entry every 0.2s
    const startIndex = startMin * 60 * samplesPerSecond;
    const endIndex = endMin * 60 * samplesPerSecond;
    dataArray = JSON.parse(JSON.stringify(dataArray));
    return dataArray.slice(startIndex, endIndex);
  }
}
