import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesService {

  constructor(
    private afs: AngularFirestore,
  ) { }

  getChatMessageTemplate() {
    return this.afs.doc('emailTemplates/chatMessage').valueChanges();
  }
  getChatMessageTemplateDE() {
    return this.afs.doc('emailTemplates/chatMessageDE').valueChanges();
  }
  setChatMessage(data) {
    return this.afs.doc('emailTemplates/chatMessage').set(data);
  }
  setChatMessageDE(data) {
    return this.afs.doc('emailTemplates/chatMessageDE').set(data);
  }
}
