import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PlaylistService} from '../../../../services/playlist/playlist.service';
import {ToastrMessageService} from '../../../../services/toastr-message/toastr-message.service';
import {TranslateService} from '@ngx-translate/core';
import {CurrentUserService} from '../../../../services/current-user/current-user.service';
import {IUser} from '../../../../core/interfaces/user';
import {FirebaseService} from '../../../../services/firebase/firebase.service';
import {take} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable, Subject} from 'rxjs';
import {DeleteCustomVideoComponent} from '../delete-custom-video/delete-custom-video.component';
import * as moment from 'moment';
import {UserStorageService} from '../../../../services/user-storage/user-storage.service';

@Component({
  selector: 'kt-add-youtube-playlist',
  templateUrl: './add-youtube-playlist.component.html',
  styleUrls: ['./add-youtube-playlist.component.scss']
})
export class AddYoutubePlaylistComponent implements OnInit, AfterViewInit {

  @ViewChild('linkInput') linkInput: any;
  linkUrl;
  playlistName = '';
  selectedPlaylist;
  playlistError = true;
  originalLink = '';
  youtubeLink = 'https://youtube.com/playlist?list='
  playlistHistoryArray = [
    // {
    //   name: 'Feedback Videos Concentration',
    //   url: 'https://youtube.com/playlist?list=PLC2aNL4InM9ALpD8rz8e_Ynj1mDGKfBUG'
    // },
    // {
    //   name: 'Feedback Videos Relaxation',
    //   url: 'https://youtube.com/playlist?list=PLC2aNL4InM9D5FPDLHW9-1D1Q66Es-Kcd'
    // },
    // {
    //   name: 'General Feedback Videos',
    //   url: 'https://youtube.com/playlist?list=PLC2aNL4InM9DlGFkVfxYLhGUfrdFkTjEe'
    // }
  ];
  currentUser: IUser;
  choosenPlaylist = {
    url: '',
    name: ''
  }
  playlistType = 'youtube';
  customVideoType = 'private';
  videoName = '';
  videoFile;
  videoDownloadUrl = '';
  userVideos = [];
  publicVideos = [];
  selectedVideo;
  videoUploading = false;
  uploadProgress = 0;
  videoPreview;
  showToast = true;
  youtubeLinkType = 'playlist';
  videoShortsError = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddYoutubePlaylistComponent>,
    private playlistService: PlaylistService,
    private toast: ToastrMessageService,
    private translate: TranslateService,
    private firebaseUser: CurrentUserService ,
    private firebase: FirebaseService,
    private cd: ChangeDetectorRef,
    private afstorage: AngularFireStorage,
    private dialog: MatDialog,
    private userStorage: UserStorageService
  ) { }

  ngOnInit(): void {
    this.getDefaultYoutubePlaylist();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.linkInput.nativeElement.focus();
    },500);
  }

  handleSavedVideo() {
    if (this.data.youtubePlaylistUrl) {
      this.playlistType = 'youtube';
      this.showToast = false;
      const savedYoutubeVideo = this.playlistHistoryArray.find(el => el.url === this.youtubeLink + this.data.youtubePlaylistUrl);
      // const savedYoutubeVideo = this.playlistHistoryArray.find(el => el.url.includes(this.data.youtubePlaylistUrl));
      const video = {
        name: savedYoutubeVideo ? savedYoutubeVideo.name : '',
        url: this.youtubeLink + this.data.youtubePlaylistUrl
      };
      this.linkUrl = video.url;
      this.playlistName = savedYoutubeVideo ? savedYoutubeVideo.name : '';
      // @ts-ignore
      this.choosenPlaylist.url = video.url;
      this.choosenPlaylist.name = video.name;
    } else {
      this.playlistType = 'custom';
      const allVideos = [...this.userVideos, ...this.publicVideos];
      const savedCustomVideo = allVideos.find(el => el.url === this.data.customVideoUrl);
      if (savedCustomVideo) {
        this.setVideoFromList({
          value: savedCustomVideo
        });
      }
    }
  }
  getDefaultYoutubePlaylist() {
    this.firebase.getPublicYoutubePlaylist().pipe(take(1)).subscribe((playlist:any) => {
      playlist.data.forEach((item, index) => {
        const found = this.playlistHistoryArray.findIndex(video => video.url === item.url);
        if (found < 0) {
          this.playlistHistoryArray.push(item);
        }
        if (index === playlist.data.length -1) {
          this.getCurrentUser();
        }
      })
    });
  }

  getCurrentUser() {
    this.firebaseUser.getCurrentUser().pipe(take(1)).subscribe((user: IUser) => {
      this.currentUser = user;
      this.getUserVideos(user);
      this.getPublicVideos();
      if (this.currentUser.youtubePlaylist) {
        this.currentUser.youtubePlaylist.forEach((playlist:any) => {
          const index = this.playlistHistoryArray.findIndex(item => item.name === playlist.name);
          if (index < 0) {
            this.playlistHistoryArray.push(playlist);
          }
          this.cd.markForCheck()
        });
      }
      this.userStorage.calculateUserStorage(user.id);
    });
    if (this.data.youtubePlaylistUrl || this.data.customVideoUrl) {
      this.handleSavedVideo();
    }
  }

  getUserVideos(user) {
    const videosUrl = `TrainingVideos/${user.id}`;
    const ref = this.afstorage.ref(videosUrl);
    ref.listAll().subscribe((data) => {
      data.items.forEach((video) => {
        const reference = this.afstorage.ref(video.fullPath);
        reference.getDownloadURL().subscribe((url) => {
          this.videoPreview = url;
          const videoItem = {
            name: video.name,
            url: video.fullPath,
            videoPreview: url,
            selected: false,
            duration: ''
          }
          this.userVideos.push(videoItem);
          this.cd.markForCheck();
          if (this.data.youtubePlaylistUrl || this.data.customVideoUrl) {
            this.handleSavedVideo();
          }
        })
      });
    });
  }

  setVideoFromList(event) {
    this.clearSelectedVideos();
    event.value.selected = true;
    this.selectedVideo = event.value;
    this.videoFile = null;
    this.playlistError = false;
    this.playlistName = 'custom';
    this.videoName = '';
  }

  clearSelectedVideos() {
    this.userVideos.forEach((item) => {
      item.selected = false;
    });
    this.publicVideos.forEach((item) => {
      item.selected = false;
    });
  }

  getPublicVideos() {
    const videosUrl = `TrainingVideos/Public`;
    const ref = this.afstorage.ref(videosUrl);
    ref.listAll().subscribe((data) => {
      data.items.forEach((video) => {
        video.getMetadata().then((metadata) => {
          const reference = this.afstorage.ref(video.fullPath);
          reference.getDownloadURL().subscribe((url) => {
            this.videoPreview = url;
            const videoItem = {
              name: video.name,
              url: video.fullPath,
              videoPreview: url,
              selected: false,
              metadata: metadata.customMetadata ? metadata.customMetadata.ownerId : '',
              duration: '',
              ownerName: ''
            }
            if (metadata.customMetadata) {
              this.firebase.getUidByUserId(metadata.customMetadata.ownerId).subscribe((user: any) => {
                videoItem.ownerName = user[0].displayName;
              });
            }
            this.publicVideos.push(videoItem);
            this.publicVideos = this.publicVideos.sort((a, b) => a.ownerName.localeCompare(b.ownerName));
            this.cd.markForCheck();
            if (this.data.youtubePlaylistUrl || this.data.customVideoUrl) {
              this.handleSavedVideo();
            }
          })
        })
      });
    });
  }

  getChoosenPlaylist(event, showToast?) {
    if (showToast) {
      this.showToast = true;
    }
    const isPlaylist = event.value.url.includes('list');
    if (isPlaylist) {
      this.linkUrl = event.value.url;
      this.playlistName = event.value.name;
      this.getPlaylist();
      this.choosenPlaylist.url = event.value.url;
      this.choosenPlaylist.name = event.value.name;
    } else {
      this.youtubeLinkType = 'videoId';
      this.linkUrl = event.value.url;
      this.playlistName = event.value.name;
      this.choosenPlaylist.url = event.value.url;
      this.choosenPlaylist.name = event.value.name;
      this.selectedPlaylist = this.getYouTubeVideoId(this.choosenPlaylist.url);
    }
  }
  getYouTubeVideoId(url) {
    let videoId = null;
    let match = url.match(/youtu\.be\/([^?]+)/);
    if (match && match[1]) {
      videoId = match[1];
    }

    // Check for normal URL format: https://www.youtube.com/watch?v=VIDEO_ID
    match = url.match(/[?&]v=([^&]+)/);
    if (match && match[1]) {
      videoId = match[1];
    }
    return videoId; // Returns the extracted video ID or null if not found
  }
  handlePlaylistType(event) {
    if (event.value.includes('shorts')) {
      this.videoErrorToast();
      this.videoShortsError = true;
      return;
    } else {
      this.videoShortsError = false;
    }
    if (event.value.includes('list')) {
      this.youtubeLinkType = 'playlist'
    } else {
      this.youtubeLinkType = 'videoId';
      this.selectedPlaylist = this.getYouTubeVideoId(this.linkUrl);
    }
  }

  getPlaylist() {
    const filteredLink = this.linkUrl.split('list=')[1];
    this.playlistService.getTRainerPlaylist(filteredLink).subscribe(
      (response) => {
        if (this.showToast) {
          this.toast.message(`${this.translate.instant('TRAINING_CONFIGURATION.PLAYLIST_ADDED')}`, '', 'toast-done');
        }
        this.selectedPlaylist = filteredLink;
        this.playlistError = false;
      },
      (error) => {
        console.log(error)
        this.playlistError = true;
        this.toast.message(`${this.translate.instant('TRAINING_CONFIGURATION.YOUTUBE_INVALID_LINK')}`, '', 'toast-warn');
      })
  }

  handleVideoType(event) {
    if (event.value === 'youtube') {
      this.videoFile = null;
      this.videoName = '';
      this.playlistError = true;
      this.playlistName = '';
      this.videoDownloadUrl = '';
    }
    if (event.value === 'custom') {
      this.playlistName = null;
      this.choosenPlaylist.url = '';
      this.choosenPlaylist.name = '';
      this.linkUrl = null;
    }
  }

  close() {
    this.dialogRef.close()
  }

  confirm() {
    if (this.playlistType === 'youtube') {
      const playlist = {
        name: this.playlistName,
        url: this.linkUrl,
        isDefault: false
      }
      if (!this.currentUser.youtubePlaylist) {
        this.currentUser.youtubePlaylist = [];
      }

      if (this.currentUser.role !== 'super user') {
        // @ts-ignore
        const found = this.currentUser.youtubePlaylist.findIndex(item => item.url === playlist.url);
        if (found === -1) {
          this.currentUser.youtubePlaylist.push(playlist);
          this.firebase.updateUser(this.currentUser.uid, this.currentUser);
        }
      } else {
        playlist.isDefault = true;
        this.playlistHistoryArray.push(playlist);
        this.firebase.setSuperUserPublicYoutubeList(this.playlistHistoryArray);
      }

      this.dialogRef.close(this.selectedPlaylist);
    } else {
      if (this.videoFile) {
        this.setVideoDownloadUrl(this.videoFile).subscribe((value: number) => {
          this.uploadProgress = value;
          this.videoUploading = true;
          if (value === 100) {
            const video = {
              downloadUrl: this.videoDownloadUrl
            }
            this.videoUploading = false;
            this.dialogRef.close({video});
          }
        });
      }
      if (this.selectedVideo) {
        const video = {
          downloadUrl: this.selectedVideo.url
        }
        this.dialogRef.close({video});
      }
    }
  }

  onFileChange(event) {
    if (this.bytesToMB(event.target.files[0].size) > 250) {
      this.toast.message(this.translate.instant('CUSTOM_VIDEO.UPLOAD_VIDEO_HINT'),'','toast-warn', 3000);
      return;
    }
    this.videoName = event.target.files[0].name;
    const video = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(video);
    fileReader.onloadend = () => {
      event.target.files[0].ownerId = this.currentUser.id;
      this.videoFile = event.target.files[0];
      if (this.videoFile) {
        this.playlistError = false;
        this.playlistName = 'custom';
      }
    };
  }

  bytesToMB(bytes) {
    const mb = bytes / (1024 * 1024);
    return +mb.toFixed(2);
  }

  setVideoDownloadUrl(video) {
    return new Observable((observer) => {
      if (this.customVideoType === 'private') {
        this.videoDownloadUrl = `TrainingVideos/${this.currentUser.id}/${this.videoName}`;
        this.afstorage.upload(`TrainingVideos/${this.currentUser.id}/${this.videoName}`, video).percentageChanges().subscribe((value) => {
          observer.next(value);
        });
      } else {
        this.videoDownloadUrl = `TrainingVideos/Public/${this.videoName}`
        this.afstorage.upload(`TrainingVideos/Public/${this.videoName}`, video, {
          customMetadata: {
            ownerId: this.currentUser.id
          }
        }).percentageChanges().subscribe((value) => {
          observer.next(value);
        });
      }
    })
  }

  getVideoDuration(event, video) {
    video.duration = moment.utc(event.target.duration * 1000).format('HH:mm:ss')
  }

  deleteVideo(video, type) {
    this.dialog.open(DeleteCustomVideoComponent , {
        data: video.name
    }).afterClosed().subscribe((action) => {
      if (action) {
        const videoIndex = type === 'private' ? this.userVideos.findIndex(item => item.url === video.url) : this.publicVideos.findIndex(item => item.url === video.url);
        if (type === 'private') {
          this.userVideos.splice(videoIndex, 1);
        } else {
          this.publicVideos.splice(videoIndex, 1);
        }
        this.afstorage.ref(video.url).delete();
        this.clearSelectedVideo();
      }
    })
  }

  clearSelectedVideo() {
    this.clearSelectedVideos();
    this.selectedVideo = '';
    this.videoFile = null;
    this.playlistError = true;
    this.cd.markForCheck();
  }

  limitOfCharacters(nameLength: number): number {
    if (nameLength > 15) {
      return 15;
    }
    return 120;
  }

  deleteVideoFormat(videoName) {
    return  videoName.split('.')[0];
  }
  deleteYoutubeVideoSuperUser(video) {
    const indexToDelete = this.playlistHistoryArray.findIndex(item => item.url === video.url);
    this.playlistHistoryArray.splice(indexToDelete, 1);
    this.firebase.setSuperUserPublicYoutubeList(this.playlistHistoryArray);
  }
  deleteYoutubeVideoFromHistory(video) {
    const userVideos = [];
    const indexToDelete = this.playlistHistoryArray.findIndex(item => item.url === video.url);
    this.playlistHistoryArray.splice(indexToDelete, 1);
    this.playlistHistoryArray.forEach((video) => {
      if (!video.isDefault) {
        userVideos.push(video);
      }
    });
    this.currentUser.youtubePlaylist = userVideos;
    this.firebase.updateUser(this.currentUser.uid, this.currentUser);
  }
  linkTooltip() {
    return this.translate.instant('TRAINING_CONFIGURATION.LINK_TOOLTIP');
  }
  nameTooltip() {
    return this.translate.instant('TRAINING_CONFIGURATION.NAME_TOOLTIP');
  }
  uploadVideoHint() {
    return this.translate.instant('CUSTOM_VIDEO.UPLOAD_VIDEO_HINT');
  }
  youtubeNavigate() {
    window.open('https://www.youtube.com/', '_blank')
  }
  getUserStorageUsed() {
    const max = this.userStorage.maximumStorageAllowedMb;
    return +((this.userStorage.getUserStorageUsed() * 100) / max).toFixed(1);
  }
  getUserStorageInMb() {
    return this.userStorage.getUserStorageUsed();
  }
  videoErrorToast() {
    this.toast.message(`${this.translate.instant('TRAINING_CONFIGURATION.YOUTUBE_INVALID_LINK_URL')}`, '', 'toast-warn');
  }
}
