import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {IUser} from '../../core/interfaces/user';
import moment from 'moment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LimitSessionsService {

  maxSecondsPerMonth = 54000;
  maxSecondsPerDay = 3600;
  constructor(
    private afs: AngularFirestore,
  ) { }
  setUserMonthSessions(user: IUser) {
    return this.afs.doc(`users/${user.uid}`).set(user);
  }
  getUserMonthSessions(uid: string) {
    return this.afs.doc(`users/${uid}`).valueChanges();
  }
  calculateHoursBetweenDates(date1, date2) {
    const date1Millis = new Date(date1).getTime();
    const date2Millis = new Date(date2).getTime();
    const differenceInMillis = Math.abs(date2Millis - date1Millis);
    return differenceInMillis / (1000 * 60 * 60);
  }
  filterMonthSessions(sessions: any[]) {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    return sessions.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate.getMonth() === currentMonth && itemDate.getFullYear() === currentYear;
    });
  }
  calculateUserSessionsTime(sessions: any[]) {
    return sessions.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.seconds;
    }, 0);
  }
  calculateMonthTimeLeft(sessions: any[]) {
    const seconds = sessions.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.seconds;
    }, 0);
    if (this.maxSecondsPerMonth - seconds < 0) {
      return 0;
    }
    return this.maxSecondsPerMonth - seconds;
  }
  secondsToHHMMSS(seconds) {
    const duration = moment.duration(seconds, 'seconds');
    return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
  }
  calculateUserMontLimit(user: IUser): Observable<IUser> {
    return new Observable((observer) => {
      if (!user.monthSessions) {
        this.clearUserSessionsLimit(user)
      }
      const lastUserSession = user.monthSessions.sessionsList.slice(-1).pop();
      if (lastUserSession && !this.isSameDay(new Date(lastUserSession.date))) {
        this.clearUserSessionsLimit(user);
      }
      user.monthSessions.sessionsList = this.filterMonthSessions(user.monthSessions.sessionsList);
      // tslint:disable-next-line:max-line-length
      const sessionsLessThanDay = user.monthSessions.sessionsList.filter(session => this.calculateHoursBetweenDates(new Date(session.date), new Date()) < 24);
      user.monthSessions.sessionsSecondsPerDay = this.calculateUserSessionsTime(sessionsLessThanDay);
      // tslint:disable-next-line:max-line-length
      user.monthSessions.catStartSession = user.monthSessions.sessionsSecondsPerDay < this.maxSecondsPerDay;
      if (user.monthSessions.sessionsSecondsPerDay < this.maxSecondsPerDay) {
        user.monthSessions.dayLimitReached = false;
      } else {
        user.monthSessions.dayLimitReached = true;
      }
      if (user.monthSessions.catStartSession) {
        // tslint:disable-next-line:max-line-length
        user.monthSessions.catStartSession = true;
        user.monthSessions.nextSessionAvailableInHours = null;
      } else {
        // tslint:disable-next-line:max-line-length
        user.monthSessions.nextSessionAvailableInHours = this.hoursUntilNextDay(lastUserSession);
        if (user.monthSessions.nextSessionAvailableInHours <= 0) {
          user.monthSessions.sessionsSecondsPerDay = 0;
          user.monthSessions.catStartSession = true;
          user.monthSessions.nextSessionAvailableInHours = null;
        }
      }
      if (this.calculateUserSessionsTime(user.monthSessions.sessionsList) > this.maxSecondsPerMonth) {
        user.monthSessions.catStartSession = false;
        user.monthSessions.nextSessionAvailableInHours = null;
      }
      user.monthSessions.monthTimeLeft = this.calculateMonthTimeLeft(user.monthSessions.sessionsList);
      observer.next(user);
    });
  }
  isSameDay(date) {
    const now = new Date();
    return date.getFullYear() === now.getFullYear() &&
      date.getMonth() === now.getMonth() &&
      date.getDate() === now.getDate();
  }

  hoursUntilNextDay(training) {
    const now = new Date();
    const targetDate = new Date(training.date);
    targetDate.setHours(24, 0, 0, 0);
    // @ts-ignore
    const diffMs = targetDate - now;
    return diffMs / (1000 * 60 * 60);
  }
  clearUserSessionsLimit(user) {
    user.monthSessions  = {
      sessionsSecondsPerDay: 0,
      catStartSession: true,
      sessionsList: [],
      nextSessionAvailableInHours: null,
      monthTimeLeft: user.monthSessions.monthTimeLeft ? user.monthSessions.monthTimeLeft : 0,
      dayLimitReached: false
    }
  }
}
